import React from "react";
import get from "lodash/get";
import withLogin from "../../hocs/LoginGuard/withLogin";

import { connect } from "react-redux";
import { loginAction } from "../../actions/authActions";

import {
  loginPayload,
  loginValidation,
  verifyOTPPayload,
  verifyOTPValidation,
} from "./dataManager";

import AuthService from "../../services/authService";
import axiosInstance from "../../services/CreateService";
import axiosCDNInstance from "../../services/createCDNService";

import styles from "./LoginPage.scss";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      isOtpRequested: false,
      formData: {
        mobileNumber: "",
        countryCode: "91",
        otp: "",
      },
    };

    this.authService = new AuthService();
  }

  handleOTPSubmit = () => {
    const isFormValid = verifyOTPValidation(this.state.formData);
    if (!isFormValid) {
      return;
    }

    const dataPayload = verifyOTPPayload(this.state.formData);

    this.authService
      .verifyOTP(dataPayload)
      .then((responseData) => {
        if (responseData.data) {
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `${responseData.data.accessToken}`;

          axiosCDNInstance.defaults.headers.common[
            "Authorization"
          ] = `${responseData.data.accessToken}`;

          this.props.loginAction(responseData.data);
        }
      })
      .catch((errorData) => {
        console.log(get(errorData, "message"));
      });
  };

  requestOTPHandler = () => {
    const isFormValid = loginValidation(this.state.formData);
    if (!isFormValid) {
      return;
    }

    const dataPayload = loginPayload(this.state.formData);

    this.authService
      .login(dataPayload)
      .then((responseData) => {
        this.setState((state) => ({
          ...state,
          isOtpRequested: true,
        }));
      })
      .catch((errorData) => {
        console.log(get(errorData, "message"));
      });
  };

  setStateValues = (fieldName, fieldValue) => {
    const formData = Object.assign({}, this.state.formData);
    formData[fieldName] = fieldValue;
    this.setState((state) => ({
      ...state,
      formData,
    }));
  };

  render() {
    const { isOtpRequested, formData } = this.state;

    return (
      <div className="page-container page-container--nopad" style={styles}>
        <div className="login-container">
          <div className="login-container-wrapper">
            <img
              src={require("../../images/logo.svg")}
              className="login-container-brand"
              alt="SKIP"
            />

            <div className="login-container-header">
              <h4>Administrator Login</h4>
            </div>

            <div className="login-container-content">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  maxLength={10}
                  placeholder="Mobile Number"
                  value={formData.mobileNumber}
                  onChange={(event) =>
                    this.setStateValues(
                      "mobileNumber",
                      event.currentTarget.value
                    )
                  }
                />
              </div>
              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={this.requestOTPHandler}
                >
                  {!isOtpRequested ? "Request OTP" : "Resend OTP"}
                </button>
              </div>
              {isOtpRequested && (
                <>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="input-field"
                      maxLength={4}
                      placeholder="One Time Password (OTP)"
                      value={formData.otp}
                      onChange={(event) =>
                        this.setStateValues("otp", event.currentTarget.value)
                      }
                    />
                  </div>
                  <div className="input-wrapper">
                    <button
                      type="button"
                      className="button button-primary"
                      onClick={() => this.handleOTPSubmit()}
                    >
                      Sign In
                    </button>
                  </div>
                </>
              )}
              <p className="login-container-disclaimer">
                NOTICE: Wise Owl Learning Private Limited, Hyderabad, IN
                reserves the right to administration of this website and
                monitors every visitor to this and other areas of this website.
                Your use of this website is subject to be monitored and tracked.
                By using this website and/or making any attempts to sabotage its
                integrity will be tracked and be subjected to international
                jurisdictions, wherever applicable.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  loginAction,
};

export default withLogin(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
