import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import moment from 'moment';
import get from 'lodash/get';
import Header from '../../components/Header';
import PageLoader from '../../components/PageLoader';
import AgentService from '../../services/agentService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './EditAgentPage.scss';

class EditAgentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      agentData: null,
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.agentService = new AgentService();
  }

  componentDidMount() {
    this.getAgentByID();
  }

  getAgentByID = () => {
    this.setState({ loader: true });

    this.agentService
      .getAgentByID({ salesAgentID: this.props.match.params.id })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            agentData: get(responseData, 'data', null),
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH AGNET', errorData);
      });
  };

  handleFormSubmit = (status) => {
    this.setState({ loader: true });

    const dataPayload = {
      status,
      salesAgentID: this.props.match.params.id,
    };

    this.agentService
      .verifyAgent(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });

        console.log('SUCCESSFULLY EDITED AGENT', responseData);

        this.getAgentByID();
      })
      .catch((errorData) => {
        this.setState({
          loader: false,
          showServerErrors: errorData.message,
        });

        console.log('UNABLE TO EDIT AGENT', errorData);
      });
  };

  retryVerification = () => {
    this.setState({ loader: true });

    const dataPayload = {
      salesAgentID: this.props.match.params.id,
    };

    this.agentService
      .retryVerification(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });

        console.log('SUCCESSFULLY RETRIED', responseData);

        this.getAgentByID();
      })
      .catch((errorData) => {
        this.setState({
          loader: false,
          showServerErrors: errorData.message,
        });

        console.log('UNABLE TO RETRY', errorData);
      });
  };

  render() {
    const {
      loader,
      showServerErrors,
      validationErrors,
      showValidationErrors,
      agentData,
    } = this.state;

    return (
      <div className='page-container' style={styles}>
        <Helmet>
          <title>SKIP - Agent Details</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className='editagent-container'>
          <div className='editagent-container-wrapper'>
            <h1 className='editagent-container-title'>Agent Details</h1>

            <div className='editagent-container-content'>
              {showValidationErrors && (
                <div className='error-wrapper'>
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className='error-wrapper'>
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className='editagent-container-details'>
                <p>
                  <b>Status: </b>
                  {get(agentData, 'status', 'N/A')}
                </p>

                {get(agentData, 'status') === 'Rejected' && (
                  <p>
                    <b>Rejection Reason: </b>
                    {get(agentData, 'reason', 'N/A')}
                  </p>
                )}

                <p>
                  <b>Agent Code: </b>
                  {get(agentData, 'agentCode', 'N/A')}
                </p>

                <p>
                  <b>Name: </b>
                  {get(agentData, 'name', 'N/A')}
                </p>

                <p>
                  <b>Email: </b>
                  {get(agentData, 'email', 'N/A')}
                </p>

                <p>
                  <b>Mobile Number: </b>
                  {get(agentData, 'mobileNumber', 'N/A')}
                </p>

                <p>
                  <b>TDS Consent Provided: </b>
                  {agentData &&
                  agentData.hasOwnProperty('consentForTDSProvided')
                    ? agentData.consentForTDSProvided
                      ? 'Yes'
                      : 'No'
                    : 'N/A'}
                </p>

                {agentData?.consentForTDSProvided && (
                  <>
                    <p>
                      <b>PAN Card Number: </b>
                      {get(agentData, 'panCardNumber', 'N/A')}
                    </p>

                    <a
                      href={get(agentData, 'panCard', '#')}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      + View PAN Card
                    </a>
                  </>
                )}

                <p>
                  <b>Registration Date &amp; Time: </b>
                  {moment(agentData?.createdAt).format('DD/MM/YY hh:mm A')}
                </p>
              </div>

              {/* <div className="editagent-container-details">
                <h1>Verification Details</h1>
                <p>
                  <b>Full Name: </b>
                  {get(agentData, 'verificationData.full_name', 'N/A')}
                </p>

                <p>
                  <b>Category: </b>
                  {get(agentData, 'verificationData.category', 'N/A')}
                </p>

                <p>
                  <b>PAN Card Number: </b>
                  {get(agentData, 'verificationData.pan', 'N/A')}
                </p>

                <p>
                  <b>PAN Card Status: </b>
                  {get(agentData, 'verificationData.status', 'N/A')}
                </p>

                {get(agentData, 'quickoStatus', false) === false && (
                  <p>
                    <b>Server Response: </b>
                    {get(agentData, 'verificationData.message', 'N/A')}
                  </p>
                )}
              </div> */}

              {
                get(agentData, 'status') === 'Pending' && (
                  // (agentData.quickoStatus ? (
                  <>
                    <button
                      type='button'
                      className='button button-primary'
                      onClick={() => this.handleFormSubmit(true)}
                    >
                      Verify
                    </button>

                    <button
                      type='button'
                      className='button button-secondary'
                      onClick={() => this.handleFormSubmit(false)}
                    >
                      Reject
                    </button>
                  </>
                )
                // ) : (
                //   <>
                //     <button
                //       type="button"
                //       className="button button-primary"
                //       onClick={() => this.retryVerification()}
                //     >
                //       Retry Verification
                //     </button>

                //     <h5>
                //       ** Request for PAN Card verification is a paid service.
                //     </h5>
                //   </>
                // ))
              }
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditAgentPage));
