import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import get from 'lodash/get';
import Header from '../../components/Header';
import SubjectService from '../../services/subjectService';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import SideNavigation from '../../components/SideNavigation';
import PageLoader from '../../components/PageLoader';

import styles from './EditChapterPage.scss';

class EditChapterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      className: null,
      subjectName: null,
      formData: {
        chapter: '',
        number: '',
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.subjectService = new SubjectService();
  }

  componentDidMount() {
    this.getChapterByID();
  }

  getChapterByID = () => {
    this.setState({ loader: true });

    this.subjectService
      .getChapterByID({ chapterID: this.props.match.params.id })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            subjectName: get(responseData, 'data.subjectID.subject', 'N/A'),
            className: get(responseData, 'data.subjectID.classID.class', 'N/A'),
            formData: {
              ...state.formData,
              chapter: get(responseData, 'data.chapter', ''),
              number: get(responseData, 'data.number', ''),
            },
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH CHAPTER', errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (this.props.match.params.id === '') {
      errorMessagesArr.push('Chapter ID is required.');
      isFormValid = false;
    }

    if (formData.chapter === '') {
      errorMessagesArr.push('Chapter Name is a required field.');
      isFormValid = false;
    }

    if (formData.number === '') {
      errorMessagesArr.push('Chapter Number is a required field.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      const dataPayload = {
        chapterID: this.props.match.params.id,
        chapter: formData.chapter,
        number: formData.number.toString(),
      };

      this.subjectService
        .editChapter(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });

          console.log('SUCCESSFULLY EDITED SUBJECT', responseData);

          if (responseData) {
            this.props.history.push(NavigationPaths.LESSONSPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          console.log('UNABLE TO EDIT SUBJECT', errorData);
        });
    }
  };

  render() {
    const {
      loader,
      formData,
      className,
      subjectName,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Chapter</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editChapter-container">
          <div className="editChapter-container-wrapper">
            <h1 className="editChapter-container-title">Edit Chapter</h1>

            <div className="editChapter-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <p>
                  <b>Class Name: </b>
                  {className}
                </p>
              </div>

              <div className="input-wrapper">
                <p>
                  <b>Subject Name: </b>
                  {subjectName}
                </p>
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Chapter Name"
                  value={formData.chapter}
                  onChange={(event) => this.handleFieldUpdate('chapter', event.target.value)}
                />
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Chapter Number"
                  value={formData.number}
                  onChange={(event) => this.handleFieldUpdate('number', event.target.value)}
                />
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Edit Chapter
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withAuthentication(EditChapterPage));
