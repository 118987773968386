import React from "react";
import { set, get } from "lodash";
import { v4 as uuid } from "uuid";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Subjects } from "../../common/Constants";
import { NavigationPaths } from "../../common/Environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import ContentService from "../../services/contentService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";
import ImageUploader from "../../components/ImageUploader";
import PageLoader from "../../components/PageLoader";

import styles from "./EditActivityPage.scss";

class EditActivityPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      currentActivity: null,
      formData: {
        resourceType: "Activity",
        mediaDirectory: uuid(),
        isFreebie: "false",
        thumbnail: "",
        title: "",
        difficulty: "",
        description: { texts: [""] },
        activityURL: "",
        source: "",
        downloadType: "",
        downloadURL: ""
      },
      validationErrors: [],
      showServerErrors: "",
      showValidationErrors: false,
    };

    this.contentServive = new ContentService();
  }

  componentDidMount() {
    this.getActivityData();
  }

  getActivityData = () => {
    this.setState({ loader: true });

    const dataPayload = {
      resourceType: "Activity",
      resourceID: this.props.match.params.id,
    };

    this.contentServive
      .getContentByID(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData.data) {
          this.setState((state) => ({
            ...state,
            currentActivity: responseData.data,
            formData: {
              ...state.formData,
              title: get(responseData.data, "title", ""),
              thumbnail: get(responseData.data, "thumbnail", ""),
              difficulty: get(responseData.data, "difficulty", ""),
              description: get(responseData.data, "description", {
                texts: [""],
              }),
              mediaDirectory: get(responseData.data, "mediaDirectory", uuid()),
              activityURL: get(responseData.data, "activityURL", ""),
              source: get(responseData.data, "source", ""),
              downloadType: get(responseData.data, "downloadType", ""),
              downloadURL: get(responseData.data, "downloadURL", ""),
              isFreebie:
                responseData.data.isFreebie === true ? "true" : "false",
            },
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log("UNABLE TO FETCH DATA", errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.resourceType === "") {
      errorMessagesArr.push("Resource Type is a required field.");
      isFormValid = false;
    }

    if (formData.title === "") {
      errorMessagesArr.push("Activity Title is a required field.");
      isFormValid = false;
    }

    if (!formData.description.texts.length) {
      errorMessagesArr.push("Activity Description is a required.");
      isFormValid = false;
    } else {
      for (let d of formData.description.texts) {
        if (!d || d === "") {
          errorMessagesArr.push("Activity Description cannot be empty.");
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.difficulty === "") {
      errorMessagesArr.push("Activity Difficulty is a required field.");
      isFormValid = false;
    }

    if (formData.thumbnail === "") {
      errorMessagesArr.push("Activity Thumbnail URL is a required field.");
      isFormValid = false;
    }

    if (formData.source === "") {
      errorMessagesArr.push("Activity Source is a required field.");
      isFormValid = false;
    }

    if (formData.activityURL === "") {
      errorMessagesArr.push("Activity URL/Embed Code is required.");
      isFormValid = false;
    }

    if (formData.downloadType && !formData.downloadURL) {
      errorMessagesArr.push('Activity Download URL is required.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log("Invalid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log("Valid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: "",
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    set(formData, fieldName, fieldValue);

    this.setState((state) => ({
      ...state,
      formData,
    }));
  };

  handleStateUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      [`${fieldName}`]: fieldValue,
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      let dataPayload = {
        resourceType: formData.resourceType,
        mediaDirectory: formData.mediaDirectory,
        isFreebie: formData.isFreebie === "true" ? true : false,
        title: formData.title,
        description: formData.description,
        thumbnail: formData.thumbnail,
        activityURL: formData.activityURL,
        resourceID: this.props.match.params.id,
        difficulty: formData.difficulty,
        source: formData.source,
        downloadURL: (formData.downloadType && formData.downloadURL) || null,
        downloadType: formData.downloadType || null,
      };

      this.contentServive
        .editContent(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });
          console.log("SUCCESSFULLY Edited Content", responseData);
          this.handleNavigation()
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });

          console.log("UNABLE TO Edited CONTENT", errorData);
        });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  addDescriptionRow = () => {
    let description = Object.assign({}, this.state.formData.description);
    description.texts.push("");
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        description,
      },
    }));
  };

  removeDescriptionRow = (descriptionIndex) => {
    let description = Object.assign({}, this.state.formData.description);
    description.texts = description.texts.filter(
      (item, itemIndex) => itemIndex !== descriptionIndex
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        description,
      },
    }));
  };

  handleNavigation = () => {
    const subject = get(
      this.state.currentActivity,
      "chapterID.subjectID.subject"
    );

    switch (subject) {
      case Subjects.MATHS:
      case Subjects.ENGLISH:
      case Subjects.SCIENCE:
        return this.props.history.push(
          NavigationPaths.SUBJECTACTIVITYPAGE.replace(":subject", subject)
        );

      case Subjects.EVS:
        return this.props.history.push(
          NavigationPaths.SUBJECTACTIVITYPAGE.replace(
            ":subject",
            Subjects.SCIENCE
          )
        );

      default:
        return this.props.history.push(NavigationPaths.ACTIVITYPAGE);
    }
  };

  render() {
    const {
      loader,
      formData,
      currentActivity,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Activity</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addcontent-container">
          <ImageUploader mediaDirectory={formData.mediaDirectory} />

          <div className="addcontent-container-wrapper">
            <h1 className="addcontent-container-title">Edit Activity</h1>

            <div className="addcontent-container-content">
              <div className="addcontent-container-metadata">
                {showValidationErrors && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    {validationErrors.map((item, index) => {
                      return <p key={index}>{item}</p>;
                    })}
                  </div>
                )}

                {showServerErrors !== "" && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    <p>{showServerErrors}</p>
                  </div>
                )}

                <h5>Activity Details</h5>

                <div className="input-wrapper">
                  <label>Activity Class </label>
                  <input
                    disabled
                    type="text"
                    className="input-field"
                    value={get(
                      currentActivity,
                      "chapterID.subjectID.classID.class",
                      "N/A"
                    )}
                  />
                </div>

                <div className="input-wrapper">
                  <label>Activity Subject </label>
                  <input
                    disabled
                    type="text"
                    className="input-field"
                    value={get(
                      currentActivity,
                      "chapterID.subjectID.subject",
                      "N/A"
                    )}
                  />
                </div>

                <div className="input-wrapper">
                  <label>Activity Chapter </label>
                  <input
                    disabled
                    type="text"
                    className="input-field"
                    value={get(currentActivity, "chapterID.chapter", "N/A")}
                  />
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Activity Title"
                    value={formData.title}
                    onChange={(event) =>
                      this.handleFieldUpdate("title", event.target.value)
                    }
                  />
                </div>

                <div className="activity-description">
                  {formData.description.texts.length !== 0 && (
                    <div className="description-data">
                      <div className="description-replicable">
                        {formData.description.texts.map(
                          (description, descriptionIndex) => {
                            return (
                              <div
                                key={descriptionIndex}
                                className="description-replicable-item d-flex align-items-center justify-content-between"
                              >
                                <div className="input-wrapper">
                                  <input
                                    type="text"
                                    className="input-field"
                                    placeholder={`Activity Description ${
                                      descriptionIndex + 1
                                    }`}
                                    value={description}
                                    onChange={(event) =>
                                      this.handleFieldUpdate(
                                        `description.texts[${descriptionIndex}]`,
                                        event.target.value
                                      )
                                    }
                                  />
                                </div>

                                <button
                                  type="button"
                                  className="button button-link"
                                  disabled={
                                    formData.description.texts.length < 2
                                  }
                                  onClick={() =>
                                    this.removeDescriptionRow(descriptionIndex)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                              </div>
                            );
                          }
                        )}

                        <div className="description-replicable-trigger">
                          <button
                            type="button"
                            className="button button-link"
                            onClick={() => this.addDescriptionRow()}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Another Text
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Activity Difficulty"
                    value={formData.difficulty}
                    onChange={(event) =>
                      this.handleFieldUpdate("difficulty", event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Activity Thumbnail URL"
                    value={formData.thumbnail}
                    onChange={(event) =>
                      this.handleFieldUpdate("thumbnail", event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <label>Mark Activity as a Freebie</label>
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate("isFreebie", event.target.value)
                    }
                    value={formData.isFreebie}
                  >
                    <option value={"false"}>No</option>
                    <option value={"true"}>Yes</option>
                  </select>
                </div>

                <div className="input-wrapper">
                  <label>Activity Source</label>
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate("source", event.target.value)
                    }
                    value={formData.source}
                  >
                    <option value="" disabled>
                      Select a source
                    </option>
                    <option value={"URL"}>URL</option>
                    <option value={"Embeded"}>Embeded</option>
                  </select>
                </div>

                <div className="input-wrapper">
                  {formData.source === "Embeded" ? (
                    <textarea
                      rows={2}
                      type="text"
                      className="input-field"
                      placeholder="Activity Embed Code"
                      value={formData.activityURL}
                      onChange={(event) =>
                        this.handleFieldUpdate(
                          "activityURL",
                          event.target.value
                        )
                      }
                    />
                  ) : (
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Activity URL"
                      value={formData.activityURL}
                      onChange={(event) =>
                        this.handleFieldUpdate(
                          "activityURL",
                          event.target.value
                        )
                      }
                    />
                  )}
                </div>

                <div className="input-wrapper">
                  <label>Activity Download Type</label>
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate('downloadType', event.target.value)
                    }
                    value={formData.downloadType}
                  >
                    <option value="">None</option>
                    <option value={'image'}>Image</option>
                    <option value={'pdf'}>PDF</option>
                  </select>
                </div>

                {formData.downloadType && (
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="input-field"
                      value={formData.downloadURL}
                      placeholder="Activity Download URL"
                      onChange={(event) =>
                        this.handleFieldUpdate(
                          'downloadURL',
                          event.target.value
                        )
                      }
                    />
                  </div>
                )}
              </div>

              <hr />

              <div className="content-context">
                <div className="content-context-trigger">
                  <button
                    type="button"
                    className="button button-primary"
                    onClick={() => this.handleFormSubmit()}
                  >
                    Edit Activity
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditActivityPage));
