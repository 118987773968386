import React from "react";
import get from "lodash/get";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";

export class QuestionExplanation extends React.Component {
  render() {
    const { explanation } = this.props;
    return (
      <>
        <div className="input-wrapper">
          <input
            type="text"
            className="input-field"
            placeholder="Explanation Primary Image"
            value={get(explanation, "primaryImage", "")}
            onChange={(event) =>
              this.props.handleFieldUpdate(
                `explanation.primaryImage`,
                event.target.value
              )
            }
          />
        </div>

        <div className="question-data">
          <div className="question-replicable">
            {explanation && explanation.texts && explanation.texts.length !== 0 &&
              explanation.texts.map((text, index) => {
                return (
                  <div
                    key={index}
                    className="question-replicable-item d-flex align-items-center justify-content-between"
                  >
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="input-field"
                        placeholder={`Explanation Text ${index + 1}`}
                        value={text}
                        onChange={(event) =>
                          this.props.handleFieldUpdate(
                            `explanation.texts[${index}]`,
                            event.target.value
                          )
                        }
                      />
                    </div>

                    <button
                      type="button"
                      className="button button-link"
                      onClick={() =>
                        this.props.handleRemoveExplanationText("texts", index)
                      }
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                );
              })}

            <div className="question-replicable-trigger">
              <button
                type="button"
                className="button button-link"
                onClick={() => this.props.handleAddExplanationText("texts")}
              >
                <FontAwesomeIcon icon={faPlus} />
                {` Add Explanation Text`}
              </button>
            </div>
          </div>
        </div>

        <div className="input-wrapper">
          <input
            type="text"
            className="input-field"
            placeholder="Explanation Secondary Image"
            value={get(explanation, "secondaryImage", "")}
            onChange={(event) =>
              this.props.handleFieldUpdate(
                `explanation.secondaryImage`,
                event.target.value
              )
            }
          />
        </div>

        <div className="question-data">
          <div className="question-replicable">
            {explanation && explanation.subTexts && explanation.subTexts.length !== 0 &&
              explanation.subTexts.map((text, index) => {
                return (
                  <div
                    key={index}
                    className="question-replicable-item d-flex align-items-center justify-content-between"
                  >
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="input-field"
                        placeholder={`Explanation Text ${index + 1}`}
                        value={text}
                        onChange={(event) =>
                          this.props.handleFieldUpdate(
                            `explanation.subTexts[${index}]`,
                            event.target.value
                          )
                        }
                      />
                    </div>

                    <button
                      type="button"
                      className="button button-link"
                      onClick={() =>
                        this.props.handleRemoveExplanationText(
                          "subTexts",
                          index
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                );
              })}

            <div className="question-replicable-trigger">
              <button
                type="button"
                className="button button-link"
                onClick={() => this.props.handleAddExplanationText("subTexts")}
              >
                <FontAwesomeIcon icon={faPlus} />
                {` Add Explanation Text`}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
