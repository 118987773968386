import React from 'react';
import { set, get } from 'lodash';
import { v4 as uuid } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { QuestionExplanation } from '../QuestionExplanation';
import { QuestionData } from '../QuestionData';

import styles from './MTFSubType.scss';
import { UrlRegex, QuestionSubTypePayload } from '../../common/Constants';
import QuestionHeader from '../QuestionHeader';
import QuestionHint from '../QuestionHint';

export default class MTFSubType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSafeButton: false,
      selectedQuesType: '',
      selectedAnsType: '',
      validationErrors: [],
      showValidationErrors: false,
      formData: Object.assign({}, QuestionSubTypePayload.MTF),
    };
  }

  componentDidMount() {
    this.prefillQuestionData();
  }

  prefillQuestionData = () => {
    const { currentQuestionData } = this.props;

    if (currentQuestionData) {
      this.setState((state) => ({
        ...state,
        formData: currentQuestionData,
      }));
    }
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    set(formData, fieldName, fieldValue);

    this.setState((state) => ({
      ...state,
      formData,
      showSafeButton: true,
    }));
  };

  handleUpdateQuestion = () => {
    const valid = this.validateFormData();

    if (valid) {
      let answerIds = [];
      let ans = Object.assign([], this.state.formData.answerData.ans);

      if (ans.length) {
        for (let answerObj of ans) {
          answerIds.push(answerObj.id);
        }
      }

      this.setState(
        (state) => ({
          ...state,
          showSafeButton: false,
          formData: {
            ...state.formData,
            correctAnswer: answerIds.toString(),
          },
        }),
        () => {
          this.props.updateQuestion(this.state.formData, this.props.position);
        }
      );
    }
  };

  handleAddSuggestion = () => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionSuggestions: [...state.formData.questionSuggestions, ''],
      },
      showSafeButton: true,
    }));
  };

  handleRemoveSuggestion = (index) => {
    let questionSuggestions = Object.assign(
      [],
      this.state.formData.questionSuggestions
    );
    questionSuggestions = questionSuggestions.filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionSuggestions,
      },
      showSafeButton: true,
    }));
  };

  handleRemoveAnswerData = (type, index) => {
    let answerData = Object.assign({}, this.state.formData.answerData);

    if (type === 'ques') {
      answerData.ques = answerData.ques.filter(
        (data, dataIndex) => dataIndex !== index
      );
    } else if (type === 'ans') {
      answerData.ans = answerData.ans.filter(
        (data, dataIndex) => dataIndex !== index
      );
    }

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        answerData,
      },
      showSafeButton: true,
    }));
  };

  handleStateUpdate = (fieldName, fieldValue) => {
    let answerData = Object.assign({}, this.state.formData.answerData);

    if (fieldName === 'selectedQuesType') {
      if (fieldValue === 'text') {
        set(answerData, `ques[${answerData.ques.length}]`, {
          type: 'text',
          id: uuid(),
          text: '',
        });
      } else if (fieldValue === 'image') {
        set(answerData, `ques[${answerData.ques.length}]`, {
          type: 'image',
          id: uuid(),
          url: '',
        });
      }
    }

    if (fieldName === 'selectedAnsType') {
      if (fieldValue === 'text') {
        set(answerData, `ans[${answerData.ans.length}]`, {
          type: 'text',
          id: uuid(),
          text: '',
        });
      } else if (fieldValue === 'image') {
        set(answerData, `ans[${answerData.ans.length}]`, {
          type: 'image',
          id: uuid(),
          url: '',
        });
      }
    }

    this.setState((state) => ({
      ...state,
      selectedQuesType: '',
      selectedAnsType: '',
      showSafeButton: true,
      formData: {
        ...state.formData,
        answerData,
      },
    }));
  };

  handleAddQuestionData = (dataType) => {
    let questionData = Object.assign([], this.state.formData.questionData);
    questionData.push({
      type: dataType,
      value: '',
      vendor: dataType === 'video' ? '' : null,
    });

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionData,
      },
      showSafeButton: true,
    }));
  };

  handleRemoveQuestionData = (index) => {
    let questionData = Object.assign([], this.state.formData.questionData);

    questionData = questionData.filter(
      (item, itemIndex) => itemIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionData,
      },
      showSafeButton: true,
    }));
  };

  handleAddHint = (questionHintType) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionHint: [
          ...state.formData.questionHint,
          { type: questionHintType, value: '' },
        ],
      },
      showSafeButton: true,
    }));
  };

  handleRemoveHint = (index) => {
    let questionHint = Object.assign([], this.state.formData.questionHint);
    questionHint = questionHint.filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionHint,
      },
      showSafeButton: true,
    }));
  };

  handleAddExplanationText = (fieldName) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        explanation: {
          ...state.formData.explanation,
          [`${fieldName}`]: [...state.formData.explanation[fieldName], ''],
        },
      },
      showSafeButton: true,
    }));
  };

  handleRemoveExplanationText = (fieldName, index) => {
    let explanation = Object.assign({}, this.state.formData.explanation);
    explanation[fieldName] = explanation[fieldName].filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        explanation,
      },
      showSafeButton: true,
    }));
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;
    if (!formData.competency) {
      errorMessagesArr.push('Competency is a required field.');
      isFormValid = false;
    }

    if (!formData.questionData.length) {
      errorMessagesArr.push('Atleast 1 Question Field is required.');
      isFormValid = false;
    } else {
      for (let q of formData.questionData) {
        if (!q.value || (q.type === 'video' && !q.vendor)) {
          errorMessagesArr.push('Question Field Value is required.');
          isFormValid = false;
          break;
        }

        if (q.type === 'url' && !UrlRegex.test(q.value)) {
          errorMessagesArr.push('Question URL is invalid.');
          isFormValid = false;
          break;
        }
      }
    }

    if (!formData.answerData.ques.length) {
      errorMessagesArr.push('Question Type is a required field.');
      isFormValid = false;
    } else {
      for (let q of formData.answerData.ques) {
        if (
          !q ||
          q.type === '' ||
          ((q.text === '' || !q.text) && (q.url === '' || !q.url))
        ) {
          errorMessagesArr.push('Question Text/URL is a required field.');
          isFormValid = false;
          break;
        }
      }
    }

    if (!formData.answerData.ans.length) {
      errorMessagesArr.push('Answer Type is a required field.');
      isFormValid = false;
    } else {
      for (let q of formData.answerData.ans) {
        if (
          !q ||
          q.type === '' ||
          ((q.text === '' || !q.text) && (q.url === '' || !q.url))
        ) {
          errorMessagesArr.push('Answer Text/URL is a required field.');
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.answerData.ques.length !== formData.answerData.ans.length) {
      errorMessagesArr.push('Number of Questions and Answers must be equal.');
      isFormValid = false;
    }

    if (formData.questionHint.length) {
      for (let qh of formData.questionHint) {
        if (!qh || !qh.value) {
          errorMessagesArr.push('Question Hint is a required field.');
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.questionSuggestions.length) {
      for (let qs of formData.questionSuggestions) {
        if (!qs) {
          errorMessagesArr.push('Question Suggestion is a required field.');
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.explanation.texts.length) {
      for (let e of formData.explanation.texts) {
        if (!e) {
          errorMessagesArr.push(
            'Question Explanation Text is a required field.'
          );
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.explanation.subTexts.length) {
      for (let e of formData.explanation.subTexts) {
        if (!e) {
          errorMessagesArr.push(
            'Question Explanation Text is a required field.'
          );
          isFormValid = false;
          break;
        }
      }
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  render() {
    const {
      formData,
      showSafeButton,
      selectedQuesType,
      selectedAnsType,
      showValidationErrors,
      validationErrors,
    } = this.state;

    return (
      <div className="content-question" style={styles}>
        <QuestionHeader
          code={formData.code}
          showCompetency={true}
          position={this.props.position}
          competency={formData.competency}
          showQuestionSubTypeSwitcher={false}
          questionSubType={formData.questionSubType}
          handleFieldUpdate={this.handleFieldUpdate}
          removeQuestion={this.props.removeQuestion}
          questionsCount={this.props.questionsCount}
          updatePositionMap={this.props.updatePositionMap}
          positionSwitchMap={this.props.positionSwitchMap}
          questionType={get(this.props.currentQuestionData, 'questionType', '')}
          markedForDeletion={get(
            this.props.currentQuestionData,
            'markedForDeletion',
            ''
          )}
        />

        <div className="content-question-body">
          {showValidationErrors && (
            <div className="error-wrapper">
              <h6>Error!</h6>

              {validationErrors.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          )}

          <QuestionData
            questionData={formData.questionData}
            handleFieldUpdate={this.handleFieldUpdate}
            handleAddQuestionData={this.handleAddQuestionData}
            handleRemoveQuestionData={this.handleRemoveQuestionData}
          />

          <div className="matching-format d-flex align-items-start">
            <div className="matching-format-column">
              <div className="question-data">
                <div className="question-replicable">
                  {formData.answerData.ques.length !== 0 &&
                    formData.answerData.ques.map((ques, quesIndex) => {
                      return (
                        <div
                          key={quesIndex}
                          className="question-replicable-item d-flex align-items-center justify-content-between"
                        >
                          <div className="input-wrapper">
                            <input
                              type="text"
                              className="input-field"
                              placeholder={`Question ${
                                ques.type === 'text'
                                  ? `Text ${quesIndex + 1}`
                                  : `URL ${quesIndex + 1}`
                              }`}
                              value={
                                ques.type === 'text' ? ques.text : ques.url
                              }
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  `answerData.ques[${quesIndex}]['${
                                    ques.type === 'text' ? 'text' : 'url'
                                  }']`,
                                  event.target.value
                                )
                              }
                            />
                          </div>

                          <button
                            type="button"
                            className="button button-link"
                            onClick={() =>
                              this.handleRemoveAnswerData('ques', quesIndex)
                            }
                            disabled={formData.answerData.ques.length < 2}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </div>
                      );
                    })}

                  <div className="question-replicable-trigger">
                    <div className="input-wrapper">
                      <select
                        className="input-select"
                        onChange={(event) =>
                          this.handleStateUpdate(
                            'selectedQuesType',
                            event.target.value
                          )
                        }
                        value={selectedQuesType}
                      >
                        <option value="">Add Question Type</option>
                        <option value={'text'}>Text Question</option>
                        <option value={'image'}>Image Question</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="matching-format-column">
              <div className="question-data">
                <div className="question-replicable">
                  {formData.answerData.ans.length !== 0 &&
                    formData.answerData.ans.map((ans, ansIndex) => {
                      return (
                        <div
                          key={ansIndex}
                          className="question-replicable-item d-flex align-items-center justify-content-between"
                        >
                          <div className="input-wrapper">
                            <input
                              type="text"
                              className="input-field"
                              placeholder={`Answer ${
                                ans.type === 'text' ? 'text' : 'url'
                              }`}
                              value={ans.type === 'text' ? ans.text : ans.url}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  `answerData.ans[${ansIndex}]['${
                                    ans.type === 'text' ? 'text' : 'url'
                                  }']`,
                                  event.target.value
                                )
                              }
                            />
                          </div>
                          <button
                            type="button"
                            className="button button-link"
                            onClick={() =>
                              this.handleRemoveAnswerData('ans', ansIndex)
                            }
                            disabled={formData.answerData.ans.length < 2}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </div>
                      );
                    })}

                  <div className="question-replicable-trigger">
                    <div className="input-wrapper">
                      <select
                        className="input-select"
                        onChange={(event) =>
                          this.handleStateUpdate(
                            'selectedAnsType',
                            event.target.value
                          )
                        }
                        value={selectedAnsType}
                      >
                        <option value="">Add a Answer Type</option>
                        <option value={'text'}>Text Answer</option>
                        <option value={'image'}>Image Answer</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <QuestionHint
            handleAddHint={this.handleAddHint}
            questionHint={formData.questionHint}
            handleRemoveHint={this.handleRemoveHint}
            handleFieldUpdate={this.handleFieldUpdate}
          />

          <div className="question-data">
            <div className="question-replicable">
              {formData.questionSuggestions.length !== 0 &&
                formData.questionSuggestions.map((suggestion, index) => {
                  return (
                    <div
                      key={index}
                      className="question-replicable-item d-flex align-items-center justify-content-between"
                    >
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="input-field"
                          placeholder={`Answer Suggestion ${index + 1}`}
                          value={suggestion}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              `questionSuggestions[${index}]`,
                              event.target.value
                            )
                          }
                        />
                      </div>

                      <button
                        type="button"
                        className="button button-link"
                        onClick={() => this.handleRemoveSuggestion(index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  );
                })}

              <div className="question-replicable-trigger">
                <button
                  type="button"
                  className="button button-link"
                  onClick={() => this.handleAddSuggestion()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {` Add Answer Suggestion`}
                </button>
              </div>
            </div>
          </div>

          <QuestionExplanation
            explanation={formData.explanation}
            handleFieldUpdate={this.handleFieldUpdate}
            handleAddExplanationText={this.handleAddExplanationText}
            handleRemoveExplanationText={this.handleRemoveExplanationText}
          />

          {showSafeButton && (
            <div className="input-wrapper">
              <button
                type="button"
                className="button button-secondary"
                onClick={() => this.handleUpdateQuestion()}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
