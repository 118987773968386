import axiosInstance from './CreateService';
import { ApiConstants } from '../common/Environment';

export default class ClassService {
  getAllClasses() {
    return axiosInstance.get(ApiConstants.GETCLASSES);
  }

  addClass(dataPayload) {
    return axiosInstance.post(ApiConstants.ADDCLASSES, dataPayload)
  }

  editClass(dataPayload) {
    return axiosInstance.put(ApiConstants.EDITCLASSES, dataPayload)
  }

  toggleClass(dataPayload) {
    return axiosInstance.put(ApiConstants.TOGGLECLASSES, dataPayload)
  }

  getClassByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETCLASSESBYID.replace(':classID', dataPayload.classID)
    );
  }
}
