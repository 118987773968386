import axiosCDNInstance from "./createCDNService";
import { ApiConstants } from "../common/Environment";

export default class PackageService {
  uploadMedia(dataPayload) {
    const payload = new FormData();
    payload.append("file", dataPayload.file);
    payload.set("directoryName", dataPayload.directoryName);

    return axiosCDNInstance.post(ApiConstants.ADDMEDIA, payload);
  }
}
