import moment from 'moment';

export const tableDataParser = (responseData, columnCount) => {
  if (responseData && responseData.length !== 0) {
    let tableDataArr = [];

    for (let rowIndex = 0; rowIndex < responseData.length; rowIndex++) {
      let currentRowArr = [];

      currentRowArr.push(`${rowIndex + 1}.`);
      currentRowArr.push(responseData[rowIndex]._id);
      currentRowArr.push(responseData[rowIndex].title);
      currentRowArr.push(
        responseData[rowIndex].active === true ? 'Active' : 'Inactive'
      );
      currentRowArr.push(
        moment(responseData[rowIndex].createdAt).format('DD/MM/YY hh:mm A')
      );

      tableDataArr.push(currentRowArr);
    }

    return tableDataArr;
  } else {
    return [];
  }
};
