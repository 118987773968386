import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

import MediaService from '../../services/mediaService';

import styles from './ImageUploader.scss';

export default class ImageUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFileUploaded: false,
      uploadedMediaIcon: null,
      selectedFile: null,
      showFileInput: true,
    };

    this.mediaService = new MediaService();
  }

  componentDidMount() {}

  handleFileSelect = (event) => {
    const file = event.target.files[0];
    this.setState({ selectedFile: file });
  };

  handleMediaUpload = () => {
    const { selectedFile } = this.state;
    if (!selectedFile) {
      return;
    }

    const dataPayload = {
      file: selectedFile,
      directoryName: this.props.mediaDirectory,
    };

    this.mediaService
      .uploadMedia(dataPayload)
      .then((responseData) => {
        console.log('RESPONSE WAS', responseData);

        if (responseData.data) {
          this.setState({
            uploadedMediaIcon: responseData.data.url,
            isFileUploaded: true,
          });
        }
      })
      .catch((errorData) => {
        this.setState({
          loader: false,
          showServerErrors: errorData.message,
        });

        console.log('UNABLE TO ADD MEDIA', errorData);
      });
  };

  resetMediaUpload = () => {
    this.setState(
      {
        isFileUploaded: false,
        uploadedMediaIcon: null,
        selectedFile: null,
      },
      () => {
        setTimeout(() => {
          this.setState({ showFileInput: true });
        }, 1000);
      }
    );
  };

  render() {
    const {
      isFileUploaded,
      selectedFile,
      uploadedMediaIcon,
      showFileInput,
    } = this.state;

    return (
      <div className='image-uploader' style={styles}>
        {!isFileUploaded ? (
          <div className='image-uploader-chooser d-flex align-items-center justify-content-start'>
            <div className='upload-area d-flex align-items-center justify-content-start'>
              <label
                className='upload-area-label position-relative'
                forName='imageUploader'
              >
                {showFileInput && (
                  <input
                    type='file'
                    name='imageUploader'
                    className='upload-area-input'
                    id='imageUploader'
                    onChange={(event) => this.handleFileSelect(event)}
                  />
                )}
                <h6>
                  {selectedFile && selectedFile.name
                    ? selectedFile.name
                    : 'Choose or drop your file here to upload. JPG, PNG, PDF & MP4 formats only.'}
                </h6>
              </label>

              <button
                type='button'
                className='upload-area-trigger'
                onClick={() => this.handleMediaUpload()}
              >
                Upload File
              </button>
            </div>
          </div>
        ) : (
          <div className='image-uploader-display d-flex align-items-center justify-content-start'>
            <div className='display-area d-flex align-items-center justify-content-start'>
              <div className='display-area-label'>
                <h6>{uploadedMediaIcon}</h6>
              </div>

              <button
                type='button'
                className='display-area-trigger'
                onClick={() => this.resetMediaUpload()}
              >
                <FontAwesomeIcon icon={faRedo} />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {}
}
