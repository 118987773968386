import React from 'react';

import styles from './PageLoader.scss';

class PageLoader extends React.Component {
  componentDidMount() {
    document.body.style.overflowY = 'hidden';
  }
  render() {
    return (
      <div
        className='global-loader d-flex align-items-center justify-content-center'
        style={styles}
      >
        <div className='global-loader-wrapper text-center'>
          <img
            src={require('../../images/loader.gif')}
            className='global-loader-image'
            alt='Loading'
          />
          <h4 className='global-loader-text'>Loading...</h4>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto';
  }
}

export default PageLoader;
