import React from "react";

import { get } from "lodash";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { EcomProductTypes } from "../../common/Constants";

import Header from "../../components/Header";
import OrderService from "../../services/orderService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";
import PageLoader from "../../components/PageLoader";

import styles from "./EditEcomOrdersPage.scss";

class EditEcomOrdersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      orderData: null,
    };

    this.orderService = new OrderService();
  }

  componentDidMount() {
    this.getEcomOrderByID();
  }

  getEcomOrderByID = () => {
    this.setState({ loader: true });

    this.orderService
      .getEcomOrderByID({ ecomOrderID: this.props.match.params.id })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            orderData: get(responseData, "data", null),
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log("UNABLE TO FETCH ORDER", errorData);
      });
  };

  getOrderStatus = () => {
    const { orderData } = this.state;
    let orderStatus = "N/A";

    if (orderData) {
      if (orderData.status === 0) {
        orderStatus = "Pending";
      } else if (orderData.status === 1) {
        orderStatus = "Confirmed";
      } else if (orderData.status === 2) {
        orderStatus = "Failed";
      }
    }

    return orderStatus;
  };

  render() {
    let booksCount = 0;
    let ebooksCount = 0;
    let giftCardsCount = 0;
    const { loader, orderData } = this.state;
    const products = get(orderData, "products", []);

    if (products.length) {
      for (let product of products) {
        if (product.type === EcomProductTypes.PACKAGE) {
          giftCardsCount++;
        } else if (product.type === EcomProductTypes.BOOK) {
          booksCount++;
        } else if (product.type === EcomProductTypes.EBOOK) {
          ebooksCount++;
        }
      }
    }

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - E-commerce Order Details</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editecomorder-container">
          <div className="editecomorder-container-wrapper">
            <h1 className="editecomorder-container-title">
              E-commerce Order Details
            </h1>

            <div className="editecomorder-container-content">
              <div className="editecomorder-container-details">
                <h1>Basic Details</h1>
                <p>
                  <b>Status: </b>
                  {this.getOrderStatus()}
                </p>

                <p>
                  <b>ID: </b>
                  {get(orderData, "order.orderId", "N/A")}
                </p>

                <p>
                  <b>Currency: </b>
                  {get(orderData, "order.orderCurrency", "N/A")}
                </p>

                <p>
                  <b>Amount: </b>
                  {get(orderData, "order.orderAmount", "N/A")}
                </p>

                <p>
                  <b>Tax: </b>
                  {get(orderData, "order.productsTax", "N/A")}
                </p>

                <p>
                  <b>Note: </b>
                  {get(orderData, "order.orderNote", "N/A")}
                </p>
              </div>

              <div className="editecomorder-container-details">
                <h1>Billing Details</h1>
                <p>
                  <b>First Name: </b>
                  {get(orderData, "billingDetails.firstName", "N/A")}
                </p>

                <p>
                  <b>Last Name: </b>
                  {get(orderData, "billingDetails.lastName", "N/A")}
                </p>

                <p>
                  <b>Email: </b>
                  {get(orderData, "billingDetails.email", "N/A")}
                </p>

                <p>
                  <b>Mobile Number: </b>
                  {get(orderData, "billingDetails.countryCode")}
                  {get(orderData, "billingDetails.mobileNumber", "N/A")}
                </p>

                <p>
                  <b>Address: </b>
                  {get(orderData, "billingDetails.address", "N/A")}
                </p>

                <p>
                  <b>City: </b>
                  {get(orderData, "billingDetails.city", "N/A")}
                </p>

                <p>
                  <b>State: </b>
                  {get(orderData, "billingDetails.state", "N/A")}
                </p>

                <p>
                  <b>Country: </b>
                  {get(orderData, "billingDetails.country", "N/A")}
                </p>

                <p>
                  <b>Pincode: </b>
                  {get(orderData, "billingDetails.pincode", "N/A")}
                </p>
              </div>

              {orderData &&
                orderData.shippingDetails &&
                typeof orderData.shippingDetails === "object" && (
                  <div className="editecomorder-container-details">
                    <h1>Shipping Details</h1>
                    <p>
                      <b>First Name: </b>
                      {get(orderData, "shippingDetails.firstName", "N/A")}
                    </p>

                    <p>
                      <b>Last Name: </b>
                      {get(orderData, "shippingDetails.lastName", "N/A")}
                    </p>

                    <p>
                      <b>Mobile Number: </b>
                      {get(orderData, "shippingDetails.countryCode")}
                      {get(orderData, "shippingDetails.mobileNumber", "N/A")}
                    </p>

                    <p>
                      <b>Address: </b>
                      {get(orderData, "shippingDetails.address", "N/A")}
                    </p>

                    <p>
                      <b>City: </b>
                      {get(orderData, "shippingDetails.city", "N/A")}
                    </p>

                    <p>
                      <b>State: </b>
                      {get(orderData, "shippingDetails.state", "N/A")}
                    </p>

                    <p>
                      <b>Country: </b>
                      {get(orderData, "shippingDetails.country", "N/A")}
                    </p>

                    <p>
                      <b>Pincode: </b>
                      {get(orderData, "shippingDetails.pincode", "N/A")}
                    </p>
                  </div>
                )}

              <div className="editecomorder-container-details">
                <h1>Products Details</h1>

                {giftCardsCount > 0 && (
                  <>
                    <h2># Gift Cards ({giftCardsCount})</h2>
                    {products && products.length !== 0 && (
                      <div className="replicable-products">
                        {products.map((giftCard, index) => {
                          if (giftCard.type === EcomProductTypes.PACKAGE) {
                            return (
                              <div
                                key={index}
                                className="replicable-products-item"
                              >
                                <p>
                                  <b>Title: </b>
                                  {giftCard.title || "N/A"}
                                </p>
                                <p>
                                  <b>Amount: </b>
                                  {giftCard.value || "N/A"}
                                </p>
                                <p>
                                  <b>Number: </b>
                                  {giftCard.giftCard || "N/A"}
                                </p>
                                <p>
                                  <b>Physical Card Required: </b>
                                  {giftCard.physical ? "Yes" : "No"}
                                </p>
                              </div>
                            );
                          } else
                            return (
                              <React.Fragment key={index}></React.Fragment>
                            );
                        })}
                      </div>
                    )}
                  </>
                )}

                {booksCount > 0 && (
                  <>
                    <h2># Books ({booksCount})</h2>
                    {products && products.length !== 0 && (
                      <div className="replicable-products">
                        {products.map((book, index) => {
                          if (book.type === EcomProductTypes.BOOK) {
                            return (
                              <div
                                key={index}
                                className="replicable-products-item"
                              >
                                <p>
                                  <b>Title: </b>
                                  {book.title || "N/A"}
                                </p>
                                <p>
                                  <b>Description: </b>
                                  {book.description || "N/A"}
                                </p>
                                <p>
                                  <b>Amount: </b>
                                  {get(book, "prices.INR", "N/A")}
                                </p>
                              </div>
                            );
                          } else
                            return (
                              <React.Fragment key={index}></React.Fragment>
                            );
                        })}
                      </div>
                    )}
                  </>
                )}

                {ebooksCount > 0 && (
                  <>
                    <h2># E-Books ({ebooksCount})</h2>
                    {products && products.length !== 0 && (
                      <div className="replicable-products">
                        {products.map((ebook, index) => {
                          if (ebook.type === EcomProductTypes.EBOOK) {
                            return (
                              <div
                                key={index}
                                className="replicable-products-item"
                              >
                                <p>
                                  <b>Title: </b>
                                  {ebook.title || "N/A"}
                                </p>
                                <p>
                                  <b>Description: </b>
                                  {ebook.description || "N/A"}
                                </p>
                                <p>
                                  <b>Amount: </b>
                                  {get(ebook, "prices.INR", "N/A")}
                                </p>
                              </div>
                            );
                          } else
                            return (
                              <React.Fragment key={index}></React.Fragment>
                            );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditEcomOrdersPage));
