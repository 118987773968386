import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Subjects, SYSTEM_PERMISSIONS } from '../../common/Constants';
import { NavigationPaths } from '../../common/Environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUser,
  faBook,
  faChartArea,
  faSchool,
  faGraduationCap,
  faPeopleArrows,
  faFlask,
  faCalculator,
  faFont,
  faStore,
  faSearch,
  faTags,
  faNewspaper,
  faImages,
  faAd,
  // faPercentage,
  // faCreditCard,
  faBox,
  faShoppingCart,
  faVideo,
  faMoneyCheckAlt,
  faChartBar,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import styles from './SideNavigation.scss';

class SideNavigation extends React.Component {
  render() {
    let permissions = [];
    const { adminDetails } = this.props;

    if (
      adminDetails &&
      adminDetails.permissions &&
      adminDetails.permissions.length
    ) {
      permissions = adminDetails.permissions;
    }

    return (
      <aside className="page-sidebar" style={styles}>
        <ul className="page-sidebar-options">
          {permissions.includes(SYSTEM_PERMISSIONS.STATS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.STATSPAGE}>
                <FontAwesomeIcon icon={faHome} />
                Home
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.REPORTS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.REPORTSPAGE}>
                <FontAwesomeIcon icon={faChartBar} />
                Reports
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.CONTENT) && (
            <ul className="sidebar-item">
              <Link to={NavigationPaths.WALKTHROUGHPAGE}>
                <FontAwesomeIcon icon={faBook} />
                Walkthroughs
              </Link>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTWALKTHROUGHPAGE.replace(
                    ':subject',
                    Subjects.MATHS
                  )}
                >
                  <FontAwesomeIcon icon={faCalculator} />
                  {Subjects.MATHS}
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTWALKTHROUGHPAGE.replace(
                    ':subject',
                    Subjects.ENGLISH
                  )}
                >
                  <FontAwesomeIcon icon={faFont} />
                  {Subjects.ENGLISH}
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTWALKTHROUGHPAGE.replace(
                    ':subject',
                    Subjects.SCIENCE
                  )}
                >
                  <FontAwesomeIcon icon={faFlask} />
                  {Subjects.SCIENCE}
                </Link>
              </li>
            </ul>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.CONTENT) && (
            <ul className="sidebar-item">
              <Link to={NavigationPaths.PROJECTPAGE}>
                <FontAwesomeIcon icon={faBook} />
                Projects
              </Link>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTPROJECTPAGE.replace(
                    ':subject',
                    Subjects.MATHS
                  )}
                >
                  <FontAwesomeIcon icon={faCalculator} />
                  {Subjects.MATHS}
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTPROJECTPAGE.replace(
                    ':subject',
                    Subjects.ENGLISH
                  )}
                >
                  <FontAwesomeIcon icon={faFont} />
                  {Subjects.ENGLISH}
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTPROJECTPAGE.replace(
                    ':subject',
                    Subjects.SCIENCE
                  )}
                >
                  <FontAwesomeIcon icon={faFlask} />
                  {Subjects.SCIENCE}
                </Link>
              </li>
            </ul>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.CONTENT) && (
            <ul className="sidebar-item">
              <Link to={NavigationPaths.WORKSHEETPAGE}>
                <FontAwesomeIcon icon={faBook} />
                Worksheets
              </Link>
              <li className="sidebar-item">
                <Link to={NavigationPaths.SEARCHWORKSHEETPAGE}>
                  <FontAwesomeIcon icon={faSearch} />
                  Search
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTWORKSHEETPAGE.replace(
                    ':subject',
                    Subjects.MATHS
                  )}
                >
                  <FontAwesomeIcon icon={faCalculator} />
                  {Subjects.MATHS}
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTWORKSHEETPAGE.replace(
                    ':subject',
                    Subjects.ENGLISH
                  )}
                >
                  <FontAwesomeIcon icon={faFont} />
                  {Subjects.ENGLISH}
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTWORKSHEETPAGE.replace(
                    ':subject',
                    Subjects.SCIENCE
                  )}
                >
                  <FontAwesomeIcon icon={faFlask} />
                  {Subjects.SCIENCE}
                </Link>
              </li>
            </ul>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.CONTENT) && (
            <ul className="sidebar-item">
              <Link to={NavigationPaths.ACTIVITYPAGE}>
                <FontAwesomeIcon icon={faBook} />
                Activities
              </Link>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTACTIVITYPAGE.replace(
                    ':subject',
                    Subjects.MATHS
                  )}
                >
                  <FontAwesomeIcon icon={faCalculator} />
                  {Subjects.MATHS}
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTACTIVITYPAGE.replace(
                    ':subject',
                    Subjects.ENGLISH
                  )}
                >
                  <FontAwesomeIcon icon={faFont} />
                  {Subjects.ENGLISH}
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  to={NavigationPaths.SUBJECTACTIVITYPAGE.replace(
                    ':subject',
                    Subjects.SCIENCE
                  )}
                >
                  <FontAwesomeIcon icon={faFlask} />
                  {Subjects.SCIENCE}
                </Link>
              </li>
            </ul>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.PACKAGES) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.PACKAGEPAGE}>
                <FontAwesomeIcon icon={faChartArea} />
                Packages
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.DURATIONS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.DURATIONPAGE}>
                <FontAwesomeIcon icon={faClock} />
                Durations
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.CLASSES) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.CLASSPAGE}>
                <FontAwesomeIcon icon={faSchool} />
                Classes
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.CLASSES) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.SUBJECTPAGE}>
                <FontAwesomeIcon icon={faGraduationCap} />
                Subjects
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.CLASSES) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.LESSONSPAGE}>
                <FontAwesomeIcon icon={faBook} />
                Lessons
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.ADMINS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.ADMINPAGE}>
                <FontAwesomeIcon icon={faUser} />
                Administrators
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.USERS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.USERPAGE}>
                <FontAwesomeIcon icon={faUser} />
                Users
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.AGENTS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.AGENTPAGE}>
                <FontAwesomeIcon icon={faPeopleArrows} />
                Agents
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.COUPONS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.COUPONPAGE}>
                <FontAwesomeIcon icon={faTags} />
                Coupons
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.USER_PROJECTS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.USERPROJECTPAGE}>
                <FontAwesomeIcon icon={faBook} />
                User Projects
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.SHOP_ORDERS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.ECOMORDERSPAGE}>
                <FontAwesomeIcon icon={faStore} />
                Shop Orders
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.PACKAGE_ORDERS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.PACKAGEORDERSPAGE}>
                <FontAwesomeIcon icon={faBox} />
                Package Orders
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.PRODUCT_ORDERS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.PRODUCTORDERSPAGE}>
                <FontAwesomeIcon icon={faShoppingCart} />
                Product Orders
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.PAYOUTS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.PAYOUTPAGE}>
                <FontAwesomeIcon icon={faMoneyCheckAlt} />
                Payouts
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.NEWS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.NEWSPAGE}>
                <FontAwesomeIcon icon={faNewspaper} />
                News
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.BANNERS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.BANNERSPAGE}>
                <FontAwesomeIcon icon={faImages} />
                Banners
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.ADVERTISEMENTS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.ADVERTISEMENTSPAGE}>
                <FontAwesomeIcon icon={faAd} />
                Advertisements
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.SESSIONS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.SESSIONSPAGE}>
                <FontAwesomeIcon icon={faVideo} />
                Sessions
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.NEWSLETTERS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.NEWSLETTERSPAGE}>
                <FontAwesomeIcon icon={faNewspaper} />
                Newsletters
              </Link>
            </li>
          )}

          {permissions.includes(SYSTEM_PERMISSIONS.VIDEOS) && (
            <li className="sidebar-item">
              <Link to={NavigationPaths.VIDEOSPAGE}>
                <FontAwesomeIcon icon={faVideo} />
                Videos
              </Link>
            </li>
          )}
        </ul>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  adminDetails: state.admin.adminDetails,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavigation);
