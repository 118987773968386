import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { tableDataParser } from './dataParser';
import { NavigationPaths } from '../../common/Environment';

import Table from '../../components/Table';
import Header from '../../components/Header';
import SubjectService from '../../services/subjectService';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import SideNavigation from '../../components/SideNavigation';

import styles from './SubjectPage.scss';
import { Link } from 'react-router-dom';

class SubjectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: ['S. No.', 'Database ID', 'Subject Name', 'Class Name', 'Created On'],
      tableContent: [],
    };

    this.subjectService = new SubjectService();
  }

  componentDidMount() {
    this.getAllSubjects();
  }

  getAllSubjects = () => {
    this.setState({ loader: true });

    this.subjectService
      .getAllSubjects()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        console.log('ERROR: Unable to Fetch Users', errorData);
      });
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className='page-container' style={styles}>
        <Helmet>
          <title>SKIP - Subjects</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className='subject-container'>
          <div className='subject-container-wrapper'>
          <div className='subject-container-header d-flex align-items-center justify-content-between'>
              <h1 className='subject-container-title'>Subjects</h1>
              <Link
                to={NavigationPaths.ADDSUBJECTPAGE}
                className='button button-primary'
              >
                Add Subject
              </Link>
            </div>

            <Table
              hasActions={true}
              hasDisable={false}
              loaderState={loader}
              headerData={tableHeaders}
              tableData={tableContent}
              editPath={NavigationPaths.EDITSUBJECTPAGE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(SubjectPage));
