import moment from "moment";
import { get } from "lodash";
export const tableDataParser = (responseData, columnCount) => {
  if (responseData && responseData.length !== 0) {
    let tableDataArr = [];

    for (let rowIndex = 0; rowIndex < responseData.length; rowIndex++) {
      let currentRowArr = [];
      const currentItem = responseData[rowIndex];
      let orderStatus = "N/A";
      if (currentItem.status === 0) {
        orderStatus = "Pending";
      } else if (currentItem.status === 1) {
        orderStatus = "Confirmed";
      } else if (currentItem.status === 2) {
        orderStatus = "Failed";
      }

      currentRowArr.push(`${rowIndex + 1}.`);
      currentRowArr.push(currentItem._id);
      currentRowArr.push(get(currentItem, "order.orderId", "N/A"));
      currentRowArr.push(get(currentItem, "order.orderAmount", "N/A"));
      currentRowArr.push(get(currentItem, "order.customerName", "N/A"));
      currentRowArr.push(get(currentItem, "products", []).length);
      currentRowArr.push(orderStatus);
      currentRowArr.push(
        moment(currentItem.createdAt).format("DD/MM/YY hh:mm A")
      );
      tableDataArr.push(currentRowArr);
    }

    return tableDataArr;
  } else {
    return [];
  }
};
