import React from "react";

import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { NavigationPaths } from "../../common/Environment";
import queryString from "query-string";

import Header from "../../components/Header";
import PageLoader from "../../components/PageLoader";
import SearchService from "../../services/searchService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";

import styles from "./SearchWorksheetPage.scss";

class SearchWorksheetPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      searchData: [],
      searchState: false,
      formData: { search: "" },
    };

    this.searchService = new SearchService();
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    if (params && params.search) {
      this.setState(
        (state) => ({
          ...state,
          formData: {
            ...state.formData,
            search: params.search,
          },
        }),
        () => this.searchWorksheets()
      );
    }
  }

  searchWorksheets = () => {
    const { formData } = this.state;

    if (!formData.search) {
      return;
    }

    this.setState({ loader: true });

    this.searchService
      .searchWorksheets(formData)
      .then((responseData) => {
        if (responseData) {
          this.setState({
            loader: false,
            searchState: true,
            searchData: responseData.data,
          });
          this.props.history.push(`./?search=${formData.search}`);
        }
      })
      .catch((errorData) => {
        console.log("ERROR: Unable to SEARCH", errorData);
      });
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [fieldName]: fieldValue,
      },
    }));
  };

  render() {
    const { loader, formData, searchData, searchState } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Search Worksheets</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="searchworksheets-container">
          <div className="searchworksheets-container-wrapper">
            <div className="searchworksheets-container-header">
              <h1 className="searchworksheets-container-title">
                Search Worksheets
              </h1>
            </div>

            <div className="searchworksheets-container-header d-flex align-items-center justify-content-between">
              <input
                type="text"
                className="input-field"
                placeholder="Search for Worksheets"
                value={formData.search}
                onChange={(event) =>
                  this.handleFieldUpdate("search", event.target.value)
                }
                onKeyDown={(event) =>
                  event.keyCode === 13 && this.searchWorksheets()
                }
              />

              <button
                type="button"
                className="button button-primary"
                onClick={() => this.searchWorksheets()}
              >
                Search
              </button>
            </div>

            {searchState && (
              <div className="searchworksheets-body">
                {searchData && searchData.length !== 0 ? (
                  searchData.map((searchItem, index) => {
                    return (
                      <div
                        key={index}
                        className="searchworksheets-body-item d-flex align-items-center justify-content-between"
                      >
                        <div className='search-left'>
                          <h5>{`Title: ${searchItem.worksheetTitle}`}</h5>
                          <p>{`Type: ${searchItem.searchType}`}</p>
                          <p className='search-text'>
                            {`Matched Text: `}
                            <i>{`"${searchItem.searchText}"`}</i>
                          </p>
                        </div>
                        <div className='search-right'>
                          <Link
                            className="button button-secondary"
                            to={NavigationPaths.EDITWORKSHEETPAGE.replace(
                              ":id",
                              searchItem.worksheetID
                            )}
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <h5>No Worksheet Found</h5>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(SearchWorksheetPage));
