import React from 'react';
import { get, set } from 'lodash';
import { v4 as uuid } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { QuestionExplanation } from '../QuestionExplanation';
import { QuestionData } from '../QuestionData';
import { UrlRegex, QuestionSubTypePayload } from '../../common/Constants';
import QuestionHeader from '../QuestionHeader';
import QuestionHint from '../QuestionHint';

export default class MCQMSubType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSafeButton: false,
      validationErrors: [],
      showValidationErrors: false,
      formData: Object.assign({}, QuestionSubTypePayload.MCQM),
    };
  }

  componentDidMount() {
    this.prefillQuestionData();
  }

  prefillQuestionData = () => {
    const { currentQuestionData } = this.props;
    if (currentQuestionData) {
      this.setState((state) => ({
        ...state,
        formData: {
          ...currentQuestionData,
          correctAnswer:
            typeof currentQuestionData.correctAnswer === 'string'
              ? currentQuestionData.correctAnswer.split(',')
              : currentQuestionData.correctAnswer,
        },
      }));
    }
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);
    set(formData, fieldName, fieldValue);

    if (fieldName === 'answerType') {
      if (fieldValue === 'text') {
        delete formData.answerData.urls;
        set(formData, 'answerData.texts[0].text', '');
        set(formData, 'answerData.texts[0].id', uuid());
        set(formData, 'correctAnswer', []);
      } else if (fieldValue === 'image') {
        delete formData.answerData.texts;
        set(formData, 'answerData.urls[0].url', '');
        set(formData, 'answerData.urls[0].id', uuid());
        set(formData, 'correctAnswer', []);
      } else {
        delete formData.answerData.urls;
        delete formData.answerData.texts;
        set(formData, 'answerData', {});
        set(formData, 'correctAnswer', []);
      }
    }

    this.setState((state) => ({
      ...state,
      formData,
      showSafeButton: true,
    }));
  };

  handleCorrectAnswerUpdate = (fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    if (!formData.correctAnswer.includes(fieldValue)) {
      formData.correctAnswer.push(fieldValue);
    } else {
      formData.correctAnswer = formData.correctAnswer.filter(
        (val) => val !== fieldValue
      );
    }

    this.setState((state) => ({
      ...state,
      showSafeButton: true,
      formData,
    }));
  };

  handleAddAnswerRow = () => {
    let formData = Object.assign({}, this.state.formData);
    let answerDataLength = 0;
    if (formData.answerType === 'text') {
      answerDataLength = get(formData.answerData, 'texts', []).length;
      set(formData, `answerData.texts[${answerDataLength}].text`, '');
      set(formData, `answerData.texts[${answerDataLength}].id`, uuid());
    } else if (formData.answerType === 'image') {
      answerDataLength = get(formData.answerData, 'urls', []).length;
      set(formData, `answerData.urls[${answerDataLength}].url`, '');
      set(formData, `answerData.urls[${answerDataLength}].id`, uuid());
    }

    this.setState((state) => ({
      ...state,
      formData,
      showSafeButton: true,
    }));
  };

  handleRemoveAnswerRow = (index, answerID) => {
    const { formData } = this.state;
    let answerData = Object.assign({}, formData.answerData);
    if (formData.answerType === 'text') {
      answerData.texts = answerData.texts.filter(
        (data, dataIndex) => dataIndex !== index
      );
    } else if (formData.answerType === 'image') {
      answerData.urls = answerData.urls.filter(
        (data, dataIndex) => dataIndex !== index
      );
    }

    let correctAnswer = Object.assign([], formData.correctAnswer);

    if (answerID) {
      correctAnswer = correctAnswer.filter(
        (data, dataIndex) => data !== answerID
      );
    }

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        answerData,
        correctAnswer,
      },
      showSafeButton: true,
    }));
  };

  handleAddSuggestion = () => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionSuggestions: [...state.formData.questionSuggestions, ''],
      },
      showSafeButton: true,
    }));
  };

  handleRemoveSuggestion = (index) => {
    let questionSuggestions = Object.assign(
      [],
      this.state.formData.questionSuggestions
    );
    questionSuggestions = questionSuggestions.filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionSuggestions,
      },
      showSafeButton: true,
    }));
  };

  handleUpdateQuestion = () => {
    const valid = this.validateFormData();

    if (valid) {
      let formData = Object.assign({}, this.state.formData);
      formData.correctAnswer = formData.correctAnswer.toString();

      this.setState({ showSafeButton: false }, () => {
        this.props.updateQuestion(formData, this.props.position);
      });
    }
  };

  handleAddQuestionData = (dataType) => {
    let questionData = Object.assign([], this.state.formData.questionData);
    questionData.push({
      type: dataType,
      value: '',
      vendor: dataType === 'video' ? '' : null,
    });

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionData,
      },
      showSafeButton: true,
    }));
  };

  handleRemoveQuestionData = (index) => {
    let questionData = Object.assign([], this.state.formData.questionData);

    questionData = questionData.filter(
      (item, itemIndex) => itemIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionData,
      },
      showSafeButton: true,
    }));
  };

  handleAddHint = (questionHintType) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionHint: [
          ...state.formData.questionHint,
          { type: questionHintType, value: '' },
        ],
      },
      showSafeButton: true,
    }));
  };

  handleRemoveHint = (index) => {
    let questionHint = Object.assign([], this.state.formData.questionHint);
    questionHint = questionHint.filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionHint,
      },
      showSafeButton: true,
    }));
  };

  handleAddExplanationText = (fieldName) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        explanation: {
          ...state.formData.explanation,
          [`${fieldName}`]: [...state.formData.explanation[fieldName], ''],
        },
      },
      showSafeButton: true,
    }));
  };

  handleRemoveExplanationText = (fieldName, index) => {
    let explanation = Object.assign({}, this.state.formData.explanation);
    explanation[fieldName] = explanation[fieldName].filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        explanation,
      },
      showSafeButton: true,
    }));
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;
    if (!formData.competency) {
      errorMessagesArr.push('Competency is a required field.');
      isFormValid = false;
    }

    if (!formData.questionData.length) {
      errorMessagesArr.push('Atleast 1 Question Field is required.');
      isFormValid = false;
    } else {
      for (let q of formData.questionData) {
        if (!q.value || (q.type === 'video' && !q.vendor)) {
          errorMessagesArr.push('Question Field Value is required.');
          isFormValid = false;
          break;
        }

        if (q.type === 'url' && !UrlRegex.test(q.value)) {
          errorMessagesArr.push('Question URL is invalid.');
          isFormValid = false;
          break;
        }
      }
    }

    if (!formData.answerType) {
      errorMessagesArr.push('Answer Type is a required field.');
      isFormValid = false;
    } else {
      if (formData.answerType === 'text') {
        if (!formData.answerData.texts.length) {
          errorMessagesArr.push('Answer Text is a required field.');
          isFormValid = false;
        } else {
          for (let at of formData.answerData.texts) {
            if (!at || at.text === '') {
              errorMessagesArr.push('Answer Text is a required field.');
              isFormValid = false;
              break;
            }
          }
        }
      } else {
        if (!formData.answerData.urls.length) {
          errorMessagesArr.push('Answer URL is a required field.');
          isFormValid = false;
        } else {
          for (let au of formData.answerData.urls) {
            if (!au || !au.url) {
              errorMessagesArr.push('Answer URL is a required field.');
              isFormValid = false;
              break;
            }
          }
        }
      }
    }

    if (!formData.correctAnswer.length) {
      errorMessagesArr.push('Correct Answer is required.');
      isFormValid = false;
    }

    if (formData.questionHint.length) {
      for (let qh of formData.questionHint) {
        if (!qh || !qh.value) {
          errorMessagesArr.push('Question Hint is a required field.');
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.questionSuggestions.length) {
      for (let qs of formData.questionSuggestions) {
        if (!qs) {
          errorMessagesArr.push('Question Suggestion is a required field.');
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.explanation.texts.length) {
      for (let e of formData.explanation.texts) {
        if (!e) {
          errorMessagesArr.push(
            'Question Explanation Text is a required field.'
          );
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.explanation.subTexts.length) {
      for (let e of formData.explanation.subTexts) {
        if (!e) {
          errorMessagesArr.push(
            'Question Explanation Text is a required field.'
          );
          isFormValid = false;
          break;
        }
      }
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  render() {
    const {
      formData,
      showSafeButton,
      showValidationErrors,
      validationErrors,
    } = this.state;

    return (
      <div className="content-question">
        <QuestionHeader
          code={formData.code}
          showCompetency={true}
          position={this.props.position}
          competency={formData.competency}
          showQuestionSubTypeSwitcher={false}
          questionSubType={formData.questionSubType}
          handleFieldUpdate={this.handleFieldUpdate}
          removeQuestion={this.props.removeQuestion}
          questionsCount={this.props.questionsCount}
          updatePositionMap={this.props.updatePositionMap}
          positionSwitchMap={this.props.positionSwitchMap}
          questionType={get(this.props.currentQuestionData, 'questionType', '')}
          markedForDeletion={get(
            this.props.currentQuestionData,
            'markedForDeletion',
            ''
          )}
        />

        <div className="content-question-body">
          {showValidationErrors && (
            <div className="error-wrapper">
              <h6>Error!</h6>

              {validationErrors.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          )}

          <QuestionData
            questionData={formData.questionData}
            handleFieldUpdate={this.handleFieldUpdate}
            handleAddQuestionData={this.handleAddQuestionData}
            handleRemoveQuestionData={this.handleRemoveQuestionData}
          />

          <div className="input-wrapper">
            <select
              className="input-select"
              onChange={(event) =>
                this.handleFieldUpdate('answerType', event.target.value)
              }
              value={formData.answerType}
            >
              <option value="">What is the type of Answer?</option>
              <option value={'text'}>Text Answers</option>
              <option value={'image'}>Image Answers</option>
            </select>
          </div>

          <div className="question-data">
            <div className="question-replicable">
              {formData.answerType === 'text' && (
                <React.Fragment>
                  {formData.answerData.texts.length !== 0 &&
                    formData.answerData.texts.map((item, itemIndex) => {
                      return (
                        <div
                          key={itemIndex}
                          className="question-replicable-item d-flex align-items-center justify-content-between"
                        >
                          <div className="input-wrapper">
                            <input
                              type="text"
                              className="input-field"
                              placeholder="Answer text"
                              value={get(item, 'text', '')}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  `answerData.texts[${itemIndex}].text`,
                                  event.target.value
                                )
                              }
                            />
                          </div>

                          <div className="input-wrapper input-wrapper-inline">
                            <label
                              className="input-label d-flex align-items-center justify-content-start"
                              htmlFor={`correctAnswerCheckbox${itemIndex}`}
                            >
                              <input
                                type="checkbox"
                                className="input-checkbox"
                                id={`correctAnswerCheckbox${itemIndex}`}
                                value={get(item, 'id')}
                                checked={formData.correctAnswer.includes(
                                  get(item, 'id')
                                )}
                                onChange={(event) =>
                                  this.handleCorrectAnswerUpdate(
                                    event.target.value
                                  )
                                }
                              />{' '}
                              Mark Correct Answer
                            </label>
                          </div>

                          <button
                            type="button"
                            className="button button-link"
                            onClick={() =>
                              this.handleRemoveAnswerRow(
                                itemIndex,
                                get(item, 'id')
                              )
                            }
                            disabled={
                              get(formData, 'answerData.texts', []).length < 2
                            }
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </div>
                      );
                    })}

                  <div className="question-replicable-trigger">
                    <button
                      type="button"
                      className="button button-link"
                      onClick={() => this.handleAddAnswerRow()}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {` Add Answer Field`}
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>

          <div className="question-data">
            <div className="question-replicable">
              {formData.answerType === 'image' && (
                <React.Fragment>
                  {formData.answerData.urls.length !== 0 &&
                    formData.answerData.urls.map((item, itemIndex) => {
                      return (
                        <div
                          key={itemIndex}
                          className="question-replicable-item d-flex align-items-center justify-content-between"
                        >
                          <div className="input-wrapper">
                            <input
                              type="text"
                              className="input-field"
                              placeholder="Answer URL"
                              value={get(item, 'url', '')}
                              onChange={(event) =>
                                this.handleFieldUpdate(
                                  `answerData.urls[${itemIndex}].url`,
                                  event.target.value
                                )
                              }
                            />
                          </div>

                          <div className="input-wrapper input-wrapper-inline">
                            <label
                              className="input-label d-flex align-items-center justify-content-start"
                              htmlFor={`correctAnswerCheckbox${itemIndex}`}
                            >
                              <input
                                type="checkbox"
                                className="input-checkbox"
                                id={`correctAnswerCheckbox${itemIndex}`}
                                value={get(item, 'id')}
                                checked={formData.correctAnswer.includes(
                                  get(item, 'id')
                                )}
                                onChange={(event) =>
                                  this.handleCorrectAnswerUpdate(
                                    event.target.value
                                  )
                                }
                              />{' '}
                              Mark Correct Answer
                            </label>
                          </div>

                          <button
                            type="button"
                            className="button button-link"
                            onClick={() =>
                              this.handleRemoveAnswerRow(
                                itemIndex,
                                get(item, 'id')
                              )
                            }
                            disabled={
                              get(formData, 'answerData.urls', []).length < 2
                            }
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </div>
                      );
                    })}

                  <div className="question-replicable-trigger">
                    <button
                      type="button"
                      className="button button-link"
                      onClick={() => this.handleAddAnswerRow()}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {` Add Answer Field`}
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>

          <QuestionHint
            handleAddHint={this.handleAddHint}
            questionHint={formData.questionHint}
            handleRemoveHint={this.handleRemoveHint}
            handleFieldUpdate={this.handleFieldUpdate}
          />

          <div className="question-data">
            <div className="question-replicable">
              {formData.questionSuggestions.length !== 0 &&
                formData.questionSuggestions.map((suggestion, index) => {
                  return (
                    <div
                      key={index}
                      className="question-replicable-item d-flex align-items-center justify-content-between"
                    >
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="input-field"
                          placeholder={`Answer Suggestion ${index + 1}`}
                          value={suggestion}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              `questionSuggestions[${index}]`,
                              event.target.value
                            )
                          }
                        />
                      </div>

                      <button
                        type="button"
                        className="button button-link"
                        onClick={() => this.handleRemoveSuggestion(index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  );
                })}

              <div className="question-replicable-trigger">
                <button
                  type="button"
                  className="button button-link"
                  onClick={() => this.handleAddSuggestion()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {` Add Answer Suggestion`}
                </button>
              </div>
            </div>
          </div>

          <QuestionExplanation
            explanation={formData.explanation}
            handleFieldUpdate={this.handleFieldUpdate}
            handleAddExplanationText={this.handleAddExplanationText}
            handleRemoveExplanationText={this.handleRemoveExplanationText}
          />

          {showSafeButton && (
            <div className="input-wrapper">
              <button
                type="button"
                className="button button-secondary"
                onClick={() => this.handleUpdateQuestion()}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
