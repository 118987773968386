import React from "react";

import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { NavigationPaths } from "../../common/Environment";

import Header from "../../components/Header";
import ClassService from "../../services/classService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";

import styles from "./AddClassPage.scss";

class AddClassPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      classesArr: [],
      formData: {
        class: "",
      },
      validationErrors: [],
      showServerErrors: "",
      showValidationErrors: false,
    };

    this.classService = new ClassService();
  }

  componentDidMount() {}

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.class === "") {
      errorMessagesArr.push("Class is a required field.");
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log("Invalid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log("Valid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: "",
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form

      const { formData } = this.state;

      const dataPayload = {
        class: formData.class,
      };

      this.classService
        .addClass(dataPayload)
        .then((responseData) => {
          console.log("SUCCESSFULLY ADDED NEW CLASS", responseData);

          if (responseData) {
            this.props.history.push(NavigationPaths.CLASSPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          console.log("UNABLE TO ADD NEW CLASS", errorData);
        });
    }
  };

  render() {
    const {
      formData,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Add New Class</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addclass-container">
          <div className="addclass-container-wrapper">
            <h1 className="addclass-container-title">Add New Class</h1>

            <div className="addclass-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== "" && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Class Name"
                  value={formData.class}
                  onChange={(event) =>
                    this.handleFieldUpdate("class", event.target.value)
                  }
                />
              </div>
              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Add New Class
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AddClassPage));
