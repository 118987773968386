import React from "react";
import set from "lodash/set";

import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { NavigationPaths } from "../../common/Environment";

import Header from "../../components/Header";
import PageLoader from '../../components/PageLoader';
import PackageService from "../../services/packageService";
import SideNavigation from "../../components/SideNavigation";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";

import styles from "./AddDurationPage.scss";

class AddDurationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      formData: {
        offer: "0",
        duration: "",
        prices: [ 
          {subjects: 1, amount: ""},
          {subjects: 2, amount: ""},
          {subjects: 3, amount: ""}
      ]
      },
      validationErrors: [],
      showServerErrors: "",
      showValidationErrors: false,
    };

    this.packageService = new PackageService();
  }


  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (!formData.duration ||
      !/^[0-9]*$/.test(formData.duration) ||
      parseInt(formData.duration) < 1) {
      errorMessagesArr.push("Duration is a required field.");
      isFormValid = false;
    }

    for (let d of formData.prices) {
      if (
        !d ||
        !d.amount ||
        !/^[0-9]*$/.test(d.amount) ||
        parseInt(d.amount) < 1
      ) {
        errorMessagesArr.push("Amount is a required field.");
        isFormValid = false;
        break;
      }
    }

    if (
      formData.offer &&
      (!/^[0-9]*$/.test(formData.offer) ||
      parseInt(formData.offer) < 0 ||
      parseInt(formData.offer) > 100)
    ) {
      errorMessagesArr.push("Offer is invalid.");
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: "",
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);
    set(formData, fieldName, fieldValue);

    this.setState(
      (state) => ({
        ...state,
        formData,
      })
    );
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form

      const { formData } = this.state;

      const dataPayload = {
        offer: formData.offer,
        prices: formData.prices,
        duration: formData.duration,
      };

      this.packageService
        .addDuration(dataPayload)
        .then((responseData) => {
          if (responseData) {
            this.props.history.push(NavigationPaths.DURATIONPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });
        });
    }
  };

  render() {
    const {
      loader,
      formData,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Add New Package Duration</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addduration-container">
          <div className="addduration-container-wrapper">
            <h1 className="addduration-container-title">Add New Package Duration</h1>

            <div className="addduration-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== "" && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <label>Duration (Months)</label>
                <input
                  type="text"
                  className="input-field"
                  placeholder="Duration"
                  value={formData.duration}
                  onChange={(event) =>
                    this.handleFieldUpdate("duration", event.target.value)
                  }
                />
              </div>

              <div className="rows-replicable">
                <label>Subjects Wise Pricing</label>
                {formData.prices.map((price, index) => {
                    return (
                      <div
                        key={index}
                        className="rows-replicable-item d-flex align-items-center justify-content-between"
                      >
                        <div className="input-wrapper">
                          <label>Number of Subjects</label>
                          <input
                            disabled
                            type="text"
                            className="input-field"
                            placeholder="Number of Subjects"
                            value={price.subjects}
                          />
                        </div>

                        <div className="input-wrapper">
                          <label>Price (INR)</label>
                          <input
                            type="text"
                            className="input-field"
                            placeholder="Price (INR)"
                            value={price.amount}
                            onChange={(event) =>
                              this.handleFieldUpdate(
                                `prices[${index}].amount`,
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="input-wrapper">
                <label>Offer (%)</label>
                <input
                  type="text"
                  className="input-field"
                  placeholder="Offer (%)"
                  value={formData.offer}
                  onChange={(event) =>
                    this.handleFieldUpdate(`offer`, event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Add New Package Duration
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AddDurationPage));
