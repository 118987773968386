import axiosInstance from './CreateService';
import { ApiConstants } from '../common/Environment';

export default class UserService {
  getAllUsers() {
    return axiosInstance.get(ApiConstants.GETUSERS);
  }

  addUser(dataPayload) {
    return axiosInstance.post(ApiConstants.ADDUSERS, dataPayload);
  }
}
