import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { QuestionSubTypes } from "../../common/Constants";

export default class QuestionHeader extends React.Component {
  getRenderingPositions = () => {
    const { questionsCount } = this.props;
    let positionsArr = [];
    if (questionsCount) {
      for (let i = 1; i <= questionsCount; i++) {
        positionsArr.push(
          <option key={i} value={`${i}`}>
            {i}
          </option>
        );
      }
    }

    return positionsArr;
  };

  render() {
    const {
      code,
      position,
      competency,
      questionType,
      showCompetency,
      questionSubType,
      markedForDeletion,
      positionSwitchMap,
      showQuestionSubTypeSwitcher,
    } = this.props;
    return (
      <>
        <div className="content-question-header d-flex align-items-center justify-content-between">
          <div className="content-header-item content-header-count">
            Current Position: {position + 1}
          </div>
          {questionSubType !== QuestionSubTypes.EMPTY && (
            <div className="content-header-item content-header-count">
              <div className="input-wrapper">
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.props.updatePositionMap(
                      code,
                      event.currentTarget.value
                    )
                  }
                  value={(positionSwitchMap && positionSwitchMap[code]) || ""}
                >
                  <option value="">Final Position</option>
                  {this.getRenderingPositions()}
                </select>
              </div>
            </div>
          )}
        </div>
        <div className="content-question-header d-flex align-items-center justify-content-between">
          <div className="content-header-item content-header-code">
            Code: {code}
          </div>
          <div className="content-header-item content-header-type">
            Type: Q-{questionType} | A-{questionSubType}{" "}
            {showQuestionSubTypeSwitcher && (
              <button
                type="button"
                className="button button-link"
                onClick={() =>
                  this.props.handleFieldUpdate("questionSubType", null)
                }
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
            )}
          </div>
          {showCompetency && (
            <div className="content-header-item content-header-competency">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Competency"
                  value={competency}
                  onChange={(event) =>
                    this.props.handleFieldUpdate(
                      "competency",
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          )}
          <div className="content-header-item content-header-remove">
            <button
              type="button"
              className="button button-link"
              disabled={markedForDeletion}
              onClick={() => this.props.removeQuestion(position)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />{" "}
              {markedForDeletion ? "Deleted" : `Remove`}
            </button>
          </div>
        </div>
      </>
    );
  }
}
