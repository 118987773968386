import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import ContentService from "../../services/contentService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";
import PageLoader from "../../components/PageLoader";

import styles from "./EditUserProjectPage.scss";
import { get } from "lodash";

class EditUserProjectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      currentProject: null,
      validationErrors: [],
      showServerErrors: "",
      showValidationErrors: false,
    };

    this.contentServive = new ContentService();
  }

  componentDidMount() {
    this.getProjectData();
  }

  getProjectData = () => {
    this.setState({ loader: true });

    const dataPayload = {
      userProjectID: this.props.match.params.id,
    };

    this.contentServive
      .getUserProjectByID(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData.data) {
          this.setState((state) => ({
            ...state,
            currentProject: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log("UNABLE TO FETCH DATA", errorData);
      });
  };

  handleFormSubmit = () => {
    this.setState({ loader: true });

    let dataPayload = {
      userProjectID: this.props.match.params.id,
    };

    this.contentServive
      .toggleUserProjectFeatured(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });
        console.log("SUCCESSFULLY Edited Content", responseData);
        if (responseData.data) {
          this.setState({ currentProject: responseData.data });
        }
      })
      .catch((errorData) => {
        this.setState({
          loader: false,
          showServerErrors: errorData.message,
        });

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

        console.log("UNABLE TO Edited CONTENT", errorData);
      });
  };

  render() {
    const {
      loader,
      currentProject,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - View User Project</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="edituserproject-container">
          <div className="edituserproject-container-wrapper">
            <h1 className="edituserproject-container-title">View User Project</h1>

            <div className="edituserproject-container-content">
              <div className="edituserproject-container-metadata">
                {showValidationErrors && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    {validationErrors.map((item, index) => {
                      return <p key={index}>{item}</p>;
                    })}
                  </div>
                )}

                {showServerErrors !== "" && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    <p>{showServerErrors}</p>
                  </div>
                )}

                <h5>User Details</h5>

                <div className="input-wrapper">
                  <p>
                    <b>User Name: </b>
                    {get(currentProject, "userID.name", "N/A")}
                  </p>
                </div>

                <div className="input-wrapper">
                  <p>
                    <b>User Email: </b>
                    {get(currentProject, "userID.email", "N/A")}
                  </p>
                </div>

                <div className="input-wrapper">
                  <p>
                    <b>User Mobile Number: </b>
                    {get(currentProject, "userID.formattedNumber", "N/A")}
                  </p>
                </div>

                <hr />

                <h5>Project Details</h5>

                <div className="input-wrapper">
                  <p>
                    <b>Class Name: </b>
                    {get(
                      currentProject,
                      "project.chapterID.subjectID.classID.class",
                      "N/A"
                    )}
                  </p>
                </div>

                <div className="input-wrapper">
                  <p>
                    <b>Subject Name: </b>
                    {get(
                      currentProject,
                      "project.chapterID.subjectID.subject",
                      "N/A"
                    )}
                  </p>
                </div>

                <div className="input-wrapper">
                  <p>
                    <b>Chapter Name: </b>
                    {get(currentProject, "project.chapterID.chapter", "N/A")}
                  </p>
                </div>

                <div className="input-wrapper">
                  <p>
                    <b>Project Title: </b>
                    {get(currentProject, "project.title", "N/A")}
                  </p>
                </div>

                <div className="input-wrapper">
                  <p>
                    <b>Project Attachments: </b>
                  </p>

                  {currentProject &&
                    currentProject.attachments &&
                    currentProject.attachments.length !== 0 && (
                      <div className='edituserproject-attachments'>
                        {currentProject.attachments.map((file, index) => {
                          return (
                            <a
                              key={index}
                              href={file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Attachment {index + 1}
                            </a>
                          );
                        })}
                      </div>
                    )}
                </div>
              </div>

              <hr />

              {currentProject && (
                <div className="content-context">
                  <div className="content-context-trigger">
                    <button
                      type="button"
                      className="button button-primary"
                      onClick={() => this.handleFormSubmit()}
                    >
                      {currentProject.featured
                        ? "Remove From Featured Projects"
                        : "Feature Project"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditUserProjectPage));
