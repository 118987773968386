import moment from 'moment';
import { get } from 'lodash';
export const tableDataParser = (responseData, columnCount) => {
  if (responseData && responseData.length !== 0) {
    let tableDataArr = [];

    for (let rowIndex = 0; rowIndex < responseData.length; rowIndex++) {
      let currentRowArr = [];
      const currentItem = responseData[rowIndex];

      currentRowArr.push(`${rowIndex + 1}.`);
      currentRowArr.push(currentItem._id);
      currentRowArr.push(currentItem.transferId);
      currentRowArr.push(currentItem.referenceId);
      currentRowArr.push(`Rs. ${currentItem.amount}`);
      currentRowArr.push(get(currentItem, 'salesAgent.name', 'N/A'));
      currentRowArr.push(
        moment(currentItem.createdAt).format('DD/MM/YY hh:mm A')
      );
      tableDataArr.push(currentRowArr);
    }

    return tableDataArr;
  } else {
    return [];
  }
};
