import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import LmsService from '../../services/lmsService';
import PageLoader from '../../components/PageLoader';
import ClassService from '../../services/classService';
import SubjectService from '../../services/subjectService';
import ImageUploader from '../../components/ImageUploader';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './EditSessionPage.scss';

class EditSessionPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      classesArr: [],
      subjectsArr: [],
      chaptersArr: [],
      formData: {
        title: '',
        url: '',
        public: '',
        thumbnail: '',
        subjectID: '',
        classID: '',
        chapterID: '',
        endDate: null,
        startDate: null,
        source: 'Direct',
        mediaDirectory: uuid(),
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.lmsService = new LmsService();
    this.classService = new ClassService();
    this.subjectService = new SubjectService();
  }

  componentDidMount() {
    this.getSessionsByID();
  }

  getSessionsByID = () => {
    this.setState({ loader: true });
    this.lmsService
      .getSessionsByID({ sessionID: this.props.match.params.id })
      .then((responseData) => {
        if (responseData) {
          this.setState(
            (state) => ({
              ...state,
              loader: false,
              formData: {
                endDate: responseData.data.endDate
                  ? new Date(responseData.data.endDate)
                  : null,
                startDate: responseData.data.startDate
                  ? new Date(responseData.data.startDate)
                  : null,
                url: get(responseData, 'data.url') || '',
                title: get(responseData, 'data.title') || '',
                classID: get(responseData, 'data.classID') || '',
                thumbnail: get(responseData, 'data.thumbnail') || '',
                subjectID: get(responseData, 'data.subjectID') || '',
                chapterID: get(responseData, 'data.chapterID') || '',
                source: get(responseData, 'data.source') || 'Direct',
                mediaDirectory:
                  get(responseData, 'data.mediaDirectory') || uuid(),
                public:
                  get(responseData, 'data.public') === true ? 'true' : 'false',
              },
            }),
            () => {
              this.fetchAllClasses();
              this.getSubjectByClass();
              this.getChaptersBySubject();
            }
          );
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  fetchAllClasses = () => {
    this.setState({ loader: true });

    this.classService
      .getAllClasses()
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            classesArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  getSubjectByClass = () => {
    const { classID } = this.state.formData;

    if (!classID) {
      return;
    }

    this.setState({ loader: true });

    this.subjectService
      .getSubjectsByClass({ classID })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            subjectsArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  getChaptersBySubject = () => {
    const { subjectID } = this.state.formData;

    if (!subjectID) {
      return;
    }

    this.setState({ loader: true });

    this.subjectService
      .getChapters({ subjectID })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            chaptersArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.public !== 'true') {
      if (formData.classID === '') {
        errorMessagesArr.push('Class is a required field.');
        isFormValid = false;
      }

      if (formData.subjectID === '') {
        errorMessagesArr.push('Subject is a required field.');
        isFormValid = false;
      }

      if (formData.chapterID === '') {
        errorMessagesArr.push('Selection of Lesson is required.');
        isFormValid = false;
      }
    }

    if (formData.title === '') {
      errorMessagesArr.push('Title is a required field.');
      isFormValid = false;
    }

    if (formData.url === '') {
      errorMessagesArr.push('Session URL is invalid.');
      isFormValid = false;
    }

    if (formData.thumbnail === '') {
      errorMessagesArr.push('Thumbnail URL is invalid.');
      isFormValid = false;
    }

    if (!formData.startDate) {
      errorMessagesArr.push('Start Date is a required field.');
      isFormValid = false;
    }

    if (!formData.endDate) {
      errorMessagesArr.push('End Date is a required field.');
      isFormValid = false;
    }

    if (
      formData.endDate &&
      formData.startDate &&
      moment(formData.endDate).isSameOrBefore(formData.startDate)
    ) {
      errorMessagesArr.push('End Date must be greater than the Start Date.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState(
      (state) => ({
        ...state,
        formData: {
          ...state.formData,
          [`${fieldName}`]: fieldValue,
        },
      }),
      () => {
        if (fieldName === 'classID') {
          this.setState((state) => ({
            ...state,
            formData: { ...state.formData, subjectID: '' },
          }));
          this.getSubjectByClass();
        } else if (fieldName === 'subjectID') {
          this.setState((state) => ({
            ...state,
            formData: { ...state.formData, chapterID: '' },
          }));
          this.getChaptersBySubject();
        }
      }
    );
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      const dataPayload = {
        ...formData,
        sessionID: this.props.match.params.id,
        public: formData.public === 'true' ? true : false,
      };

      this.lmsService
        .editSessions(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });

          if (responseData) {
            this.props.history.push(NavigationPaths.SESSIONSPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const {
      loader,
      formData,
      classesArr,
      subjectsArr,
      chaptersArr,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Session</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editsession-container">
          <ImageUploader mediaDirectory={formData.mediaDirectory} />

          <div className="editsession-container-wrapper">
            <h1 className="editsession-container-title">Edit Session</h1>

            <div className="editsession-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <label>Is Session Public?</label>
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('public', event.target.value)
                  }
                  value={formData.public}
                >
                  <option value={'true'}>Yes</option>
                  <option value={'false'}>No</option>
                </select>
              </div>

              {formData.public !== 'true' && (
                <>
                  <div className="input-wrapper">
                    <label>Class</label>
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleFieldUpdate('classID', event.target.value)
                      }
                      value={formData.classID}
                    >
                      <option value="">Choose a Class</option>

                      {classesArr.map((item, index) => {
                        return (
                          <option key={index} value={item._id}>
                            {item.class}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="input-wrapper">
                    <label>Subject</label>
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleFieldUpdate('subjectID', event.target.value)
                      }
                      value={formData.subjectID}
                      disabled={!formData.classID ? true : false}
                    >
                      <option value="" disabled>
                        Choose a Subject
                      </option>

                      {formData.classID &&
                        subjectsArr.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.subject}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="input-wrapper">
                    <label>Lesson</label>
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleFieldUpdate('chapterID', event.target.value)
                      }
                      value={formData.chapterID}
                      disabled={!formData.subjectID ? true : false}
                    >
                      <option value="" disabled>
                        Choose a Lesson
                      </option>

                      {formData.subjectID &&
                        chaptersArr.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.chapter}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </>
              )}

              <div className="input-wrapper">
                <label>Title</label>

                <input
                  type="text"
                  className="input-field"
                  placeholder="Title"
                  value={formData.title}
                  onChange={(event) =>
                    this.handleFieldUpdate('title', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <label>Thumbnail URL</label>

                <input
                  type="text"
                  className="input-field"
                  placeholder="Thumbnail URL"
                  value={formData.thumbnail}
                  onChange={(event) =>
                    this.handleFieldUpdate('thumbnail', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <label>Source</label>
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('source', event.target.value)
                  }
                  value={formData.source}
                >
                  <option value={'Direct'}>Direct</option>
                  <option value={'Youtube'}>Youtube</option>
                  <option value={'Vimeo'}>Vimeo</option>
                </select>
              </div>

              <div className="input-wrapper">
                <label>Video URL / ID</label>
                <input
                  type="text"
                  className="input-field"
                  placeholder="Video URL / ID"
                  value={formData.url}
                  onChange={(event) =>
                    this.handleFieldUpdate('url', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <label>Start Date</label>
                <DatePicker
                  showTimeSelect
                  showYearDropdown
                  showMonthDropdown
                  minDate={new Date()}
                  dropdownMode="select"
                  timeFormat="HH:mm:ss"
                  className="input-field"
                  value={formData.startDate}
                  placeholderText="Start Date"
                  selected={formData.startDate}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  onChange={(date) => this.handleFieldUpdate('startDate', date)}
                />
              </div>

              <div className="input-wrapper">
                <label>End Date</label>
                <DatePicker
                  showTimeSelect
                  showYearDropdown
                  showMonthDropdown
                  minDate={new Date()}
                  dropdownMode="select"
                  timeFormat="HH:mm:ss"
                  className="input-field"
                  value={formData.endDate}
                  placeholderText="End Date"
                  selected={formData.endDate}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  onChange={(date) => this.handleFieldUpdate('endDate', date)}
                />
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Edit Session
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditSessionPage));
