import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { tableDataParser } from './dataParser';
import { NavigationPaths } from '../../common/Environment';

import Table from '../../components/Table';
import Header from '../../components/Header';
import LmsService from '../../services/lmsService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './NewsletterPage.scss';

class NewsletterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: ['S. No.', 'Database ID', 'Title', 'Status', 'Created On'],
      tableContent: [],
    };

    this.lmsService = new LmsService();
  }

  componentDidMount() {
    this.getNewsletters();
  }

  getNewsletters = () => {
    this.setState({ loader: true });

    this.lmsService
      .getNewsletters()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);
          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  hanldeToggle = (newsletterID) => {
    if (!newsletterID) {
      return;
    }

    const dataPayload = {
      newsletterID,
    };

    this.lmsService
      .toggleNewsletters(dataPayload)
      .then(() => {
        this.getNewsletters();
      })
      .catch((errorData) => {});
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Newsletters</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="newsletters-container">
          <div className="newsletters-container-wrapper">
            <div className="newsletters-container-header d-flex align-items-center justify-content-between">
              <h1 className="newsletters-container-title">Newsletters</h1>
              <Link
                className="button button-primary"
                to={NavigationPaths.ADDNEWSLETTERSPAGE}
              >
                Add Newsletter
              </Link>
            </div>

            <Table
              hasActions={true}
              hasDisable={true}
              loaderState={loader}
              headerData={tableHeaders}
              tableData={tableContent}
              disablePath={this.hanldeToggle}
              editPath={NavigationPaths.EDITNEWSLETTERSPAGE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(NewsletterPage));
