import axiosInstance from "./CreateService";
import { ApiConstants } from "../common/Environment";

export default class ClassService {
  addContent(dataPayload) {
    return axiosInstance.post(ApiConstants.ADDCONTENT, dataPayload);
  }

  addClass(dataPayload) {
    return axiosInstance.post(ApiConstants.ADDCLASSES, dataPayload);
  }

  getContent(dataPayload) {
    let url = `${ApiConstants.GETCONTENT}?resourceType=${dataPayload.resourceType}`;
    if (dataPayload.subject) {
      url = `${url}&subject=${dataPayload.subject}`;
    }

    return axiosInstance.get(url);
  }

  getContentByID(dataPayload) {
    let url = `${ApiConstants.GETCONTENTBYID}?resourceType=${dataPayload.resourceType}&resourceID=${dataPayload.resourceID}`;
    return axiosInstance.get(url);
  }

  editContent(dataPayload) {
    return axiosInstance.put(ApiConstants.EDITCONTENT, dataPayload);
  }

  getUserProjects() {
    return axiosInstance.get(ApiConstants.GETUSERPROJECTS);
  }

  getUserProjectByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETUSERPROJECTBYID.replace(
        ":userProjectID",
        dataPayload.userProjectID
      )
    );
  }

  toggleUserProjectFeatured(dataPayload) {
    return axiosInstance.put(
      ApiConstants.TOGGLEUSERPROJECTSFEATURED,
      dataPayload
    );
  }

  deleteContent(dataPayload) {
    return axiosInstance.post(ApiConstants.DELETECONTENT, dataPayload);
  }

  toggleContent(dataPayload) {
    return axiosInstance.put(ApiConstants.TOGGLECONTENT, dataPayload);
  }

  copyResources(dataPayload) {
    return axiosInstance.post(ApiConstants.COPYRESOURCES, dataPayload);
  }
}
