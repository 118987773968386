import React from 'react';
import { get, set } from 'lodash';
import { v4 as uuid } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { QuestionExplanation } from '../QuestionExplanation';
import { QuestionData } from '../QuestionData';
import {
  UrlRegex,
  QuestionSubTypes,
  QuestionSubTypePayload,
} from '../../common/Constants';
import styles from './TBLTSubType.scss';
import QuestionHeader from '../QuestionHeader';
import QuestionHint from '../QuestionHint';
export default class TBLTSubType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSafeButton: false,
      tableColumns: '0',
      validationErrors: [],
      showValidationErrors: false,
      formData: Object.assign({}, QuestionSubTypePayload.TBLT),
    };
  }

  componentDidMount() {
    this.prefillQuestionData();
  }

  prefillQuestionData = () => {
    const { currentQuestionData } = this.props;

    if (currentQuestionData) {
      this.setState((state) => ({
        ...state,
        formData: currentQuestionData,
      }));
    }

    const texts = get(currentQuestionData, 'answerData.texts[0]', []);
    if (texts && (texts.length === 2 || texts.length === 3)) {
      this.setState({ tableColumns: texts.length.toString() });
    }
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    if (fieldName === 'questionSubType') {
      fieldValue =
        formData.questionSubType === QuestionSubTypes.TBLT
          ? QuestionSubTypes.TBLS
          : QuestionSubTypes.TBLT;
    }

    set(formData, fieldName, fieldValue);

    this.setState((state) => ({
      ...state,
      formData,
      showSafeButton: true,
    }));
  };

  handleAddTableRow = () => {
    let formData = Object.assign({}, this.state.formData);
    let answerDataLength = 0;
    answerDataLength = get(formData.answerData, 'texts', []).length;

    if (this.state.tableColumns === '2') {
      set(formData, `answerData.texts[${answerDataLength}]`, [
        { type: '', text: '', id: uuid() },
        { type: '', text: '', id: uuid() },
      ]);
    } else {
      set(formData, `answerData.texts[${answerDataLength}]`, [
        { type: '', text: '', id: uuid() },
        { type: '', text: '', id: uuid() },
        { type: '', text: '', id: uuid() },
      ]);
    }

    this.setState((state) => ({
      ...state,
      formData,
      showSafeButton: true,
    }));
  };

  handleRemoveTableRow = (index) => {
    let answerData = Object.assign({}, this.state.formData.answerData);

    answerData.texts = answerData.texts.filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        answerData,
      },
      showSafeButton: true,
    }));
  };

  handleAddSuggestion = () => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionSuggestions: [...state.formData.questionSuggestions, ''],
      },
      showSafeButton: true,
    }));
  };

  handleRemoveSuggestion = (index) => {
    let questionSuggestions = Object.assign(
      [],
      this.state.formData.questionSuggestions
    );
    questionSuggestions = questionSuggestions.filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionSuggestions,
      },
      showSafeButton: true,
    }));
  };

  handleUpdateQuestion = () => {
    const valid = this.validateFormData();

    if (valid) {
      this.setState({ showSafeButton: false }, () => {
        this.props.updateQuestion(this.state.formData, this.props.position);
      });
    }
  };

  handleAddQuestionData = (dataType) => {
    let questionData = Object.assign([], this.state.formData.questionData);
    questionData.push({
      type: dataType,
      value: '',
      vendor: dataType === 'video' ? '' : null,
    });

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionData,
      },
      showSafeButton: true,
    }));
  };

  handleRemoveQuestionData = (index) => {
    let questionData = Object.assign([], this.state.formData.questionData);

    questionData = questionData.filter(
      (item, itemIndex) => itemIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionData,
      },
      showSafeButton: true,
    }));
  };

  handleStateUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    if (fieldName === 'tableColumns') {
      formData.answerData.texts = [];

      if (fieldValue === '2') {
        set(formData, 'answerData.texts[0]', [
          { type: '', text: '', id: uuid() },
          { type: '', text: '', id: uuid() },
        ]);
      } else if (fieldValue === '3') {
        set(formData, 'answerData.texts[0]', [
          { type: '', text: '', id: uuid() },
          { type: '', text: '', id: uuid() },
          { type: '', text: '', id: uuid() },
        ]);
      }
    }

    this.setState((state) => ({
      ...state,
      showSafeButton: true,
      [`${fieldName}`]: fieldValue,
      formData,
    }));
  };

  handleAddHint = (questionHintType) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionHint: [
          ...state.formData.questionHint,
          { type: questionHintType, value: '' },
        ],
      },
      showSafeButton: true,
    }));
  };

  handleRemoveHint = (index) => {
    let questionHint = Object.assign([], this.state.formData.questionHint);
    questionHint = questionHint.filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionHint,
      },
      showSafeButton: true,
    }));
  };

  handleAddExplanationText = (fieldName) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        explanation: {
          ...state.formData.explanation,
          [`${fieldName}`]: [...state.formData.explanation[fieldName], ''],
        },
      },
      showSafeButton: true,
    }));
  };

  handleRemoveExplanationText = (fieldName, index) => {
    let explanation = Object.assign({}, this.state.formData.explanation);
    explanation[fieldName] = explanation[fieldName].filter(
      (data, dataIndex) => dataIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        explanation,
      },
      showSafeButton: true,
    }));
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;
    if (!formData.competency) {
      errorMessagesArr.push('Competency is a required field.');
      isFormValid = false;
    }

    if (!formData.questionData.length) {
      errorMessagesArr.push('Atleast 1 Question Field is required.');
      isFormValid = false;
    } else {
      for (let q of formData.questionData) {
        if (!q.value || (q.type === 'video' && !q.vendor)) {
          errorMessagesArr.push('Question Field Value is required.');
          isFormValid = false;
          break;
        }

        if (q.type === 'url' && !UrlRegex.test(q.value)) {
          errorMessagesArr.push('Question URL is invalid.');
          isFormValid = false;
          break;
        }
      }
    }

    if (!formData.answerData.texts.length) {
      errorMessagesArr.push('Table Type is required');
      isFormValid = false;
    } else {
      for (let q of formData.answerData.texts) {
        for (let a of q) {
          if (!a || a.type === '' || a.text === '') {
            errorMessagesArr.push('Table Cell Text/Type is a required field.');
            isFormValid = false;
            break;
          }
        }
      }
    }

    if (formData.questionHint.length) {
      for (let qh of formData.questionHint) {
        if (!qh || !qh.value) {
          errorMessagesArr.push('Question Hint is a required field.');
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.questionSuggestions.length) {
      for (let qs of formData.questionSuggestions) {
        if (!qs) {
          errorMessagesArr.push('Question Suggestion is a required field.');
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.explanation.texts.length) {
      for (let e of formData.explanation.texts) {
        if (!e) {
          errorMessagesArr.push(
            'Question Explanation Text is a required field.'
          );
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.explanation.subTexts.length) {
      for (let e of formData.explanation.subTexts) {
        if (!e) {
          errorMessagesArr.push(
            'Question Explanation Text is a required field.'
          );
          isFormValid = false;
          break;
        }
      }
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  render() {
    const {
      formData,
      showSafeButton,
      tableColumns,
      showValidationErrors,
      validationErrors,
    } = this.state;

    return (
      <div className="content-question" style={styles}>
        <QuestionHeader
          code={formData.code}
          showCompetency={true}
          position={this.props.position}
          competency={formData.competency}
          showQuestionSubTypeSwitcher={true}
          questionSubType={formData.questionSubType}
          handleFieldUpdate={this.handleFieldUpdate}
          removeQuestion={this.props.removeQuestion}
          questionsCount={this.props.questionsCount}
          updatePositionMap={this.props.updatePositionMap}
          positionSwitchMap={this.props.positionSwitchMap}
          questionType={get(this.props.currentQuestionData, 'questionType', '')}
          markedForDeletion={get(
            this.props.currentQuestionData,
            'markedForDeletion',
            ''
          )}
        />

        <div className="content-question-body">
          {showValidationErrors && (
            <div className="error-wrapper">
              <h6>Error!</h6>

              {validationErrors.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          )}

          <QuestionData
            questionData={formData.questionData}
            handleFieldUpdate={this.handleFieldUpdate}
            handleAddQuestionData={this.handleAddQuestionData}
            handleRemoveQuestionData={this.handleRemoveQuestionData}
          />

          <div className="input-wrapper">
            <select
              className="input-select"
              onChange={(event) =>
                this.handleStateUpdate('tableColumns', event.target.value)
              }
              value={tableColumns}
            >
              <option value="0" disabled>
                Select Number of Columns
              </option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          <div className="question-data">
            <div className="question-replicable">
              <div className="tabular-format">
                {formData.answerData.texts.length !== 0 &&
                  formData.answerData.texts.map((rows, rowIndex) => {
                    return (
                      <div
                        key={rowIndex}
                        className="tabular-format-row d-flex align-items-center justify-content-start"
                      >
                        {rows.length !== 0 &&
                          rows.map((cell, cellIndex) => {
                            return (
                              <div
                                className="tabular-format-cell d-flex align-items-center justify-content-start"
                                key={cellIndex}
                              >
                                <div className="input-wrapper">
                                  <input
                                    type="text"
                                    className="input-field"
                                    placeholder="Cell text"
                                    value={get(cell, 'text', '')}
                                    onChange={(event) =>
                                      this.handleFieldUpdate(
                                        `answerData.texts[${rowIndex}][${cellIndex}].text`,
                                        event.target.value
                                      )
                                    }
                                  />
                                </div>

                                <div className="input-wrapper">
                                  <select
                                    className="input-select"
                                    onChange={(event) =>
                                      this.handleFieldUpdate(
                                        `answerData.texts[${rowIndex}][${cellIndex}].type`,
                                        event.target.value
                                      )
                                    }
                                    value={get(cell, 'type', '')}
                                  >
                                    <option value="">Type</option>
                                    {(rowIndex === 0 || cellIndex === 0) && (
                                      <option value="title">TH</option>
                                    )}
                                    <option value="ques">Q</option>
                                    <option value="ans">A</option>
                                  </select>
                                </div>
                              </div>
                            );
                          })}

                        <button
                          type="button"
                          className="button button-link button-remove-row"
                          onClick={() => this.handleRemoveTableRow(rowIndex)}
                          disabled={
                            get(formData, 'answerData.texts', []).length < 2
                          }
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    );
                  })}
              </div>

              {tableColumns !== '0' && (
                <div className="question-replicable-trigger">
                  <button
                    type="button"
                    className="button button-link"
                    onClick={() => this.handleAddTableRow()}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    {` Add One Row`}
                  </button>
                </div>
              )}
            </div>
          </div>

          <QuestionHint
            handleAddHint={this.handleAddHint}
            questionHint={formData.questionHint}
            handleRemoveHint={this.handleRemoveHint}
            handleFieldUpdate={this.handleFieldUpdate}
          />

          <div className="question-data">
            <div className="question-replicable">
              {formData.questionSuggestions.length !== 0 &&
                formData.questionSuggestions.map((suggestion, index) => {
                  return (
                    <div
                      key={index}
                      className="question-replicable-item d-flex align-items-center justify-content-between"
                    >
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="input-field"
                          placeholder={`Answer Suggestion ${index + 1}`}
                          value={suggestion}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              `questionSuggestions[${index}]`,
                              event.target.value
                            )
                          }
                        />
                      </div>

                      <button
                        type="button"
                        className="button button-link"
                        onClick={() => this.handleRemoveSuggestion(index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  );
                })}

              <div className="question-replicable-trigger">
                <button
                  type="button"
                  className="button button-link"
                  onClick={() => this.handleAddSuggestion()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {` Add Answer Suggestion`}
                </button>
              </div>
            </div>
          </div>

          <QuestionExplanation
            explanation={formData.explanation}
            handleFieldUpdate={this.handleFieldUpdate}
            handleAddExplanationText={this.handleAddExplanationText}
            handleRemoveExplanationText={this.handleRemoveExplanationText}
          />

          {showSafeButton && (
            <div className="input-wrapper">
              <button
                type="button"
                className="button button-secondary"
                onClick={() => this.handleUpdateQuestion()}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
