import { isEmpty } from "lodash";

export const switchQuestionPositions = (questions, positionSwitchMap) => {
  let updatedQuestions = [...questions];
  if (positionSwitchMap && !isEmpty(positionSwitchMap)) {
    const positionKeys = Object.keys(positionSwitchMap);
    for (let pK of positionKeys) {
      let oldIndex = null;
      console.log(updatedQuestions)
      for (let qIndex = 0; qIndex < updatedQuestions.length; qIndex++) {
        if (updatedQuestions[qIndex] && updatedQuestions[qIndex].code === pK) {
          oldIndex = qIndex;
          break;
        }
      }

      console.log(oldIndex)
      if (oldIndex === null) {
        continue;
      }
      
      const newIndex = parseInt(positionSwitchMap[pK]) - 1;
      console.log(newIndex)

      if (oldIndex === newIndex) {
        continue;
      }

      if (newIndex >= updatedQuestions.length) {
        let tempIndex = newIndex - updatedQuestions.length + 1;
        while (tempIndex--) {
          updatedQuestions.push(undefined);
        }
      }

      updatedQuestions.splice(
        newIndex,
        0,
        updatedQuestions.splice(oldIndex, 1)[0]
      );
    }
  }

  return updatedQuestions;
};
