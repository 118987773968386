import { ReduxConstants } from '../common/Environment';

export const loginAction = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.LOGIN,
    payload: dataPayload,
  });
};

export const logoutAction = () => (dispatch) => {
  dispatch({
    type: ReduxConstants.LOGOUT,
    payload: {},
  });
};

export const updatePermissions = (dataPayload) => (dispatch) => {
  dispatch({
    type: ReduxConstants.UPDATEPERMISSIONS,
    payload: dataPayload,
  });
};
