import axiosInstance from "./CreateService";
import { ApiConstants } from "../common/Environment";

export default class AgentService {
  getAgents() {
    return axiosInstance.get(ApiConstants.GETAGENTS);
  }

  getAgentByID(payload) {
    return axiosInstance.get(
      ApiConstants.GETAGENTBYID.replace(":salesAgentID", payload.salesAgentID)
    );
  }

  verifyAgent(payload) {
    return axiosInstance.put(ApiConstants.VERIFYAGENT, payload);
  }

  retryVerification(payload) {
    return axiosInstance.post(ApiConstants.RETRYAGENTVERIFICATION, payload);
  }
}
