import React from 'react';

import get from 'lodash/get';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import CouponService from '../../services/couponService';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import SideNavigation from '../../components/SideNavigation';
import DatePicker from 'react-datepicker';
import PageLoader from '../../components/PageLoader';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './EditCouponPage.scss';

class EditCouponPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      formData: {
        coupon: '',
        isAgent: false,
        isPublic: 'true',
        userID: '',
        minOrderAmountINR: 0,
        minOrderAmountUSD: 0,
        maxDiscount: 0,
        category: 'Package',
        expiry: null,
        isAllApplicable: 'false',
        isDiscountTypeFlat: 'false'
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.couponService = new CouponService();
  }

  componentDidMount() {
    this.getCouponByID();
  }

  getCouponByID = () => {
    this.setState({ loader: true });

    const dataPayload = {
      couponID: this.props.match.params.id,
    };

    this.couponService
      .getCouponByID(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData.data) {
          this.setState((state) => ({
            ...state,
            formData: {
              ...state.formData,
              coupon: get(responseData.data, 'coupon', ''),
              userID: get(responseData.data, 'userID', '') || '',
              isAgent: get(responseData.data, 'isAgent', false),
              maxDiscount: get(responseData.data, 'maxDiscount', 0) || 0,
              isPublic: responseData.data.isPublic === true ? 'true' : 'false',
              minOrderAmountINR: get(responseData.data, 'minOrderAmountINR', 0),
              minOrderAmountUSD: get(responseData.data, 'minOrderAmountUSD', 0),
              isAllApplicable: responseData.data.isAllApplicable === true ? 'true' : 'false',
              expiry: responseData.data.expiry ? new Date(responseData.data.expiry) : null,
              isDiscountTypeFlat: responseData.data.isDiscountTypeFlat === true ? 'true' : 'false',
            },
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH DATA', errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.coupon === '') {
      errorMessagesArr.push('Coupon is a required field.');
      isFormValid = false;
    }

    if (!formData.isAgent && formData.isPublic === 'false' && formData.userID === '') {
      errorMessagesArr.push('User ID is a required field.');
      isFormValid = false;
    }

    if (!formData.isAgent && formData.minOrderAmountINR === 0) {
      errorMessagesArr.push('Min Order INR Amount is a required.');
      isFormValid = false;
    }

    if (!formData.isAgent && formData.minOrderAmountUSD === 0) {
      errorMessagesArr.push('Min Order USD Amount is a required.');
      isFormValid = false;
    }

    if (!formData.isAgent && formData.maxDiscount === '') {
      errorMessagesArr.push('Max Discount is a required field.');
      isFormValid = false;
    }

    if (!formData.isAgent && !formData.expiry) {
      errorMessagesArr.push('Expiry is a required field.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      const dataPayload = {
        ...formData,
        couponID: this.props.match.params.id,
        coupon: formData.coupon.toUpperCase(),
        maxDiscount: parseInt(formData.maxDiscount) || 0,
        isPublic: formData.isPublic === 'true' ? true : false,
        minOrderAmountINR: parseInt(formData.minOrderAmountINR),
        minOrderAmountUSD: parseInt(formData.minOrderAmountUSD),
        isAllApplicable: formData.isAllApplicable === 'true' ? true : false,
        isDiscountTypeFlat: formData.isDiscountTypeFlat === 'true' ? true : false
      };

      this.couponService
        .editCoupon(dataPayload)
        .then((responseData) => {
          console.log('SUCCESSFULLY EDITED COUPON', responseData);

          if (responseData) {
            this.props.history.push(NavigationPaths.COUPONPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          console.log('UNABLE TO EDIT COUPON', errorData);
        });
    }
  };

  render() {
    const {
      loader,
      formData,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Coupon</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editcoupon-container">
          <div className="editcoupon-container-wrapper">
            <h1 className="editcoupon-container-title">Edit Coupon</h1>

            <div className="editcoupon-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  Coupon
                </label>
                <input
                  type="text"
                  className="input-field"
                  placeholder="Coupon"
                  value={formData.coupon}
                  style={{ textTransform: 'uppercase' }}
                  onChange={(event) => this.handleFieldUpdate('coupon', event.target.value)}
                />
              </div>

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  Is this coupon applicable to all the users?
                </label>
                <select
                  disabled={formData.isAgent}
                  className="input-select"
                  onChange={(event) => this.handleFieldUpdate('isPublic', event.target.value)}
                  value={formData.isPublic}
                >
                  <option value={'true'}>Yes</option>
                  <option value={'false'}>No</option>
                </select>
              </div>

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  User ID
                </label>
                <input
                  type="text"
                  className="input-field"
                  placeholder="User ID"
                  disabled={formData.isPublic === 'true'}
                  value={formData.userID}
                  onChange={(event) => this.handleFieldUpdate('userID', event.target.value)}
                />
              </div>

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  Max Discount Percentage/Value
                </label>
                <input
                  type="number"
                  className="input-field"
                  placeholder="Max Discount Percentage/Value"
                  value={formData.maxDiscount}
                  onChange={(event) => this.handleFieldUpdate('maxDiscount', event.target.value)}
                />
              </div>

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  Minimum Order Amount in INR
                </label>
                <input
                  type="number"
                  className="input-field"
                  disabled={formData.isAgent}
                  placeholder="Minimum Order Amount in INR"
                  value={formData.minOrderAmountINR}
                  onChange={(event) =>
                    this.handleFieldUpdate('minOrderAmountINR', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  Minimum Order Amount in USD
                </label>
                <input
                  type="number"
                  disabled={formData.isAgent}
                  className="input-field"
                  placeholder="Minimum Order Amount in USD"
                  value={formData.minOrderAmountUSD}
                  onChange={(event) =>
                    this.handleFieldUpdate('minOrderAmountUSD', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  Expiry Date
                </label>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  minDate={new Date()}
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  className="input-field"
                  value={formData.expiry}
                  selected={formData.expiry}
                  disabled={formData.isAgent}
                  placeholderText="Expiry Date"
                  onChange={(date) => this.handleFieldUpdate('expiry', date)}
                />
              </div>

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  Select a discount type for this coupon
                </label>
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('isDiscountTypeFlat', event.target.value)
                  }
                  disabled={formData.isAgent}
                  value={formData.isDiscountTypeFlat}
                >
                  <option value={'true'}>Flat Discount</option>
                  <option value={'false'}>Percentage Discount</option>
                </select>
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Edit Coupon
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withAuthentication(EditCouponPage));
