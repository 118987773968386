import React from 'react';

import { Helmet } from 'react-helmet';

import Header from '../../components/Header';
import SideNavigation from '../../components/SideNavigation';

import styles from './UnauthorizedPage.scss';

class UnauthorizedPage extends React.Component {
  render() {
    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Unauthorized Page</title>
        </Helmet>

        <Header hideLogout={true} />

        <SideNavigation />

        <div className="unauthorized-container">
          <div className="unauthorized-container-wrapper">
            <div className="unauthorized-container-header d-flex align-items-center justify-content-between">
              <h1 className="unauthorized-container-title">
                Sorry, you are not authorized to view this page.
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UnauthorizedPage;
