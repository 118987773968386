import React from "react";
import { set, get } from "lodash";
import { QuestionTypes, QuestionSubTypePayload } from "../../common/Constants";
import QuestionHeader from "../QuestionHeader";

class EMPTYSubType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: Object.assign({}, QuestionSubTypePayload.EMPTY),
    };
  }

  componentDidMount() {
    this.prefillQuestionData();
  }

  prefillQuestionData = () => {
    const { currentQuestionData } = this.props;

    if (currentQuestionData) {
      this.setState((state) => ({
        ...state,
        formData: currentQuestionData,
      }));
    }
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    set(formData, fieldName, fieldValue);

    if (
      fieldName === "selectedQuestionType" &&
      fieldValue === QuestionTypes.DSC
    ) {
      set(formData, "selectedQuestionSubType", fieldValue);
    }

    this.setState((state) => ({
      ...state,
      formData,
      showSafeButton: true,
    }));
  };

  handleUpdateQuestion = () => {
    const { formData } = this.state;

    if (formData.selectedQuestionSubType && formData.selectedQuestionType) {
      this.props.updateQuestion(formData, this.props.position);
    }
  };

  render() {
    const { formData } = this.state;

    return (
      <div className="content-question">
        <QuestionHeader
          showCompetency={false}
          position={this.props.position}
          competency={formData.competency}
          showQuestionSubTypeSwitcher={false}
          questionSubType={formData.questionSubType}
          handleFieldUpdate={this.handleFieldUpdate}
          removeQuestion={this.props.removeQuestion}
          questionsCount={this.props.questionsCount}
          updatePositionMap={this.props.updatePositionMap}
          positionSwitchMap={this.props.positionSwitchMap}
          code={get(this.props.currentQuestionData, "code", "")}
          questionType={get(this.props.currentQuestionData, "questionType", "")}
          markedForDeletion={get(
            this.props.currentQuestionData,
            "markedForDeletion",
            ""
          )}
        />

        <div className="content-question-body">
          <h5>Change the Question Type</h5>
          <div className="input-wrapper">
            <select
              className="input-select"
              onChange={(event) =>
                this.handleFieldUpdate(
                  "selectedQuestionType",
                  event.target.value
                )
              }
              value={formData.selectedQuestionType}
            >
              <option value={""} disabled>
                Select a Question Type
              </option>
              <option value="T">T - Text Only</option>
              <option value="TWI">TWI - Text With Image</option>
              <option value="TWV">TWV - Text With Video</option>
              <option value="TWA">TWA - Text With Audio</option>
              <option value="DSC">DSC - Description Type</option>
            </select>
          </div>

          <div className="input-wrapper">
            <select
              className="input-select"
              onChange={(event) =>
                this.handleFieldUpdate(
                  "selectedQuestionSubType",
                  event.target.value
                )
              }
              value={formData.selectedQuestionSubType}
              disabled={formData.selectedQuestionType === QuestionTypes.DSC}
            >
              <option value={""} disabled>
                Select a Question Sub Type
              </option>
              <option value="SAQ">SAQ - Single Answer Question</option>
              <option value="MCQ">MCQ - Multiple Choice Question</option>
              <option value="MCQM">
                MCQM - Multiple Choice Question With Multiple Answers
              </option>
              <option value="LQ">LQ - Labelling Question</option>
              <option value="MTF">MTF - Match The Following</option>
              <option value="FITBT">
                FITBT - Fill In The Blanks - Typeable
              </option>
              <option value="FITBS">
                FITBS - Fill In The Blanks - Selectable
              </option>
              <option value="TBLT">TBLT - Table Question - Typeable</option>
              <option value="TBLS">TBLS - Table Question - Selectable</option>
              <option value="DSC">DSC - Description Type</option>
            </select>
          </div>

          {formData.selectedQuestionSubType && formData.selectedQuestionType && (
            <button
              type="button"
              className="button button-secondary"
              onClick={this.handleUpdateQuestion}
            >
              Add
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default EMPTYSubType;
