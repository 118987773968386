import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class QuestionHint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      questionHintType: '',
    };
  }

  render() {
    const {
      questionHint,
      handleAddHint,
      handleRemoveHint,
      handleFieldUpdate,
    } = this.props;

    return (
      <div className="question-data">
        <label>Question Hints</label>
        <div className="question-replicable">
          {questionHint &&
            questionHint.length !== 0 &&
            questionHint.map((hint, index) => {
              return (
                <div
                  key={index}
                  className="question-replicable-item d-flex align-items-center justify-content-between"
                >
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="input-field"
                      placeholder={
                        hint.type === 'image'
                          ? 'Question Hint Image URL'
                          : 'Question Hint Text'
                      }
                      value={hint.value}
                      onChange={(event) =>
                        handleFieldUpdate(
                          `questionHint[${index}].value`,
                          event.target.value
                        )
                      }
                    />
                  </div>

                  <button
                    type="button"
                    className="button button-link"
                    onClick={() => handleRemoveHint(index)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              );
            })}

          <div className="input-wrapper">
            <select
              className="input-select"
              onChange={(event) => handleAddHint(event.target.value)}
              value={this.state.questionHintType}
            >
              <option value="" disabled>
                Choose a Hint Type
              </option>
              <option value="image">Image</option>
              <option value="text">Text</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionHint;
