import React from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { NavigationPaths } from '../../common/Environment';

export default (ChildComponent) => {
  class withLogin extends React.Component {
    render() {
      const isLoggedIn = this.props.admin && this.props.admin.accessToken;

      if (isLoggedIn) {
        return <Redirect to={NavigationPaths.STATSPAGE} />;
      }

      return <ChildComponent />;
    }
  }

  const mapStateToProps = (state) => ({
    admin: state.admin.adminDetails,
  });

  const mapDispatchToProps = {};

  return connect(mapStateToProps, mapDispatchToProps)(withLogin);
};
