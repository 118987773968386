import React from "react";

import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { tableDataParser } from "./dataParser";
import { NavigationPaths } from "../../common/Environment";

import Table from "../../components/Table";
import Header from "../../components/Header";
import OrderService from "../../services/orderService";
import SideNavigation from "../../components/SideNavigation";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";

import styles from "./EcomOrdersPage.scss";

class EcomOrdersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: [
        "S. No.",
        "Database ID",
        "Order ID",
        "Amount",
        "Customer Name",
        "Products",
        "Status",
        "Created On",
      ],
      tableContent: [],
    };

    this.orderService = new OrderService();
  }

  componentDidMount() {
    this.getEcomOrders();
  }

  getEcomOrders = () => {
    this.setState({ loader: true });

    this.orderService
      .getEcomOrders()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        console.log("ERROR: Unable to Fetch Orders", errorData);
      });
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - E-commerce Orders</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="ecomOrders-container">
          <div className="ecomOrders-container-wrapper">
            <div className="ecomOrders-container-header d-flex align-items-center justify-content-between">
              <h1 className="ecomOrders-container-title">E-commerce Orders</h1>
            </div>

            <Table
              hasActions={true}
              hasDisable={false}
              loaderState={loader}
              headerData={tableHeaders}
              tableData={tableContent}
              editPath={NavigationPaths.EDITECOMORDERSPAGE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EcomOrdersPage));
