import React from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import PageLoader from '../../components/PageLoader';
import OrderService from '../../services/orderService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import DeleteConfirmation from '../../components/DeleteConfirmation';

import styles from './EditPayoutPage.scss';

class EditPayoutPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      payoutData: null,
      showServerErrors: '',
      showConfirmation: false,
    };

    this.orderService = new OrderService();
  }

  componentDidMount() {
    this.getPayoutByID();
  }

  getPayoutByID = () => {
    this.setState({ loader: true });

    this.orderService
      .getPayoutByID({ payoutID: this.props.match.params.id })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            payoutData: get(responseData, 'data', null),
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  toggleRequestPayout = () => {
    this.setState((state) => ({
      ...state,
      showConfirmation: !state.showConfirmation,
    }));
  };

  requestPayout = () => {
    this.setState({ loader: true });

    this.orderService
      .requestPayout({ payoutID: this.props.match.params.id })
      .then((responseData) => {
        this.setState({ loader: false });

        this.props.history.push(NavigationPaths.PAYOUTPAGE);
      })
      .catch((errorData) => {
        this.setState({
          loader: false,
          showServerErrors: errorData.message,
        });

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
  };

  render() {
    const {
      loader,
      payoutData,
      showConfirmation,
      showServerErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Payout Details</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editpayout-container">
          <div className="editpayout-container-wrapper">
            <h1 className="editpayout-container-title">Payout Details</h1>

            <div className="editpayout-container-content">
              {showServerErrors !== '' && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="editpayout-container-details">
                <h1>Basic Details</h1>

                <p>
                  <b>Transfer ID: </b>
                  {get(payoutData, 'transferId', 'N/A')}
                </p>

                <p>
                  <b>Reference ID: </b>
                  {get(payoutData, 'referenceId', 'N/A')}
                </p>

                <p>
                  <b>Amount: </b>
                  {get(payoutData, 'amount', 'N/A')}
                </p>

                <p>
                  <b>Status: </b>
                  {get(payoutData, 'status', 'N/A')}
                </p>

                <p>
                  <b>Message: </b>
                  {get(payoutData, 'message', 'N/A')}
                </p>

                {payoutData && payoutData.userPackageID && (
                  <Link
                    to={NavigationPaths.EDITPACKAGEORDERSPAGE.replace(
                      ':id',
                      payoutData.userPackageID
                    )}
                  >
                    View Order Details
                  </Link>
                )}
              </div>

              <div className="editpayout-container-details">
                <h1>Agent Details</h1>
                <p>
                  <b>Name: </b>
                  {get(payoutData, 'salesAgent.name', 'N/A')}
                </p>

                <p>
                  <b>Number: </b>
                  {get(payoutData, 'salesAgent.mobileNumber', 'N/A')}
                </p>

                <p>
                  <b>Email: </b>
                  {get(payoutData, 'salesAgent.email', 'N/A')}
                </p>
              </div>

              <div className="editpayout-container-details">
                <h1>Transfer Details</h1>

                <p>
                  <b>Message: </b>
                  {get(payoutData, 'transferStatus.message', 'N/A')}
                </p>

                <p>
                  <b>Status: </b>
                  {get(
                    payoutData,
                    'transferStatus.data.transfer.status',
                    'N/A'
                  )}
                </p>

                <p>
                  <b>UTR: </b>
                  {get(payoutData, 'transferStatus.data.transfer.utr', 'N/A')}
                </p>

                <p>
                  <b>Bank Account: </b>
                  {get(
                    payoutData,
                    'transferStatus.data.transfer.bankAccount',
                    'N/A'
                  )}
                </p>

                <p>
                  <b>IFSC: </b>
                  {get(payoutData, 'transferStatus.data.transfer.ifsc', 'N/A')}
                </p>

                <p>
                  <b>Beneficiary ID: </b>
                  {get(
                    payoutData,
                    'transferStatus.data.transfer.beneId',
                    'N/A'
                  )}
                </p>

                <p>
                  <b>Processed ON: </b>
                  {get(
                    payoutData,
                    'transferStatus.data.transfer.processedOn',
                    'N/A'
                  )}
                </p>

                <p>
                  <b>Reason: </b>
                  {get(
                    payoutData,
                    'transferStatus.data.transfer.reason',
                    'N/A'
                  )}
                </p>
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={this.toggleRequestPayout}
                >
                  Request Payout Again
                </button>
              </div>
            </div>
          </div>
        </div>

        {showConfirmation && (
          <DeleteConfirmation
            actionHandler={this.requestPayout}
            resetHandler={this.toggleRequestPayout}
          />
        )}

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditPayoutPage));
