import axiosInstance from "./CreateService";
import { ApiConstants } from "../common/Environment";

export default class PackageService {
  getAllPackages() {
    return axiosInstance.get(ApiConstants.GETPACKAGES);
  }

  getPackagesByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETPACKAGESBYID.replace(":packageID", dataPayload.packageID)
    );
  }

  addPackage(dataPayload) {
    return axiosInstance.post(ApiConstants.ADDPACKAGES, dataPayload);
  }

  editPackage(dataPayload) {
    return axiosInstance.put(ApiConstants.EDITPACKAGES, dataPayload);
  }

  togglePackage(dataPayload) {
    return axiosInstance.put(ApiConstants.TOGGLEPACKAGES, dataPayload);
  }

  getDurations() {
    return axiosInstance.get(ApiConstants.GETDURATIONS);
  }

  getDurationsByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETDURATIONSBYID.replace(":durationID", dataPayload.durationID)
    );
  }

  addDuration(dataPayload) {
    return axiosInstance.post(ApiConstants.ADDDURATIONS, dataPayload);
  }

  editDuration(dataPayload) {
    return axiosInstance.put(ApiConstants.EDITDURATIONS, dataPayload);
  }

  toggleDuration(dataPayload) {
    return axiosInstance.put(ApiConstants.TOGGLEDURATIONS, dataPayload);
  }
}
