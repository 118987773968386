import React from 'react';
import SAQSubType from '../SAQSubType';
import MCQSubType from '../MCQSubType';
import MTFSubType from '../MTFSubType';
import DSCSubType from '../DSCSubType';
import LQSubType from '../LQSubType';
import FITBTSubType from '../FITBTSubType';
import FITBSSubType from '../FITBSSubType';
import MCQMSubType from '../MCQMSubType';
import TBLTSubType from '../TBLTSubType';
import TBLSSubType from '../TBLSSubType';
import EMPTYSubType from '../EMPTYSubType';
import LAQSubType from '../LAQSubType';
import LAQESubType from '../LAQESubType';
import LAQWLSubType from '../LAQWLSubType';
import LAQAISubType from '../LAQAISubType';
import RQTSubType from '../RQTSubType';
import { QuestionSubTypes } from '../../common/Constants';

export default class QuestionPods extends React.Component {
  render() {
    const { questions, positionSwitchMap } = this.props;
    return (
      <div className="content-context-wrapper">
        {questions.map((question, index) => {
          switch (question.questionSubType) {
            case QuestionSubTypes.SAQ:
              return (
                <SAQSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.MCQ:
              return (
                <MCQSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.MCQM:
              return (
                <MCQMSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.MTF:
              return (
                <MTFSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.LQ:
              return (
                <LQSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.FITBT:
              return (
                <FITBTSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.FITBS:
              return (
                <FITBSSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.TBLT:
              return (
                <TBLTSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.TBLS:
              return (
                <TBLSSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.LAQ:
              return (
                <LAQSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.LAQE:
              return (
                <LAQESubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.LAQWL:
              return (
                <LAQWLSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.LAQAI:
              return (
                <LAQAISubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.RQT:
              return (
                <RQTSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.DSC:
              return (
                <DSCSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            case QuestionSubTypes.EMPTY:
              return (
                <EMPTYSubType
                  key={index}
                  position={index}
                  questionsCount={questions.length}
                  positionSwitchMap={positionSwitchMap}
                  currentQuestionData={questions[index]}
                  removeQuestion={this.props.removeQuestion}
                  updateQuestion={this.props.updateEmptyQuestion}
                  updatePositionMap={this.props.updatePositionMap}
                />
              );

            default:
              return <React.Fragment key={index}></React.Fragment>;
          }
        })}
      </div>
    );
  }
}
