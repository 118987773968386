import React from 'react';
import get from 'lodash/get';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import PackageService from '../../services/packageService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './EditPackagePage.scss';

class EditPackagePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      classesArr: [],
      subjectsArr: [],
      formData: {
        title: '',
        iconURL: '',
        permittedResources: '',
        INR: '',
        USD: '',
        packageID: '',
      },
      classTitle: '',
      subjectTitle: '',
      isAll: false,
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.packageService = new PackageService();
  }

  componentDidMount() {
    this.getPackageByID(this.props.match.params.id);
  }

  getPackageByID = (packageID) => {
    if (!packageID) {
      return;
    }

    const dataPayload = {
      packageID,
    };

    this.setState({ loader: true });
    this.packageService
      .getPackagesByID(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData.data) {
          this.populatePackageDetails(responseData.data);
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH Package Details', errorData);
      });
  };

  populatePackageDetails = (packageDetails) => {
    this.setState((state) => ({
      ...state,
      classTitle: get(packageDetails, 'classID.class', 'N/A'),
      subjectTitle: get(packageDetails, 'subjectID.subject', 'N/A'),
      isAll: packageDetails.isAll ? 'YES' : 'NO',
      formData: {
        ...state.formData,
        packageID: packageDetails._id,
        title: packageDetails.title,
        iconURL: packageDetails.iconURL,
        permittedResources: packageDetails.permittedResources,
        INR: get(packageDetails, 'prices.INR', ''),
        USD: get(packageDetails, 'prices.USD', ''),
      },
    }));
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.title === '') {
      errorMessagesArr.push('Title is a required field.');
      isFormValid = false;
    }

    if (formData.iconURL === '') {
      errorMessagesArr.push('Icon URL is a required field.');
      isFormValid = false;
    }

    if (formData.permittedResources === '') {
      errorMessagesArr.push('Permitted Resources is a required field.');
      isFormValid = false;
    }

    if (formData.INR === '') {
      errorMessagesArr.push('Price in INR is a required field.');
      isFormValid = false;
    }

    if (formData.USD === '') {
      errorMessagesArr.push('Price in USD is a required field.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form

      const { formData } = this.state;

      const dataPayload = {
        title: formData.title,
        iconURL: formData.iconURL,
        permittedResources: formData.permittedResources,
        prices: {
          INR: formData.INR,
          USD: formData.USD,
        },
        packageID: formData.packageID,
      };

      this.packageService
        .editPackage(dataPayload)
        .then((responseData) => {
          console.log('SUCCESSFULLY EDITED PACKAGE', responseData);

          if (responseData) {
            this.props.history.push(NavigationPaths.PACKAGEPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          console.log('UNABLE TO EDIT PACKAGE', errorData);
        });
    }
  };

  render() {
    const {
      formData,
      showServerErrors,
      validationErrors,
      showValidationErrors,
      classTitle,
      subjectTitle,
      isAll,
    } = this.state;

    return (
      <div className='page-container' style={styles}>
        <Helmet>
          <title>SKIP - Edit Package</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className='editpackage-container'>
          <div className='editpackage-container-wrapper'>
            <h1 className='editpackage-container-title'>Edit Package</h1>

            <div className='editpackage-container-content'>
              {showValidationErrors && (
                <div className='error-wrapper'>
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className='error-wrapper'>
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className='input-wrapper'>
                <label>All Subjects Package?</label>
                <input
                  type='text'
                  value={isAll}
                  disabled={true}
                  className='input-field'
                  placeholder='All Subjects Package'
                />
              </div>

              <div className='input-wrapper'>
                <label>Class</label>
                <input
                  type='text'
                  disabled={true}
                  value={classTitle}
                  className='input-field'
                  placeholder='Class'
                />
              </div>

              {isAll === 'NO' && (
                <div className='input-wrapper'>
                  <label>Subject</label>
                  <input
                    type='text'
                    disabled={true}
                    value={subjectTitle}
                    className='input-field'
                    placeholder='Subject'
                  />
                </div>
              )}

              <hr />

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Package Title'
                  value={formData.title}
                  onChange={(event) =>
                    this.handleFieldUpdate('title', event.target.value)
                  }
                />
              </div>

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Package Icon URL'
                  value={formData.iconURL}
                  onChange={(event) =>
                    this.handleFieldUpdate('iconURL', event.target.value)
                  }
                />
              </div>

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Permitted Resources'
                  value={formData.permittedResources}
                  onChange={(event) =>
                    this.handleFieldUpdate(
                      'permittedResources',
                      event.target.value
                    )
                  }
                />
              </div>

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Price in INR'
                  value={formData.INR}
                  onChange={(event) =>
                    this.handleFieldUpdate('INR', event.target.value)
                  }
                />
              </div>

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Price in USD'
                  value={formData.USD}
                  onChange={(event) =>
                    this.handleFieldUpdate('USD', event.target.value)
                  }
                />
              </div>

              <div className='input-wrapper'>
                <button
                  type='button'
                  className='button button-primary'
                  onClick={() => this.handleFormSubmit()}
                >
                  Save Updates
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditPackagePage));
