import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import SubjectService from '../../services/subjectService';
import PackageService from '../../services/packageService';
import ClassService from '../../services/classService';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import SideNavigation from '../../components/SideNavigation';

import styles from './AddPackagePage.scss';

class AddPackagePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      classesArr: [],
      subjectsArr: [],
      formData: {
        title: '',
        iconURL: '',
        permittedResources: '',
        INR: '',
        USD: '',
        subjectID: '',
        classID: '',
        isAll: 'false',
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.subjectService = new SubjectService();
    this.classService = new ClassService();
    this.packageService = new PackageService();
  }

  componentDidMount() {
    this.fetchAllClasses();
  }

  fetchAllClasses = () => {
    this.setState({ loader: true });

    this.classService
      .getAllClasses()
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            classesArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH CLASSES', errorData);
      });
  };

  getSubjectByClass = () => {
    this.setState({ loader: true });

    this.subjectService
      .getSubjectsByClass(this.state.formData)
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            subjectsArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH SUBJECTS', errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.classID === '') {
      errorMessagesArr.push('Class is a required field.');
      isFormValid = false;
    }

    if (formData.isAll === 'false' && formData.subjectID === '') {
      errorMessagesArr.push('Subject is a required field.');
      isFormValid = false;
    }

    if (formData.title === '') {
      errorMessagesArr.push('Title is a required field.');
      isFormValid = false;
    }

    if (formData.iconURL === '') {
      errorMessagesArr.push('Icon URL is a required field.');
      isFormValid = false;
    }

    if (formData.permittedResources === '') {
      errorMessagesArr.push('Permitted Resources is a required field.');
      isFormValid = false;
    }

    if (formData.INR === '') {
      errorMessagesArr.push('Price in INR is a required field.');
      isFormValid = false;
    }

    if (formData.USD === '') {
      errorMessagesArr.push('Price in USD is a required field.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState(
      (state) => ({
        ...state,
        formData: {
          ...state.formData,
          [`${fieldName}`]: fieldValue,
        },
      }),
      () => {
        if (
          (fieldName === 'classID' && this.state.formData.isAll === 'false') ||
          (fieldName === 'isAll' &&
            fieldValue === 'true' &&
            this.state.formData.classID)
        ) {
          this.setState((state) => ({
            ...state,
            formData: { ...state.formData, subjectID: '' },
          }));
          this.getSubjectByClass();
        }
      }
    );
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form

      const { formData } = this.state;

      const dataPayload = {
        classID: formData.classID,
        subjectID: formData.subjectID,
        title: formData.title,
        iconURL: formData.iconURL,
        permittedResources: formData.permittedResources,
        isAll: formData.isAll === 'true' ? true : false,
        prices: {
          INR: formData.INR,
          USD: formData.USD,
        },
      };

      this.packageService
        .addPackage(dataPayload)
        .then((responseData) => {
          console.log('SUCCESSFULLY ADDED NEW PACKAGE', responseData);

          if (responseData) {
            this.props.history.push(NavigationPaths.PACKAGEPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          console.log('UNABLE TO ADD NEW PACKAGE', errorData);
        });
    }
  };

  render() {
    const {
      formData,
      classesArr,
      showServerErrors,
      validationErrors,
      showValidationErrors,
      subjectsArr,
    } = this.state;

    return (
      <div className='page-container' style={styles}>
        <Helmet>
          <title>SKIP - Add New Package</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className='addpackage-container'>
          <div className='addpackage-container-wrapper'>
            <h1 className='addpackage-container-title'>Add New Package</h1>

            <div className='addpackage-container-content'>
              {showValidationErrors && (
                <div className='error-wrapper'>
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className='error-wrapper'>
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className='input-wrapper'>
                <label className='input-wrapper-label'>
                  All Subjects Package?
                </label>
                <select
                  className='input-select'
                  onChange={(event) =>
                    this.handleFieldUpdate('isAll', event.target.value)
                  }
                  value={formData.isAll}
                >
                  <option value={'true'}>Yes</option>
                  <option value={'false'}>No</option>
                </select>
              </div>

              <hr />

              <div className='input-wrapper'>
                <select
                  className='input-select'
                  onChange={(event) =>
                    this.handleFieldUpdate('classID', event.target.value)
                  }
                  value={formData.classID}
                >
                  <option value=''>Choose a Class</option>

                  {classesArr.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.class}
                      </option>
                    );
                  })}
                </select>
              </div>

              {formData.isAll === 'false' && (
                <div className='input-wrapper'>
                  <select
                    className='input-select'
                    onChange={(event) =>
                      this.handleFieldUpdate('subjectID', event.target.value)
                    }
                    value={formData.subjectID}
                    disabled={!formData.classID ? true : false}
                  >
                    <option value='' disabled>
                      Choose a Subject
                    </option>

                    {formData.classID &&
                      subjectsArr.map((item, index) => {
                        return (
                          <option key={index} value={item._id}>
                            {item.subject}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Package Name'
                  value={formData.title}
                  onChange={(event) =>
                    this.handleFieldUpdate('title', event.target.value)
                  }
                />
              </div>

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Package Icon URL'
                  value={formData.iconURL}
                  onChange={(event) =>
                    this.handleFieldUpdate('iconURL', event.target.value)
                  }
                />
              </div>

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Number of Permitted Resources'
                  value={formData.permittedResources}
                  onChange={(event) =>
                    this.handleFieldUpdate(
                      'permittedResources',
                      event.target.value
                    )
                  }
                />
              </div>

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Price in INR'
                  value={formData.INR}
                  onChange={(event) =>
                    this.handleFieldUpdate('INR', event.target.value)
                  }
                />
              </div>

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Price in USD'
                  value={formData.USD}
                  onChange={(event) =>
                    this.handleFieldUpdate('USD', event.target.value)
                  }
                />
              </div>

              <div className='input-wrapper'>
                <button
                  type='button'
                  className='button button-primary'
                  onClick={() => this.handleFormSubmit()}
                >
                  Add New Package
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AddPackagePage));
