import React from 'react';

import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

import get from 'lodash/get';
import set from 'lodash/set';
import Header from '../../components/Header';
import LmsService from '../../services/lmsService';
import PageLoader from '../../components/PageLoader';
import ClassService from '../../services/classService';
import SubjectService from '../../services/subjectService';
import ImageUploader from '../../components/ImageUploader';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './EditVideoPage.scss';

class EditVideoPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      classesArr: [],
      subjectsArr: [],
      chaptersArr: [],
      formData: {
        url: '',
        title: '',
        classID: '',
        subjectID: '',
        chapterID: '',
        description: [],
        source: 'Direct',
        mediaDirectory: uuid(),
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.lmsService = new LmsService();
    this.classService = new ClassService();
    this.subjectService = new SubjectService();
  }

  componentDidMount() {
    this.getVideosByID();
  }

  getVideosByID = () => {
    this.setState({ loader: true });
    this.lmsService
      .getVideosByID({ videoID: this.props.match.params.id })
      .then((responseData) => {
        if (responseData) {
          this.setState(
            (state) => ({
              ...state,
              loader: false,
              formData: {
                url: get(responseData, 'data.url') || '',
                title: get(responseData, 'data.title') || '',
                classID: get(responseData, 'data.classID') || '',
                description: get(responseData, 'data.description') || [],
                subjectID: get(responseData, 'data.subjectID') || '',
                chapterID: get(responseData, 'data.chapterID') || '',
                source: get(responseData, 'data.source') || 'Direct',
                mediaDirectory:
                  get(responseData, 'data.mediaDirectory') || uuid(),
              },
            }),
            () => {
              this.fetchAllClasses();
              this.getSubjectByClass();
              this.getChaptersBySubject();
            }
          );
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  fetchAllClasses = () => {
    this.setState({ loader: true });

    this.classService
      .getAllClasses()
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            classesArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  getSubjectByClass = () => {
    const { classID } = this.state.formData;

    if (!classID) {
      return;
    }

    this.setState({ loader: true });

    this.subjectService
      .getSubjectsByClass({ classID })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            subjectsArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  getChaptersBySubject = () => {
    const { subjectID } = this.state.formData;

    if (!subjectID) {
      return;
    }

    this.setState({ loader: true });

    this.subjectService
      .getChapters({ subjectID })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            chaptersArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.classID === '') {
      errorMessagesArr.push('Class is a required field.');
      isFormValid = false;
    }

    if (formData.subjectID === '') {
      errorMessagesArr.push('Subject is a required field.');
      isFormValid = false;
    }

    if (formData.chapterID === '') {
      errorMessagesArr.push('Selection of Lesson is required.');
      isFormValid = false;
    }

    if (formData.title === '') {
      errorMessagesArr.push('Title is a required field.');
      isFormValid = false;
    }

    if (formData.url === '') {
      errorMessagesArr.push('Video URL/ID is invalid.');
      isFormValid = false;
    }

    if (formData.description.length) {
      for (let desc of formData.description) {
        if (!desc) {
          errorMessagesArr.push('Description is required.');
          isFormValid = false;
          break;
        }
      }
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    set(formData, fieldName, fieldValue);

    this.setState(
      (state) => ({
        ...state,
        formData,
      }),
      () => {
        if (fieldName === 'classID') {
          this.setState((state) => ({
            ...state,
            formData: { ...state.formData, subjectID: '' },
          }));
          this.getSubjectByClass();
        } else if (fieldName === 'subjectID') {
          this.setState((state) => ({
            ...state,
            formData: { ...state.formData, chapterID: '' },
          }));
          this.getChaptersBySubject();
        }
      }
    );
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      const dataPayload = {
        ...formData,
        videoID: this.props.match.params.id,
      };

      this.lmsService
        .editVideos(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });

          if (responseData) {
            this.props.history.push(NavigationPaths.VIDEOSPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  addDescriptionRow = () => {
    let description = Object.assign([], this.state.formData.description);
    description.push('');
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        description,
      },
    }));
  };

  removeDescriptionRow = (descriptionIndex) => {
    let description = Object.assign([], this.state.formData.description);
    description = description.filter(
      (item, itemIndex) => itemIndex !== descriptionIndex
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        description,
      },
    }));
  };

  render() {
    const {
      loader,
      formData,
      classesArr,
      subjectsArr,
      chaptersArr,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Video</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editvideo-container">
          <ImageUploader mediaDirectory={formData.mediaDirectory} />

          <div className="editvideo-container-wrapper">
            <h1 className="editvideo-container-title">Edit Video</h1>

            <div className="editvideo-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <label>Class</label>
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('classID', event.target.value)
                  }
                  value={formData.classID}
                >
                  <option value="">Choose a Class</option>

                  {classesArr.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.class}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="input-wrapper">
                <label>Subject</label>
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('subjectID', event.target.value)
                  }
                  value={formData.subjectID}
                  disabled={!formData.classID ? true : false}
                >
                  <option value="" disabled>
                    Choose a Subject
                  </option>

                  {formData.classID &&
                    subjectsArr.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.subject}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="input-wrapper">
                <label>Lesson</label>
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('chapterID', event.target.value)
                  }
                  value={formData.chapterID}
                  disabled={!formData.subjectID ? true : false}
                >
                  <option value="" disabled>
                    Choose a Lesson
                  </option>

                  {formData.subjectID &&
                    chaptersArr.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.chapter}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="input-wrapper">
                <label>Title</label>

                <input
                  type="text"
                  className="input-field"
                  placeholder="Title"
                  value={formData.title}
                  onChange={(event) =>
                    this.handleFieldUpdate('title', event.target.value)
                  }
                />
              </div>

              <div className="video-description">
                {formData.description.length !== 0 && (
                  <div className="description-data">
                    <div className="description-replicable">
                      {formData.description.map(
                        (description, descriptionIndex) => {
                          return (
                            <div
                              key={descriptionIndex}
                              className="description-replicable-item d-flex align-items-center justify-content-between"
                            >
                              <div className="input-wrapper">
                                <input
                                  type="text"
                                  className="input-field"
                                  placeholder={`Description ${
                                    descriptionIndex + 1
                                  }`}
                                  value={description}
                                  onChange={(event) =>
                                    this.handleFieldUpdate(
                                      `description[${descriptionIndex}]`,
                                      event.target.value
                                    )
                                  }
                                />
                              </div>

                              <button
                                type="button"
                                className="button button-link"
                                onClick={() =>
                                  this.removeDescriptionRow(descriptionIndex)
                                }
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

                <div className="description-replicable-trigger">
                  <button
                    type="button"
                    className="button button-link"
                    onClick={() => this.addDescriptionRow()}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Description
                  </button>
                </div>
              </div>

              <div className="input-wrapper">
                <label>Source</label>
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('source', event.target.value)
                  }
                  value={formData.source}
                >
                  <option value={'Direct'}>Direct</option>
                  <option value={'Youtube'}>Youtube</option>
                  <option value={'Vimeo'}>Vimeo</option>
                </select>
              </div>

              <div className="input-wrapper">
                <label>Video URL / ID</label>
                <input
                  type="text"
                  className="input-field"
                  placeholder="Video URL / ID"
                  value={formData.url}
                  onChange={(event) =>
                    this.handleFieldUpdate('url', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Edit Video
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditVideoPage));
