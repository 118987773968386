import axiosInstance from './CreateService';
import { ApiConstants } from '../common/Environment';

export default class OrderService {
  getEcomOrders() {
    return axiosInstance.get(ApiConstants.GETECOMORDERS);
  }

  getEcomOrderByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETECOMORDERBYID.replace(
        ':ecomOrderID',
        dataPayload.ecomOrderID
      )
    );
  }

  getPackageOrders() {
    return axiosInstance.get(ApiConstants.GETPACKAGEORDERS);
  }

  getPackageOrderByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETPACKAGEORDERBYID.replace(
        ':packageOrderID',
        dataPayload.packageOrderID
      )
    );
  }

  getProductOrders() {
    return axiosInstance.get(ApiConstants.GETPRODUCTORDERS);
  }

  getProductOrderByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETPRODUCTORDERBYID.replace(
        ':productOrderID',
        dataPayload.productOrderID
      )
    );
  }

  getPayouts() {
    return axiosInstance.get(ApiConstants.GETPAYOUTS);
  }

  getPayoutByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETPAYOUTSBYID.replace(':payoutID', dataPayload.payoutID)
    );
  }

  requestPayout(dataPayload) {
    return axiosInstance.post(ApiConstants.REQUESTPAYOUTS, dataPayload);
  }
}
