import axiosInstance from './CreateService';
import { ApiConstants } from '../common/Environment';

export default class CouponService {
  getCoupons() {
    return axiosInstance.get(ApiConstants.GETCOUPONS);
  }

  getCouponByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETCOUPONSBYID.replace(':couponID', dataPayload.couponID)
    );
  }

  addCoupon(dataPayload) {
    return axiosInstance.post(ApiConstants.ADDCOUPONS, dataPayload);
  }

  editCoupon(dataPayload) {
    return axiosInstance.put(ApiConstants.EDITCOUPONS, dataPayload);
  }

  toggleCoupon(dataPayload) {
    return axiosInstance.put(ApiConstants.TOGGLECOUPONS, dataPayload);
  }
}
