import React from 'react';
import DatePicker from 'react-datepicker';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Header from '../../components/Header';
import AdminService from '../../services/adminService';
import PageLoader from '../../components/PageLoader';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './ReportsPage.scss';

class ReportsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: true,
      message: '',
      reportTypes: [],
      formData: {
        to: null,
        from: null,
        type: 'ALL',
      },
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.getConfig();
  }

  getConfig = () => {
    this.adminService
      .config({ includes: ['REPORT_TYPES'] })
      .then((responseData) => {
        this.setState({
          loader: false,
          reportTypes: responseData?.data?.reportTypes || [],
        });
      })
      .catch(() => {});
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    this.setState({ loader: true, message: '' });

    const { formData } = this.state;

    const dataPayload = {
      ...formData,
    };

    this.adminService
      .generateReports(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false, message: responseData.data.message });
      })
      .catch((errorData) => {
        this.setState({
          loader: false,
        });
      });
  };

  render() {
    const { loader, message, formData, reportTypes } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Reports</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="reports-container">
          <div className="reports-container-wrapper">
            <h1 className="reports-container-title">Reports</h1>

            <div className="reports-container-content">
              <div className="input-wrapper">
                <label>Report Type</label>
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('type', event.target.value)
                  }
                  value={formData.type}
                >
                  {reportTypes.length !== 0 &&
                    reportTypes.map((reportType, index) => {
                      return (
                        <option key={index} value={reportType.value}>
                          {reportType.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="input-wrapper">
                <label>Date From</label>
                <DatePicker
                  timeIntervals={1}
                  showTimeSelect
                  showYearDropdown
                  showMonthDropdown
                  maxDate={new Date()}
                  dropdownMode="select"
                  timeFormat="HH:mm:ss"
                  popperPlacement="right"
                  className="input-field"
                  value={formData.from}
                  placeholderText="Date From (Optional)"
                  selected={formData.from}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  onChange={(date) => this.handleFieldUpdate('from', date)}
                />
              </div>

              <div className="input-wrapper">
                <label>Date To</label>
                <DatePicker
                  timeIntervals={1}
                  showTimeSelect
                  showYearDropdown
                  showMonthDropdown
                  maxDate={new Date()}
                  dropdownMode="select"
                  timeFormat="HH:mm:ss"
                  popperPlacement="right"
                  className="input-field"
                  value={formData.to}
                  placeholderText="Date To (Optional)"
                  selected={formData.to}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  onChange={(date) => this.handleFieldUpdate('to', date)}
                />
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Request Report
                </button>
              </div>

              {message && <p>{message}</p>}
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(ReportsPage));
