import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";

export class QuestionData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataType: "",
    };
  }

  handleAddQuestionData = (fieldValue) => {
    this.setState({ dataType: fieldValue }, () => {
      this.setState({ dataType: "" });
      this.props.handleAddQuestionData(fieldValue);
    });
  };

  render() {
    const { dataType } = this.state;
    const { questionData, isFITB } = this.props;
    return (
      <>
        {questionData && questionData.length !== 0 &&
          questionData.map((data, index) => {
            if (data.type === "text") {
              return (
                <div className="question-data" key={index}>
                  <div className="question-replicable">
                    <div className="question-replicable-item d-flex align-items-center justify-content-between">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="input-field"
                          placeholder={`Question Paragraph`}
                          value={data.value}
                          onChange={(event) =>
                            this.props.handleFieldUpdate(
                              `questionData[${index}].value`,
                              event.target.value
                            )
                          }
                        />
                      </div>

                      {isFITB && (
                        <button
                          type="button"
                          className="button button-link"
                          onClick={() =>
                            this.props.handleAddQuestionField(index)
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add Field
                        </button>
                      )}

                      <button
                        type="button"
                        className="button button-link"
                        onClick={() =>
                          this.props.handleRemoveQuestionData(index)
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            } else if (data.type === "image" || data.type === "audio") {
              return (
                <div className="question-data" key={index}>
                  <div className="question-replicable">
                    <div className="question-replicable-item d-flex align-items-center justify-content-between">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="input-field"
                          placeholder={`Question Media URL`}
                          value={data.value}
                          onChange={(event) =>
                            this.props.handleFieldUpdate(
                              `questionData[${index}].value`,
                              event.target.value
                            )
                          }
                        />
                      </div>

                      <button
                        type="button"
                        className="button button-link"
                        onClick={() =>
                          this.props.handleRemoveQuestionData(index)
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            } else if (data.type === "video") {
              return (
                <div className="question-data" key={index}>
                  <div className="question-replicable">
                    <div className="question-replicable-item d-flex align-items-center justify-content-between">
                      <select
                        className="input-select"
                        value={data.vendor}
                        onChange={(event) =>
                          this.props.handleFieldUpdate(
                            `questionData[${index}].vendor`,
                            event.target.value
                          )
                        }
                      >
                        <option value="">Select a Vendor</option>
                        <option value={"Direct"}>Direct</option>
                        <option value={"Embeded"}>Embeded</option>
                        <option value={"YouTube"}>YouTube</option>
                      </select>

                      {data.vendor === "Embeded" ||
                      data.vendor === "YouTube" ? (
                        <div className="input-wrapper">
                          <textarea
                            rows={2}
                            type="text"
                            className="input-field"
                            placeholder={`Question Media URL/Embed Code`}
                            value={data.value}
                            onChange={(event) =>
                              this.props.handleFieldUpdate(
                                `questionData[${index}].value`,
                                event.target.value
                              )
                            }
                          />
                        </div>
                      ) : (
                        <div className="input-wrapper">
                          <input
                            type="text"
                            className="input-field"
                            placeholder={`Question Media URL`}
                            value={data.value}
                            onChange={(event) =>
                              this.props.handleFieldUpdate(
                                `questionData[${index}].value`,
                                event.target.value
                              )
                            }
                          />
                        </div>
                      )}

                      <button
                        type="button"
                        className="button button-link"
                        onClick={() =>
                          this.props.handleRemoveQuestionData(index)
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            } else if (data.type === "url") {
              return (
                <div className="question-data" key={index}>
                  <div className="question-replicable">
                    <div className="question-replicable-item d-flex align-items-center justify-content-between">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="input-field"
                          placeholder={`Question URL`}
                          value={data.value}
                          onChange={(event) =>
                            this.props.handleFieldUpdate(
                              `questionData[${index}].value`,
                              event.target.value
                            )
                          }
                        />
                      </div>

                      <button
                        type="button"
                        className="button button-link"
                        onClick={() =>
                          this.props.handleRemoveQuestionData(index)
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            } else return <React.Fragment key={index}></React.Fragment>;
          })}

        <div className="input-wrapper">
          <select
            className="input-select"
            onChange={(event) => this.handleAddQuestionData(event.target.value)}
            value={dataType}
          >
            <option value="" disabled>
              Choose a Question Type
            </option>

            <option value="audio">Audio</option>
            <option value="image">Image</option>
            <option value="text">Text</option>
            <option value="url">URL</option>
            <option value="video">Video</option>
          </select>
        </div>
      </>
    );
  }
}
