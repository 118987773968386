import moment from "moment";
import { get } from "lodash";
export const tableDataParser = (responseData, columnCount) => {
  if (responseData && responseData.length !== 0) {
    let tableDataArr = [];

    for (let rowIndex = 0; rowIndex < responseData.length; rowIndex++) {
      let currentRowArr = [];

      currentRowArr.push(`${rowIndex + 1}.`);
      currentRowArr.push(responseData[rowIndex]._id);
      currentRowArr.push(get(responseData[rowIndex], "project.title", "N/A"));
      currentRowArr.push(
        get(responseData[rowIndex], "project.chapterID.chapter", "N/A")
      );
      currentRowArr.push(
        get(
          responseData[rowIndex],
          "project.chapterID.subjectID.subject",
          "N/A"
        )
      );
      currentRowArr.push(
        get(
          responseData[rowIndex],
          "project.chapterID.subjectID.classID.class",
          "N/A"
        )
      );
      currentRowArr.push(responseData[rowIndex].featured ? "Yes" : "No");
      currentRowArr.push(
        moment(responseData[rowIndex].createdAt).format("DD/MM/YY hh:mm A")
      );
      tableDataArr.push(currentRowArr);
    }

    return tableDataArr;
  } else {
    return [];
  }
};
