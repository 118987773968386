import React from 'react';

import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  QuestionSubTypes,
  QuestionTypes,
  QuestionSubTypePayload,
  Subjects,
  EnglishTags,
} from '../../common/Constants';

import QuestionPods from '../../components/QuestionPods';
import Header from '../../components/Header';
import SubjectService from '../../services/subjectService';
import ClassService from '../../services/classService';
import ContentService from '../../services/contentService';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import SideNavigation from '../../components/SideNavigation';
import ImageUploader from '../../components/ImageUploader';
import PageLoader from '../../components/PageLoader';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import { switchQuestionPositions } from '../../common/Helpers';

import styles from './AddWalkthroughPage.scss';

class AddWalkthroughPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      classesArr: [],
      subjectsArr: [],
      chaptersArr: [],
      existingChapter: 'false',
      selectedQuestionType: '',
      selectedQuestionSubType: '',
      markedForDeletion: [],
      positionSwitchMap: {},
      isEnglishSelected: false,
      formData: {
        subjectID: '',
        classID: '',
        resourceType: 'Walkthrough',
        chapterTitle: '',
        chapterNumber: '',
        englishTag: '',
        chapterID: '',
        questions: [],
        mediaDirectory: uuid(),
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
      showDeleteConfirmation: false,
      questionIdentifier: null,
    };

    this.subjectService = new SubjectService();
    this.classService = new ClassService();
    this.contentServive = new ContentService();
  }

  componentDidMount() {
    this.fetchAllClasses();
  }

  fetchAllClasses = () => {
    this.setState({ loader: true });

    this.classService
      .getAllClasses()
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            classesArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH CLASSES', errorData);
      });
  };

  getSubjectByClass = () => {
    const { classID } = this.state.formData;

    if (!classID) {
      return;
    }

    this.setState({ loader: true });

    this.subjectService
      .getSubjectsByClass({ classID })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            subjectsArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH SUBJECTS', errorData);
      });
  };

  getChaptersBySubject = () => {
    const { subjectID } = this.state.formData;

    if (!subjectID) {
      return;
    }

    this.setState({ loader: true });

    this.subjectService
      .getChapters({ subjectID })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            chaptersArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH CHAPTERS', errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData, existingChapter, isEnglishSelected } = this.state;

    if (formData.resourceType === '') {
      errorMessagesArr.push('Resource Type is a required field.');
      isFormValid = false;
    }

    if (formData.classID === '') {
      errorMessagesArr.push('Class is a required field.');
      isFormValid = false;
    }

    if (formData.subjectID === '') {
      errorMessagesArr.push('Subject is a required field.');
      isFormValid = false;
    }

    if (existingChapter === 'true') {
      if (formData.chapterID === '') {
        errorMessagesArr.push('Selection of Lesson is a required.');
        isFormValid = false;
      }
    } else {
      if (formData.chapterTitle === '') {
        errorMessagesArr.push('Lesson Title is a required field.');
        isFormValid = false;
      }

      if (formData.chapterNumber === '') {
        errorMessagesArr.push('Lesson Number is a required field.');
        isFormValid = false;
      }

      if (isEnglishSelected && !formData.englishTag) {
        errorMessagesArr.push('Lesson Tag is required for English.');
        isFormValid = false;
      }
    }

    if (formData.questions.length === 0) {
      errorMessagesArr.push('Questions cannot be less than 1.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState(
      (state) => ({
        ...state,
        formData: {
          ...state.formData,
          [`${fieldName}`]: fieldValue,
        },
      }),
      () => {
        if (fieldName === 'classID') {
          this.setState((state) => ({
            ...state,
            isEnglishSelected: false,
            formData: { ...state.formData, subjectID: '' },
          }));
          this.getSubjectByClass();
        } else if (fieldName === 'subjectID') {
          const isEnglishSelected = this.state.subjectsArr.some(
            (subject) =>
              subject._id === fieldValue && subject.subject === Subjects.ENGLISH
          );

          this.setState((state) => ({
            ...state,
            isEnglishSelected,
            formData: { ...state.formData, chapterID: '' },
          }));
          this.getChaptersBySubject();
        }
      }
    );
  };

  handleStateUpdate = (fieldName, fieldValue) => {
    this.setState(
      (state) => ({
        ...state,
        [`${fieldName}`]: fieldValue,
      }),
      () => {
        if (fieldName === 'selectedQuestionType') {
          if (
            fieldValue === QuestionTypes.DSC ||
            fieldValue === QuestionTypes.EMPTY
          ) {
            this.setState({
              selectedQuestionSubType: QuestionSubTypes[fieldValue],
            });
          } else {
            if (
              this.state.selectedQuestionSubType ===
              QuestionSubTypes[fieldValue]
            ) {
              this.setState({ selectedQuestionSubType: '' });
            }
          }
        }
      }
    );
  };

  addQuestionHandler = () => {
    const { selectedQuestionType, selectedQuestionSubType } = this.state;

    if (selectedQuestionType && selectedQuestionSubType) {
      let questionsPayload = cloneDeep(
        QuestionSubTypePayload[selectedQuestionSubType]
      );

      questionsPayload = {
        ...questionsPayload,
        markedForDeletion: false,
        code: `${new Date().getTime()}`,
        questionType: selectedQuestionType,
      };

      this.setState((state) => ({
        ...state,
        selectedQuestionType: '',
        selectedQuestionSubType: '',
        formData: {
          ...state.formData,
          questions: [...state.formData.questions, { ...questionsPayload }],
        },
      }));
    }
  };

  removeQuestion = (questionIndex) => {
    this.setState({
      showDeleteConfirmation: true,
      questionIdentifier: questionIndex,
    });
  };

  resetDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
      questionIdentifier: null,
    });
  };

  removeQuestionHandler = () => {
    let questions = Object.assign([], this.state.formData.questions);
    questions[this.state.questionIdentifier].markedForDeletion = true;

    this.setState((state) => ({
      ...state,
      markedForDeletion: [
        ...state.markedForDeletion,
        this.state.questionIdentifier,
      ],
      formData: {
        ...state.formData,
        questions,
      },
    }));

    this.resetDeleteConfirmation();
  };

  handleQuestionUpdate = (questionData, questionIndex) => {
    let updatedQuestions = Object.assign([], this.state.formData.questions);
    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      ...questionData,
    };

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questions: updatedQuestions,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;
      let updatedQuestions = [];

      for (let question of formData.questions) {
        if (!question.markedForDeletion) {
          updatedQuestions.push(question);
        }
      }

      updatedQuestions = switchQuestionPositions(
        updatedQuestions,
        this.state.positionSwitchMap
      );

      let dataPayload = {
        classID: formData.classID,
        subjectID: formData.subjectID,
        resourceType: formData.resourceType,
        questions: updatedQuestions,
        mediaDirectory: formData.mediaDirectory,
      };

      if (this.state.existingChapter === 'true') {
        dataPayload.chapterID = formData.chapterID;
      } else {
        dataPayload = {
          ...dataPayload,
          chapterNumber: formData.chapterNumber,
          chapterTitle: formData.chapterTitle,
          englishTag: formData.englishTag || null,
        };
      }

      this.contentServive
        .addContent(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });
          console.log('SUCCESSFULLY ADDED NEW Content', responseData);
          this.handleNavigation();
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });

          console.log('UNABLE TO ADD NEW CONTENT', errorData);
        });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  handleEmptyQuestionUpdate = (questionData, position) => {
    const { selectedQuestionSubType, selectedQuestionType } = questionData;

    let questionsPayload = cloneDeep(
      QuestionSubTypePayload[selectedQuestionSubType]
    );

    questionsPayload = {
      ...questionsPayload,
      markedForDeletion: false,
      code: `${new Date().getTime()}`,
      questionType: selectedQuestionType,
    };

    const questions = Object.assign([], this.state.formData.questions);
    questions[position] = questionsPayload;

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questions,
      },
    }));
  };

  updatePositionMap = (questionCode, switchIndex) => {
    let positionSwitchMap = Object.assign({}, this.state.positionSwitchMap);

    delete positionSwitchMap[questionCode];
    if (switchIndex !== '') {
      positionSwitchMap[questionCode] = switchIndex;
    }

    this.setState((state) => ({
      ...state,
      positionSwitchMap,
    }));
  };

  handleNavigation = () => {
    const { subjectsArr, formData } = this.state;
    const { subjectID } = formData;

    let subject = null;
    if (subjectsArr.length && subjectID) {
      for (let s of subjectsArr) {
        if (s._id === subjectID) {
          subject = s.subject;
          break;
        }
      }
    }

    switch (subject) {
      case Subjects.MATHS:
      case Subjects.ENGLISH:
      case Subjects.SCIENCE:
        return this.props.history.push(
          NavigationPaths.SUBJECTWALKTHROUGHPAGE.replace(':subject', subject)
        );

      case Subjects.EVS:
        return this.props.history.push(
          NavigationPaths.SUBJECTWALKTHROUGHPAGE.replace(
            ':subject',
            Subjects.SCIENCE
          )
        );

      default:
        return this.props.history.push(NavigationPaths.WALKTHROUGHPAGE);
    }
  };

  render() {
    const {
      loader,
      formData,
      classesArr,
      subjectsArr,
      chaptersArr,
      existingChapter,
      showServerErrors,
      validationErrors,
      positionSwitchMap,
      isEnglishSelected,
      selectedQuestionType,
      showValidationErrors,
      showDeleteConfirmation,
      selectedQuestionSubType,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Add New Walkthrough</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addcontent-container">
          <ImageUploader mediaDirectory={formData.mediaDirectory} />

          <div className="addcontent-container-wrapper">
            <h1 className="addcontent-container-title">Add New Walkthrough</h1>

            <div className="addcontent-container-content">
              <div className="addcontent-container-metadata">
                {showValidationErrors && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    {validationErrors.map((item, index) => {
                      return <p key={index}>{item}</p>;
                    })}
                  </div>
                )}

                {showServerErrors !== '' && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    <p>{showServerErrors}</p>
                  </div>
                )}

                <div className="input-wrapper">
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate('classID', event.target.value)
                    }
                    value={formData.classID}
                  >
                    <option value="">Choose a Class</option>

                    {classesArr.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.class}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="input-wrapper">
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate('subjectID', event.target.value)
                    }
                    value={formData.subjectID}
                    disabled={!formData.classID ? true : false}
                  >
                    <option value="" disabled>
                      Choose a Subject
                    </option>

                    {formData.classID &&
                      subjectsArr.map((item, index) => {
                        return (
                          <option key={index} value={item._id}>
                            {item.subject}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <hr />

                <div className="input-wrapper">
                  <label>Part of an Existing Lesson?</label>
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleStateUpdate(
                        'existingChapter',
                        event.target.value
                      )
                    }
                    value={existingChapter}
                  >
                    <option value={'false'}>No</option>
                    <option value={'true'}>Yes</option>
                  </select>
                </div>

                {existingChapter === 'true' ? (
                  <div className="input-wrapper">
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleFieldUpdate('chapterID', event.target.value)
                      }
                      value={formData.chapterID}
                      disabled={!formData.subjectID ? true : false}
                    >
                      <option value="" disabled>
                        Choose a Lesson
                      </option>

                      {formData.subjectID &&
                        chaptersArr.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.chapter}{' '}
                              {item?.subjectID?.subject === Subjects.ENGLISH &&
                                ` (${item.englishTag})`}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="input-field"
                        placeholder="Lesson Title"
                        value={formData.chapterTitle}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'chapterTitle',
                            event.target.value
                          )
                        }
                      />
                    </div>

                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="input-field"
                        placeholder="Lesson Number"
                        value={formData.chapterNumber}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'chapterNumber',
                            event.target.value
                          )
                        }
                      />
                    </div>

                    {isEnglishSelected && (
                      <div className="input-wrapper">
                        <select
                          className="input-select"
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              'englishTag',
                              event.target.value
                            )
                          }
                          value={formData.englishTag}
                        >
                          <option value="" disabled>
                            Select English Tag
                          </option>

                          {Object.keys(EnglishTags).map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>

              <hr />

              <div className="content-context">
                <h5>Walkthrough Questions</h5>

                {formData.questions.length !== 0 && (
                  <QuestionPods
                    questions={formData.questions}
                    removeQuestion={this.removeQuestion}
                    positionSwitchMap={positionSwitchMap}
                    updateQuestion={this.handleQuestionUpdate}
                    updatePositionMap={this.updatePositionMap}
                    updateEmptyQuestion={this.handleEmptyQuestionUpdate}
                  />
                )}

                <div className="content-context-actions d-flex align-items-center justify-content-start">
                  <div className="input-wrapper">
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleStateUpdate(
                          'selectedQuestionType',
                          event.target.value
                        )
                      }
                      value={selectedQuestionType}
                    >
                      <option value={''} disabled>
                        Select a Question Type
                      </option>
                      <option value="T">T - Text Only</option>
                      <option value="TWI">TWI - Text With Image</option>
                      <option value="TWV">TWV - Text With Video</option>
                      <option value="TWA">TWA - Text With Audio</option>
                      <option value="DSC">DSC - Description Type</option>
                      <option value="EMPTY">
                        EMPTY - Placeholder Question
                      </option>
                    </select>
                  </div>

                  <div className="input-wrapper">
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleStateUpdate(
                          'selectedQuestionSubType',
                          event.target.value
                        )
                      }
                      value={selectedQuestionSubType}
                      disabled={
                        selectedQuestionType === QuestionTypes.DSC ||
                        selectedQuestionType === QuestionTypes.EMPTY
                      }
                    >
                      <option value={''} disabled>
                        Select a Question Sub Type
                      </option>
                      <option value="SAQ">SAQ - Single Answer Question</option>
                      <option value="MCQ">
                        MCQ - Multiple Choice Question
                      </option>
                      <option value="MCQM">
                        MCQM - Multiple Choice Question With Multiple Answers
                      </option>
                      <option value="LQ">LQ - Labelling Question</option>
                      <option value="MTF">MTF - Match The Following</option>
                      <option value="FITBT">
                        FITBT - Fill In The Blanks - Typeable
                      </option>
                      <option value="FITBS">
                        FITBS - Fill In The Blanks - Selectable
                      </option>
                      <option value="TBLT">
                        TBLT - Table Question - Typeable
                      </option>
                      <option value="TBLS">
                        TBLS - Table Question - Selectable
                      </option>
                      <option value="LAQ">LAQ - Long Answer Question</option>
                      <option value="LAQE">
                        LAQE - Long Answer Question Editable
                      </option>
                      <option value="LAQWL">
                        LAQWL - Long Answer Question Word Limited
                      </option>
                      <option value="LAQAI">
                        LAQAI - Long Answer Question With AI Checking
                      </option>
                      <option value="RQT">RQT - Reading Question With Timer</option>
                      <option value="DSC">DSC - Description Type</option>
                      <option value="EMPTY">
                        EMPTY - Placeholder Question
                      </option>
                    </select>
                  </div>

                  <button
                    type="button"
                    className="button button-link"
                    onClick={this.addQuestionHandler}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </button>
                </div>

                <div className="content-context-trigger">
                  <button
                    type="button"
                    className="button button-primary"
                    onClick={() => this.handleFormSubmit()}
                  >
                    Add Walkthrough
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showDeleteConfirmation && (
          <DeleteConfirmation
            actionHandler={this.removeQuestionHandler}
            resetHandler={this.resetDeleteConfirmation}
          />
        )}

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AddWalkthroughPage));
