import React from 'react';

import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import LmsService from '../../services/lmsService';
import PageLoader from '../../components/PageLoader';
import ImageUploader from '../../components/ImageUploader';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './EditBannersPage.scss';

class EditBannersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      formData: {
        imageURL: '',
        actionURL: '',
        mediaDirectory: '',
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    this.lmsService = new LmsService();
  }

  componentDidMount() {
    this.getBannersByID();
  }

  getBannersByID = () => {
    this.setState({ loader: true });

    this.lmsService
      .getBannersByID({ bannerID: this.props.match.params.id })
      .then((responseData) => {
        if (responseData) {
          this.setState({
            loader: false,
            formData: {
              imageURL: responseData.data.imageURL || '',
              actionURL: responseData.data.actionURL || '',
              mediaDirectory: responseData.data.mediaDirectory || uuid(),
            },
          });
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [fieldName]: fieldValue,
      },
    }));
  };

  formValidation = () => {
    let isFormValid = true;
    let errorMessagesArr = [];
    const { formData } = this.state;

    if (!formData.imageURL) {
      errorMessagesArr.push('Banner Image URL is required');
      isFormValid = false;
    } else if (!this.urlRegex.test(formData.imageURL)) {
      errorMessagesArr.push('Banner Image URL is invalid');
      isFormValid = false;
    }

    if (!formData.actionURL) {
      errorMessagesArr.push('Banner Action URL is required');
      isFormValid = false;
    } else if (!this.urlRegex.test(formData.actionURL)) {
      errorMessagesArr.push('Banner Action URL is invalid');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.formValidation();

    if (isFormValid) {
      this.setState({ loader: true });

      this.lmsService
        .editBanners({
          ...this.state.formData,
          bannerID: this.props.match.params.id,
        })
        .then((responseData) => {
          this.props.history.push(NavigationPaths.BANNERSPAGE);
        })
        .catch((errorData) => {
          this.setState({ loader: false, showServerErrors: errorData.message });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });

          console.log('UNABLE TO SET BANNERS', errorData);
        });
    }
  };

  render() {
    const {
      loader,
      formData,
      validationErrors,
      showServerErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Banner</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editbanners-container">
          <ImageUploader mediaDirectory={formData.mediaDirectory} />

          <div className="editbanners-container-wrapper">
            <h1 className="editbanners-container-title">Edit Banner</h1>

            <div className="editbanners-container-content">
              <div className="editbanners-container-details">
                {showValidationErrors && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    {validationErrors.map((item, index) => {
                      return <p key={index}>{item}</p>;
                    })}
                  </div>
                )}

                {showServerErrors !== '' && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    <p>{showServerErrors}</p>
                  </div>
                )}

                <div className="input-wrapper">
                  <label>Banner Image URL</label>
                  <input
                    type="text"
                    className="input-field"
                    placeholder={`Banner Image URL`}
                    value={formData.imageURL}
                    onChange={(event) =>
                      this.handleFieldUpdate(`imageURL`, event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <label>Banner Action URL</label>
                  <input
                    type="text"
                    className="input-field"
                    placeholder={`Banner Action URL`}
                    value={formData.actionURL}
                    onChange={(event) =>
                      this.handleFieldUpdate(`actionURL`, event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <img alt='Banner' src={formData.imageURL} />
                </div>

                <div className="input-wrapper">
                  <button
                    type="button"
                    className="button button-primary"
                    onClick={() => this.handleFormSubmit()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditBannersPage));
