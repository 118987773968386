import React from 'react';
import { set, get } from 'lodash';
import { QuestionData } from '../QuestionData';
import { UrlRegex, QuestionSubTypePayload } from '../../common/Constants';
import QuestionHeader from '../QuestionHeader';

export default class RQTSubType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSafeButton: false,
      validationErrors: [],
      showValidationErrors: false,
      formData: Object.assign({}, QuestionSubTypePayload.RQT),
    };
  }

  componentDidMount() {
    this.prefillQuestionData();
  }

  prefillQuestionData = () => {
    const { currentQuestionData } = this.props;

    if (currentQuestionData) {
      this.setState((state) => ({
        ...state,
        formData: currentQuestionData,
      }));
    }
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    set(formData, fieldName, fieldValue);

    this.setState((state) => ({
      ...state,
      formData,
      showSafeButton: true,
    }));
  };

  handleUpdateQuestion = () => {
    const valid = this.validateFormData();

    if (valid) {
      this.setState({ showSafeButton: false }, () => {
        this.props.updateQuestion(this.state.formData, this.props.position);
      });
    }
  };

  handleAddQuestionData = (dataType) => {
    let questionData = Object.assign([], this.state.formData.questionData);
    questionData.push({
      type: dataType,
      value: '',
      vendor: dataType === 'video' ? '' : null,
    });

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionData,
      },
      showSafeButton: true,
    }));
  };

  handleRemoveQuestionData = (index) => {
    let questionData = Object.assign([], this.state.formData.questionData);

    questionData = questionData.filter(
      (item, itemIndex) => itemIndex !== index
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questionData,
      },
      showSafeButton: true,
    }));
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (!formData.questionData.length) {
      errorMessagesArr.push('Atleast 1 Question Field is required.');
      isFormValid = false;
    } else {
      for (let q of formData.questionData) {
        if (!q.value || (q.type === 'video' && !q.vendor)) {
          errorMessagesArr.push('Question Field Value is required.');
          isFormValid = false;
          break;
        }

        if (q.type === 'url' && !UrlRegex.test(q.value)) {
          errorMessagesArr.push('Question URL is invalid.');
          isFormValid = false;
          break;
        }
      }
    }

    if (!formData.answerData.texts.availableMinutes || formData.answerData.texts.availableMinutes <= 0) {
      errorMessagesArr.push('Available number of minutes are required.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  render() {
    const {
      formData,
      showSafeButton,
      showValidationErrors,
      validationErrors,
    } = this.state;
    return (
      <div className="content-question">
        <QuestionHeader
          code={formData.code}
          showCompetency={true}
          position={this.props.position}
          competency={formData.competency}
          showQuestionSubTypeSwitcher={false}
          questionSubType={formData.questionSubType}
          handleFieldUpdate={this.handleFieldUpdate}
          removeQuestion={this.props.removeQuestion}
          questionsCount={this.props.questionsCount}
          updatePositionMap={this.props.updatePositionMap}
          positionSwitchMap={this.props.positionSwitchMap}
          questionType={get(this.props.currentQuestionData, 'questionType', '')}
          markedForDeletion={get(
            this.props.currentQuestionData,
            'markedForDeletion',
            ''
          )}
        />

        <div className="content-question-body">
          {showValidationErrors && (
            <div className="error-wrapper">
              <h6>Error!</h6>

              {validationErrors.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          )}

          <QuestionData
            questionData={formData.questionData}
            handleFieldUpdate={this.handleFieldUpdate}
            handleAddQuestionData={this.handleAddQuestionData}
            handleRemoveQuestionData={this.handleRemoveQuestionData}
          />

          <div className="input-wrapper">
            <input
              type="number"
              className="input-field"
              placeholder="Available Minutes"
              value={get(formData.answerData, 'texts.availableMinutes', '')}
              onChange={(event) =>
                this.handleFieldUpdate(
                  `answerData.texts.availableMinutes`,
                  event.target.value
                )
              }
            />
          </div>

          {showSafeButton && (
            <div className="input-wrapper">
              <button
                type="button"
                className="button button-secondary"
                onClick={() => this.handleUpdateQuestion()}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
