import { v4 as uuid } from 'uuid';

export const ResourceTypes = ['Walkthrough', 'Project', 'Activity', 'Game'];

export const QuestionTypes = {
  T: 'T',
  TWI: 'TWI',
  TWV: 'TWV',
  TWA: 'TWA',
  DSC: 'DSC',
  EMPTY: 'EMPTY',
};

export const QuestionSubTypes = {
  SAQ: 'SAQ',
  MCQ: 'MCQ',
  MCQM: 'MCQM',
  LQ: 'LQ',
  FITBT: 'FITBT',
  FITBS: 'FITBS',
  TBLT: 'TBLT',
  TBLS: 'TBLS',
  MTF: 'MTF',
  LAQ: 'LAQ',
  LAQE: 'LAQE',
  LAQWL: 'LAQWL',
  LAQAI: 'LAQAI',
  RQT: 'RQT',
  DSC: 'DSC',
  EMPTY: 'EMPTY',
};

export const UrlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

export const QuestionSubTypePayload = {
  SAQ: {
    questionSubType: QuestionSubTypes.SAQ,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: {
        text: '',
        id: uuid(),
      },
    },
    correctAnswer: '',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  MCQ: {
    questionSubType: QuestionSubTypes.MCQ,
    competency: '',
    questionData: [],
    answerType: '',
    answerData: {},
    correctAnswer: '',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  MCQM: {
    questionSubType: QuestionSubTypes.MCQM,
    competency: '',
    questionData: [],
    answerType: '',
    answerData: {},
    correctAnswer: [],
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  LQ: {
    questionSubType: QuestionSubTypes.LQ,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: [
        {
          label: '',
          id: uuid(),
          ans: '',
        },
      ],
    },
    correctAnswer: 'NULL',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  MTF: {
    questionSubType: QuestionSubTypes.MTF,
    competency: '',
    questionData: [],
    answerType: 'NULL',
    answerData: {
      ques: [],
      ans: [],
    },
    correctAnswer: '',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  FITBT: {
    questionSubType: QuestionSubTypes.FITBT,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: [],
    },
    correctAnswer: 'NULL',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  FITBS: {
    questionSubType: QuestionSubTypes.FITBS,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: [],
    },
    correctAnswer: 'NULL',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  TBLT: {
    questionSubType: QuestionSubTypes.TBLT,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: [],
    },
    correctAnswer: 'NULL',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  TBLS: {
    questionSubType: QuestionSubTypes.TBLS,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: [],
    },
    correctAnswer: 'NULL',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  DSC: {
    questionSubType: QuestionSubTypes.DSC,
    questionData: [],
  },

  EMPTY: {
    questionSubType: QuestionSubTypes.EMPTY,
    selectedQuestionType: '',
    selectedQuestionSubType: '',
  },

  LAQ: {
    questionSubType: QuestionSubTypes.LAQ,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: {
        text: '',
        id: uuid(),
      },
    },
    correctAnswer: '',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  LAQE: {
    questionSubType: QuestionSubTypes.LAQE,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      ques: '',
      ans: '',
    },
    correctAnswer: '',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  LAQWL: {
    questionSubType: QuestionSubTypes.LAQWL,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: {
        keywords: [],
        wordCount: '',
      },
    },
    correctAnswer: '',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  LAQAI: {
    questionSubType: QuestionSubTypes.LAQAI,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: [
        {
          ans: '',
          id: uuid(),
        },
      ],
    },
    correctAnswer: '',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },

  RQT: {
    questionSubType: QuestionSubTypes.RQT,
    competency: '',
    questionData: [],
    answerType: 'text',
    answerData: {
      texts: {
        availableMinutes: '',
      },
    },
    correctAnswer: '',
    questionHint: [],
    questionSuggestions: [],
    explanation: {
      primaryImage: '',
      texts: [],
      secondaryImage: '',
      subTexts: [],
    },
  },
};

export const Subjects = {
  EVS: 'EVS',
  MATHS: 'Maths',
  ENGLISH: 'English',
  SCIENCE: 'Science',
};

export const EcomProductTypes = {
  BOOK: 'BOOK',
  EBOOK: 'EBOOK',
  PACKAGE: 'PACKAGE',
};

export const SYSTEM_PERMISSIONS = {
  STATS: 'STATS',
  CONTENT: 'CONTENT',
  PACKAGES: 'PACKAGES',
  CLASSES: 'CLASSES',
  USERS: 'USERS',
  AGENTS: 'AGENTS',
  COUPONS: 'COUPONS',
  USER_PROJECTS: 'USER_PROJECTS',
  SHOP_ORDERS: 'SHOP_ORDERS',
  ADMINS: 'ADMINS',
  PACKAGE_ORDERS: 'PACKAGE_ORDERS',
  PRODUCT_ORDERS: 'PRODUCT_ORDERS',
  NEWS: 'NEWS',
  BANNERS: 'BANNERS',
  ADVERTISEMENTS: 'ADVERTISEMENTS',
  SESSIONS: 'SESSIONS',
  PAYOUTS: 'PAYOUTS',
  REPORTS: 'REPORTS',
  NEWSLETTERS: 'NEWSLETTERS',
  VIDEOS: 'VIDEOS',
  DURATIONS: 'DURATIONS'
};

export const EnglishTags = {
  GRAMMAR: 'GRAMMAR',
  READING: 'READING',
  WRITING: 'WRITING',
};
