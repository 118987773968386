import React from "react";

import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { NavigationPaths } from "../../common/Environment";

import get from "lodash/get";
import Header from "../../components/Header";
import SubjectService from "../../services/subjectService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";
import ImageUploader from "../../components/ImageUploader";
import PageLoader from "../../components/PageLoader";

import styles from "./EditSubjectPage.scss";

class EditSubjectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      classesArr: [],
      className: null,
      mediaDirectory: null,
      formData: {
        subject: "",
        freebieIcon: "",
        projectIcon: "",
        activityIcon: "",
        worksheetIcon: "",
        subjectIcon: "",
        freebieDescription: "",
      },
      validationErrors: [],
      showServerErrors: "",
      showValidationErrors: false,
    };

    this.subjectService = new SubjectService();
  }

  componentDidMount() {
    this.getSubjectByID();
  }

  getSubjectByID = () => {
    this.setState({ loader: true });

    this.subjectService
      .getSubjectByID({ subjectID: this.props.match.params.id })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            className: get(responseData, "data.classID.class", "N/A"),
            mediaDirectory: get(responseData, "data.mediaDirectory", "N/A"),
            formData: {
              ...state.formData,
              subject: get(responseData, "data.subject", ""),
              freebieIcon: get(responseData, "data.freebieIcon", ""),
              projectIcon: get(responseData, "data.projectIcon", ""),
              activityIcon: get(responseData, "data.activityIcon", ""),
              worksheetIcon: get(responseData, "data.worksheetIcon", ""),
              subjectIcon: get(responseData, "data.subjectIcon", ""),
              freebieDescription: get(
                responseData,
                "data.freebieDescription",
                ""
              ),
            },
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log("UNABLE TO FETCH CLASSES", errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (this.props.match.params.id === "") {
      errorMessagesArr.push("Subject ID is required.");
      isFormValid = false;
    }

    if (formData.subject === "") {
      errorMessagesArr.push("Subject is a required field.");
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log("Invalid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log("Valid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: "",
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      const dataPayload = {
        subjectID: this.props.match.params.id,
        subject: formData.subject,
        freebieIcon: formData.freebieIcon,
        projectIcon: formData.projectIcon,
        activityIcon: formData.activityIcon,
        worksheetIcon: formData.worksheetIcon,
        subjectIcon: formData.subjectIcon,
        freebieDescription: formData.freebieDescription,
      };

      this.subjectService
        .editSubject(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });

          console.log("SUCCESSFULLY EDITED SUBJECT", responseData);

          if (responseData) {
            this.props.history.push(NavigationPaths.SUBJECTPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          console.log("UNABLE TO EDIT SUBJECT", errorData);
        });
    }
  };

  render() {
    const {
      loader,
      mediaDirectory,
      className,
      formData,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Subject</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editsubject-container">
          <ImageUploader mediaDirectory={mediaDirectory} />
          <div className="editsubject-container-wrapper">
            <h1 className="editsubject-container-title">Edit Subject</h1>

            <div className="editsubject-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== "" && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <p>
                  <b>Class Name: </b>
                  {className}
                </p>
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Subject Name"
                  value={formData.subject}
                  onChange={(event) =>
                    this.handleFieldUpdate("subject", event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Subject Icon URL"
                  value={formData.subjectIcon}
                  onChange={(event) =>
                    this.handleFieldUpdate("subjectIcon", event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Freebie Icon URL"
                  value={formData.freebieIcon}
                  onChange={(event) =>
                    this.handleFieldUpdate("freebieIcon", event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <textarea
                  rows={2}
                  type="text"
                  className="input-field"
                  placeholder="Freebie Description"
                  value={formData.freebieDescription}
                  onChange={(event) =>
                    this.handleFieldUpdate(
                      "freebieDescription",
                      event.target.value
                    )
                  }
                />
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Project Icon URL"
                  value={formData.projectIcon}
                  onChange={(event) =>
                    this.handleFieldUpdate("projectIcon", event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Activity Icon URL"
                  value={formData.activityIcon}
                  onChange={(event) =>
                    this.handleFieldUpdate("activityIcon", event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Worksheet Icon URL"
                  value={formData.worksheetIcon}
                  onChange={(event) =>
                    this.handleFieldUpdate("worksheetIcon", event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Edit Subject
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditSubjectPage));
