import React from "react";
import get from "lodash/get";

import { v4 as uuid } from "uuid";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { NavigationPaths } from "../../common/Environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  QuestionSubTypes,
  QuestionTypes,
  QuestionSubTypePayload,
  Subjects,
} from "../../common/Constants";
import { switchQuestionPositions } from "../../common/Helpers";

import QuestionPods from "../../components/QuestionPods";
import Header from "../../components/Header";
import ContentService from "../../services/contentService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";
import ImageUploader from "../../components/ImageUploader";
import PageLoader from "../../components/PageLoader";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import styles from "./EditWalkthroughPage.scss";

class EditWalkthroughPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      subject: null,
      selectedQuestionType: "",
      selectedQuestionSubType: "",
      markedForDeletion: [],
      positionSwitchMap: {},
      formData: {
        mediaDirectory: null,
        resourceType: "Walkthrough",
        questions: [],
      },
      validationErrors: [],
      showServerErrors: "",
      showValidationErrors: false,
      showDeleteConfirmation: false,
      questionIdentifier: null,
    };

    this.contentServive = new ContentService();
  }

  componentDidMount() {
    this.getWalkthroughData();
  }

  getWalkthroughData = () => {
    this.setState({ loader: true });

    const dataPayload = {
      resourceType: "Walkthrough",
      resourceID: this.props.match.params.id,
    };

    this.contentServive
      .getContentByID(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData.data) {
          const questions = Object.assign(
            [],
            get(responseData.data, "questions", [])
          );

          this.setState((state) => ({
            ...state,
            subject: get(responseData.data, "chapterID.subjectID.subject"),
            formData: {
              ...state.formData,
              questions,
              mediaDirectory: get(responseData.data, "mediaDirectory", uuid()),
            },
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log("UNABLE TO FETCH DATA", errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.resourceType === "") {
      errorMessagesArr.push("Resource Type is a required field.");
      isFormValid = false;
    }

    if (formData.questions.length === 0) {
      errorMessagesArr.push("Questions cannot be less than 1.");
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log("Invalid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log("Valid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: "",
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleStateUpdate = (fieldName, fieldValue) => {
    this.setState(
      (state) => ({
        ...state,
        [`${fieldName}`]: fieldValue,
      }),
      () => {
        if (fieldName === "selectedQuestionType") {
          if (
            fieldValue === QuestionTypes.DSC ||
            fieldValue === QuestionTypes.EMPTY
          ) {
            this.setState({
              selectedQuestionSubType: QuestionSubTypes[fieldValue],
            });
          } else {
            if (
              this.state.selectedQuestionSubType ===
              QuestionSubTypes[fieldValue]
            ) {
              this.setState({ selectedQuestionSubType: "" });
            }
          }
        }
      }
    );
  };

  addQuestionHandler = () => {
    const { selectedQuestionSubType, selectedQuestionType } = this.state;

    if (selectedQuestionType && selectedQuestionSubType) {
      let questionsPayload = cloneDeep(
        QuestionSubTypePayload[selectedQuestionSubType]
      );

      questionsPayload = {
        ...questionsPayload,
        markedForDeletion: false,
        code: `${new Date().getTime()}`,
        questionType: selectedQuestionType,
      };

      this.setState((state) => ({
        ...state,
        selectedQuestionType: "",
        selectedQuestionSubType: "",
        formData: {
          ...state.formData,
          questions: [...state.formData.questions, questionsPayload],
        },
      }));
    }
  };

  removeQuestion = (questionIndex) => {
    this.setState({
      showDeleteConfirmation: true,
      questionIdentifier: questionIndex,
    });
  };

  resetDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
      questionIdentifier: null,
    });
  };

  removeQuestionHandler = () => {
    let questions = Object.assign([], this.state.formData.questions);
    questions[this.state.questionIdentifier].markedForDeletion = true;

    this.setState((state) => ({
      ...state,
      markedForDeletion: [
        ...state.markedForDeletion,
        this.state.questionIdentifier,
      ],
      formData: {
        ...state.formData,
        questions,
      },
    }));

    this.resetDeleteConfirmation();
  };

  handleQuestionUpdate = (questionData, questionIndex) => {
    let updatedQuestions = Object.assign([], this.state.formData.questions);
    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      ...questionData,
    };

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questions: updatedQuestions,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;
      let updatedQuestions = [];

      for (let question of formData.questions) {
        if (!question.markedForDeletion) {
          updatedQuestions.push(question);
        }
      }

      updatedQuestions = switchQuestionPositions(
        updatedQuestions,
        this.state.positionSwitchMap
      );

      let dataPayload = {
        resourceID: this.props.match.params.id,
        resourceType: formData.resourceType,
        questions: updatedQuestions,
      };

      this.contentServive
        .editContent(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });
          console.log("SUCCESSFULLY UPDATED Content", responseData);
          this.handleNavigation();
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });

          console.log("UNABLE TO EDIT CONTENT", errorData);
        });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  handleEmptyQuestionUpdate = (questionData, position) => {
    const { selectedQuestionSubType, selectedQuestionType } = questionData;

    let questionsPayload = cloneDeep(
      QuestionSubTypePayload[selectedQuestionSubType]
    );

    questionsPayload = {
      ...questionsPayload,
      markedForDeletion: false,
      code: `${new Date().getTime()}`,
      questionType: selectedQuestionType,
    };

    const questions = Object.assign([], this.state.formData.questions);
    questions[position] = questionsPayload;

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questions,
      },
    }));
  };

  updatePositionMap = (questionCode, switchIndex) => {
    let positionSwitchMap = Object.assign({}, this.state.positionSwitchMap);

    delete positionSwitchMap[questionCode];
    if (switchIndex !== "") {
      positionSwitchMap[questionCode] = switchIndex;
    }

    this.setState((state) => ({
      ...state,
      positionSwitchMap,
    }));
  };

  handleNavigation = () => {
    const { subject } = this.state;

    switch (subject) {
      case Subjects.MATHS:
      case Subjects.ENGLISH:
      case Subjects.SCIENCE:
        return this.props.history.push(
          NavigationPaths.SUBJECTWALKTHROUGHPAGE.replace(":subject", subject)
        );

      case Subjects.EVS:
        return this.props.history.push(
          NavigationPaths.SUBJECTWALKTHROUGHPAGE.replace(
            ":subject",
            Subjects.SCIENCE
          )
        );

      default:
        return this.props.history.push(NavigationPaths.WALKTHROUGHPAGE);
    }
  };

  render() {
    const {
      loader,
      formData,
      showServerErrors,
      validationErrors,
      positionSwitchMap,
      showValidationErrors,
      selectedQuestionType,
      selectedQuestionSubType,
      showDeleteConfirmation,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Walkthrough</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editcontent-container">
          <ImageUploader mediaDirectory={formData.mediaDirectory} />

          <div className="editcontent-container-wrapper">
            <h1 className="editcontent-container-title">Edit Walkthrough</h1>

            <div className="editcontent-container-content">
              <div className="editcontent-container-metadata">
                {showValidationErrors && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    {validationErrors.map((item, index) => {
                      return <p key={index}>{item}</p>;
                    })}
                  </div>
                )}

                {showServerErrors !== "" && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    <p>{showServerErrors}</p>
                  </div>
                )}
              </div>

              <hr />

              <div className="content-context">
                <h5>Walkthrough Questions</h5>

                {formData.questions.length !== 0 && (
                  <QuestionPods
                    questions={formData.questions}
                    removeQuestion={this.removeQuestion}
                    positionSwitchMap={positionSwitchMap}
                    updateQuestion={this.handleQuestionUpdate}
                    updatePositionMap={this.updatePositionMap}
                    updateEmptyQuestion={this.handleEmptyQuestionUpdate}
                  />
                )}

                <div className="content-context-actions d-flex align-items-center justify-content-start">
                  <div className="input-wrapper">
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleStateUpdate(
                          "selectedQuestionType",
                          event.target.value
                        )
                      }
                      value={selectedQuestionType}
                    >
                      <option value={""} disabled>
                        Select a Question Type
                      </option>
                      <option value="T">T - Text Only</option>
                      <option value="TWI">TWI - Text With Image</option>
                      <option value="TWV">TWV - Text With Video</option>
                      <option value="TWA">TWA - Text With Audio</option>
                      <option value="DSC">DSC - Description Type</option>
                      <option value="EMPTY">
                        EMPTY - Placeholder Question
                      </option>
                    </select>
                  </div>

                  <div className="input-wrapper">
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleStateUpdate(
                          "selectedQuestionSubType",
                          event.target.value
                        )
                      }
                      value={selectedQuestionSubType}
                      disabled={
                        selectedQuestionType === QuestionTypes.DSC ||
                        selectedQuestionType === QuestionTypes.EMPTY
                      }
                    >
                      <option value={""} disabled>
                        Select a Question Sub Type
                      </option>
                      <option value="SAQ">SAQ - Single Answer Question</option>
                      <option value="MCQ">
                        MCQ - Multiple Choice Question
                      </option>
                      <option value="MCQM">
                        MCQM - Multiple Choice Question With Multiple Answers
                      </option>
                      <option value="LQ">LQ - Labelling Question</option>
                      <option value="MTF">MTF - Match The Following</option>
                      <option value="FITBT">
                        FITBT - Fill In The Blanks - Typeable
                      </option>
                      <option value="FITBS">
                        FITBS - Fill In The Blanks - Selectable
                      </option>
                      <option value="TBLT">
                        TBLT - Table Question - Typeable
                      </option>
                      <option value="TBLS">
                        TBLS - Table Question - Selectable
                      </option>
                      <option value="LAQ">LAQ - Long Answer Question</option>
                      <option value="LAQE">
                        LAQE - Long Answer Question Editable
                      </option>
                      <option value="LAQWL">
                        LAQWL - Long Answer Question Word Limited
                      </option>
                      <option value="LAQAI">
                        LAQAI - Long Answer Question With AI Checking
                      </option>
                      <option value="RQT">RQT - Reading Question With Timer</option>
                      <option value="DSC">DSC - Description Type</option>
                      <option value="EMPTY">
                        EMPTY - Placeholder Question
                      </option>
                    </select>
                  </div>

                  <button
                    type="button"
                    className="button button-link"
                    onClick={this.addQuestionHandler}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </button>
                </div>

                <div className="content-context-trigger">
                  <button
                    type="button"
                    className="button button-primary"
                    onClick={() => this.handleFormSubmit()}
                  >
                    Edit Walkthrough
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showDeleteConfirmation && (
          <DeleteConfirmation
            actionHandler={this.removeQuestionHandler}
            resetHandler={this.resetDeleteConfirmation}
          />
        )}

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditWalkthroughPage));
