import { Modal } from 'react-bootstrap';
import { Subjects } from '../../common/Constants';
import React, { useState, useEffect } from 'react';
import ClassService from '../../services/classService';
import SubjectService from '../../services/subjectService';
import ContentService from '../../services/contentService';

export const CopyResourceModal = (props) => {
  const classService = new ClassService();
  const subjectService = new SubjectService();
  const contentServive = new ContentService();

  const [formData, setFormData] = useState({
    classID: '',
    subjectID: '',
    chapterID: '',
  });
  const [success, setSuccess] = useState(false);
  const [classesArr, setClassesArr] = useState([]);
  const [chaptersArr, setChaptersArr] = useState([]);
  const [showServerErrors, setShowServerErrors] = useState('');

  useEffect(() => {
    fetchAllClasses();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formData.classID) {
      getSubjectByClass();
    }
    // eslint-disable-next-line
  }, [formData.classID]);

  useEffect(() => {
    if (formData.subjectID) {
      getChaptersBySubject();
    }
    // eslint-disable-next-line
  }, [formData.subjectID]);

  const fetchAllClasses = () => {
    props.setLoader(true);
    classService
      .getAllClasses()
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          setClassesArr(responseData.data);
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
      });
  };

  const getSubjectByClass = () => {
    const { classID } = formData;

    if (!classID) {
      return;
    }

    props.setLoader(true);
    subjectService
      .getSubjectsByClass({ classID })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData.data) {
          const subject = responseData.data.find(
            (sub) => sub.subject === props.subject
          );
          
          if (subject?._id) {
            handleFieldUpdate('subjectID', subject._id);
          }
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
      });
  };

  const getChaptersBySubject = () => {
    const { subjectID } = formData;

    if (!subjectID) {
      return;
    }

    props.setLoader(true);
    subjectService
      .getChapters({ subjectID })
      .then((responseData) => {
        props.setLoader(false);
        if (responseData) {
          setChaptersArr(responseData.data);
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
      });
  };

  const handleFieldUpdate = (fieldName, fieldValue) => {
    const updatedFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(updatedFormData);

    if (fieldName === 'classID') {
      setFormData({ ...updatedFormData, subjectID: '', chapterID: '' });
    } else if (fieldName === 'subjectID') {
      setFormData({ ...updatedFormData, chapterID: '' });
    }
  };

  const submitHandler = () => {
    setSuccess(false);
    setShowServerErrors('');
    props.setLoader(true);
    contentServive
      .copyResources({ ...formData, resourceID: props.resourceID })
      .then((responseData) => {
        if (responseData) {
          setSuccess(true);
          props.setLoader(false);
        }
      })
      .catch((errorData) => {
        props.setLoader(false);
        setShowServerErrors(
          errorData?.message || 'Something went wrong. Please try again later.'
        );
      });
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Copy Resource
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showServerErrors !== '' && (
          <div className="error-wrapper">
            <h6>Error!</h6>
            <p>{showServerErrors}</p>
          </div>
        )}

        {success && (
          <div className="success-wrapper">
            <h6>Success!</h6>
            <p>Successfully copied the worksheet.</p>
          </div>
        )}

        <div className="input-wrapper">
          <select
            className="input-select"
            onChange={(event) =>
              handleFieldUpdate('classID', event.target.value)
            }
            value={formData.classID}
          >
            <option value="">Choose a Class</option>

            {classesArr.map((item, index) => {
              return item._id !== props.classID ? (
                <option key={index} value={item._id}>
                  {item.class}
                </option>
              ) : (
                <React.Fragment key={index}></React.Fragment>
              );
            })}
          </select>
        </div>

        <div className="input-wrapper">
          <select
            className="input-select"
            onChange={(event) =>
              handleFieldUpdate('chapterID', event.target.value)
            }
            value={formData.chapterID}
            disabled={!formData.subjectID ? true : false}
          >
            <option value="" disabled>
              Choose a Lesson
            </option>

            {formData.subjectID &&
              chaptersArr.map((item, index) => {
                return (
                  <option key={index} value={item._id}>
                    {item.chapter}{' '}
                    {item?.subjectID?.subject === Subjects.ENGLISH &&
                      ` (${item.englishTag})`}
                  </option>
                );
              })}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {formData.classID && formData.subjectID && formData.chapterID && (
          <button
            type="button"
            onClick={submitHandler}
            className="button button-primary"
          >
            Copy
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
