import React from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Header from '../../components/Header';
import PageLoader from '../../components/PageLoader';
import OrderService from '../../services/orderService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './EditProductOrdersPage.scss';

class EditProductOrdersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      orderData: null,
    };

    this.orderService = new OrderService();
  }

  componentDidMount() {
    this.getProductOrderByID();
  }

  getProductOrderByID = () => {
    this.setState({ loader: true });

    this.orderService
      .getProductOrderByID({ productOrderID: this.props.match.params.id })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            orderData: get(responseData, 'data', null),
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  getOrderStatus = () => {
    const { orderData } = this.state;
    let orderStatus = 'N/A';

    if (orderData) {
      if (orderData.status === 0) {
        orderStatus = 'Pending';
      } else if (orderData.status === 1) {
        orderStatus = 'Confirmed';
      } else if (orderData.status === 2) {
        orderStatus = 'Failed';
      }
    }

    return orderStatus;
  };

  render() {
    const { loader, orderData } = this.state;
    const productsData = get(orderData, 'product', []);

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Product Order Details</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editproductorder-container">
          <div className="editproductorder-container-wrapper">
            <h1 className="editproductorder-container-title">
              Product Order Details
            </h1>

            <div className="editproductorder-container-content">
              <div className="editproductorder-container-details">
                <h1>Basic Details</h1>
                <p>
                  <b>Status: </b>
                  {this.getOrderStatus()}
                </p>

                <p>
                  <b>ID: </b>
                  {get(orderData, 'order.orderID', 'N/A')}
                </p>

                <p>
                  <b>Currency: </b>
                  {get(orderData, 'order.orderCurrency', 'N/A')}
                </p>

                <p>
                  <b>Order Amount: </b>
                  {get(orderData, 'order.orderAmount', 'N/A')}
                </p>

                <p>
                  <b>Note: </b>
                  {get(orderData, 'order.orderNote', 'N/A')}
                </p>

                <p>
                  <b>Gateway: </b>
                  {get(orderData, 'order.gateway', 'N/A')}
                </p>
              </div>

              <div className="editproductorder-container-details">
                <h1>Customer Details</h1>
                <p>
                  <b>Name: </b>
                  {get(orderData, 'order.userName', 'N/A')}
                </p>

                <p>
                  <b>Number: </b>
                  {get(orderData, 'order.userNumber', 'N/A')}
                </p>

                <p>
                  <b>Email: </b>
                  {get(orderData, 'order.userEmail', 'N/A')}
                </p>
              </div>

              <div className="editproductorder-container-details">
                <h1>Product Details</h1>

                {productsData && productsData.length !== 0 && (
                  <div className="replicable-products">
                    {productsData.map((productItem, index) => {
                      return (
                        <div key={index} className="replicable-products-item">
                          <p>
                            <b>ID: </b>
                            {productItem._id || 'N/A'}
                          </p>
                          <p>
                            <b>Title: </b>
                            {productItem.title || 'N/A'}
                          </p>
                          <p>
                            <b>Amount: </b>
                            {`Rs. ${productItem.prices.INR}` || 'N/A'}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditProductOrdersPage));
