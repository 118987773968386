import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Header from '../../components/Header';
import LmsService from '../../services/lmsService';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import SideNavigation from '../../components/SideNavigation';
import PageLoader from '../../components/PageLoader';

import styles from './AddNewsPage.scss';
import { NavigationPaths } from '../../common/Environment';

class AddNewsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      formData: {
        url: '',
        content: '',
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    this.lmsService = new LmsService();
  }

  formValidation = () => {
    let isFormValid = true;
    let errorMessagesArr = [];
    const { formData } = this.state;

    if (!formData.content) {
      errorMessagesArr.push('News Content is required');
      isFormValid = false;
    }

    if (formData.url && !this.urlRegex.test(formData.url)) {
      errorMessagesArr.push('Banner Action URL is invalid');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.formValidation();

    if (isFormValid) {
      this.setState({ loader: true });

      this.lmsService
        .addNews(this.state.formData)
        .then((responseData) => {
          this.props.history.push(NavigationPaths.NEWSPAGE);
        })
        .catch((errorData) => {
          this.setState({ loader: false, showServerErrors: errorData.message });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        });
    }
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [fieldName]: fieldValue,
      },
    }));
  };

  render() {
    const {
      loader,
      formData,
      validationErrors,
      showServerErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Add News</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addnews-container">
          <div className="addnews-container-wrapper">
            <h1 className="addnews-container-title">Add News</h1>

            <div className="addnews-container-content">
              <div className="addnews-container-details">
                {showValidationErrors && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    {validationErrors.map((item, index) => {
                      return <p key={index}>{item}</p>;
                    })}
                  </div>
                )}

                {showServerErrors !== '' && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    <p>{showServerErrors}</p>
                  </div>
                )}

                <div className="input-wrapper">
                  <label>News Content</label>
                  <input
                    type="text"
                    className="input-field"
                    placeholder={`News Content`}
                    value={formData.content}
                    onChange={(event) =>
                      this.handleFieldUpdate(`content`, event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <label>News URL</label>
                  <input
                    type="text"
                    className="input-field"
                    placeholder={`News URL`}
                    value={formData.url}
                    onChange={(event) =>
                      this.handleFieldUpdate(`url`, event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <button
                    type="button"
                    className="button button-primary"
                    onClick={() => this.handleFormSubmit()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AddNewsPage));
