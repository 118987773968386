import moment from 'moment';
import { ReduxConstants } from '../common/Environment';
import axiosInstance from '../services/CreateService';
import axiosCDNInstance from '../services/createCDNService';

export default (state = {}, action) => {
  switch (action.type) {
    case ReduxConstants.LOGIN:
      return {
        ...state,
        adminDetails: action.payload,
        permissionUpdated: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

    case ReduxConstants.LOGOUT:
      return {
        ...state,
        adminDetails: {},
        permissionUpdated: null,
      };

    case ReduxConstants.UPDATEPERMISSIONS:
      return {
        ...state,
        permissionUpdated: moment().format('YYYY-MM-DD HH:mm:ss'),
        adminDetails: { ...state.adminDetails, permissions: action.payload },
      };

    case 'persist/REHYDRATE':
      // Check if Payload Exists
      if (action.payload) {
        // Check if Payload Contains User Key
        if (action.payload.admin) {
          // Check if User Key Has Own Property Value
          if (action.payload.admin.hasOwnProperty('adminDetails')) {
            axiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${action.payload.admin.adminDetails.accessToken}`;

            axiosCDNInstance.defaults.headers.common[
              'Authorization'
            ] = `${action.payload.admin.adminDetails.accessToken}`;
          }
        }
      }
      return state;

    default: {
      return state;
    }
  }
};
