import React from "react";
import { set, get } from "lodash";
import { v4 as uuid } from "uuid";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Subjects } from "../../common/Constants";
import { NavigationPaths } from "../../common/Environment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Header from "../../components/Header";
import ContentService from "../../services/contentService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";
import ImageUploader from "../../components/ImageUploader";
import PageLoader from "../../components/PageLoader";

import styles from "./EditProjectPage.scss";

class EditProjectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      currentProject: null,
      formData: {
        resourceType: "Project",
        mediaDirectory: uuid(),
        isFreebie: "false",
        thumbnail: "",
        title: "",
        difficulty: "",
        description: { texts: [""] },
        prices: {
          INR: "",
          USD: "",
        },
        projectURL: "",
      },
      validationErrors: [],
      showServerErrors: "",
      showValidationErrors: false,
    };

    this.contentServive = new ContentService();
  }

  componentDidMount() {
    this.getProjectData();
  }

  getProjectData = () => {
    this.setState({ loader: true });

    const dataPayload = {
      resourceType: "Project",
      resourceID: this.props.match.params.id,
    };

    this.contentServive
      .getContentByID(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData.data) {
          this.setState((state) => ({
            ...state,
            currentProject: responseData.data,
            formData: {
              ...state.formData,
              title: get(responseData.data, "title", ""),
              thumbnail: get(responseData.data, "thumbnail", ""),
              difficulty: get(responseData.data, "difficulty", ""),
              description: get(responseData.data, "description", {
                texts: [""],
              }),
              prices: get(responseData.data, "prices", { INR: "", USD: "" }),
              mediaDirectory: get(responseData.data, "mediaDirectory", uuid()),
              projectURL: get(responseData.data, "projectURL", ""),
              isFreebie:
                responseData.data.isFreebie === true ? "true" : "false",
            },
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log("UNABLE TO FETCH DATA", errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.resourceType === "") {
      errorMessagesArr.push("Resource Type is a required field.");
      isFormValid = false;
    }

    if (formData.title === "") {
      errorMessagesArr.push("Project Title is a required field.");
      isFormValid = false;
    }

    if (!formData.description.texts.length) {
      errorMessagesArr.push("Project Description is a required.");
      isFormValid = false;
    } else {
      for (let d of formData.description.texts) {
        if (!d || d === "") {
          errorMessagesArr.push("Project Description cannot be empty.");
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.difficulty === "") {
      errorMessagesArr.push("Project Difficulty is a required field.");
      isFormValid = false;
    }

    if (formData.thumbnail === "") {
      errorMessagesArr.push("Project Thumbnail URL is a required field.");
      isFormValid = false;
    }

    if (formData.prices.INR === "") {
      errorMessagesArr.push("Project INR Price is a required field.");
      isFormValid = false;
    }

    if (formData.prices.USD === "") {
      errorMessagesArr.push("Project USD Price is a required field.");
      isFormValid = false;
    }

    if (formData.projectURL === "") {
      errorMessagesArr.push("Project Document URL is a required field.");
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log("Invalid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log("Valid Form", errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: "",
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    set(formData, fieldName, fieldValue);

    this.setState((state) => ({
      ...state,
      formData,
    }));
  };

  handleStateUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      [`${fieldName}`]: fieldValue,
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      let dataPayload = {
        resourceType: formData.resourceType,
        mediaDirectory: formData.mediaDirectory,
        isFreebie: formData.isFreebie === "true" ? true : false,
        title: formData.title,
        description: formData.description,
        thumbnail: formData.thumbnail,
        prices: formData.prices,
        projectURL: formData.projectURL,
        resourceID: this.props.match.params.id,
        difficulty: formData.difficulty,
      };

      this.contentServive
        .editContent(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });
          console.log("SUCCESSFULLY Edited Content", responseData);
          this.handleNavigation()
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });

          console.log("UNABLE TO Edited CONTENT", errorData);
        });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  addDescriptionRow = () => {
    let description = Object.assign({}, this.state.formData.description);
    description.texts.push("");
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        description,
      },
    }));
  };

  removeDescriptionRow = (descriptionIndex) => {
    let description = Object.assign({}, this.state.formData.description);
    description.texts = description.texts.filter(
      (item, itemIndex) => itemIndex !== descriptionIndex
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        description,
      },
    }));
  };

  handleNavigation = () => {
    const subject = get(
      this.state.currentProject,
      "chapterID.subjectID.subject"
    );

    switch (subject) {
      case Subjects.MATHS:
      case Subjects.ENGLISH:
      case Subjects.SCIENCE:
        return this.props.history.push(
          NavigationPaths.SUBJECTPROJECTPAGE.replace(":subject", subject)
        );

      case Subjects.EVS:
        return this.props.history.push(
          NavigationPaths.SUBJECTPROJECTPAGE.replace(
            ":subject",
            Subjects.SCIENCE
          )
        );

      default:
        return this.props.history.push(NavigationPaths.PROJECTPAGE);
    }
  };

  render() {
    const {
      loader,
      formData,
      currentProject,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Project</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addcontent-container">
          <ImageUploader mediaDirectory={formData.mediaDirectory} />

          <div className="addcontent-container-wrapper">
            <h1 className="addcontent-container-title">Edit Project</h1>

            <div className="addcontent-container-content">
              <div className="addcontent-container-metadata">
                {showValidationErrors && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    {validationErrors.map((item, index) => {
                      return <p key={index}>{item}</p>;
                    })}
                  </div>
                )}

                {showServerErrors !== "" && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    <p>{showServerErrors}</p>
                  </div>
                )}

                <h5>Project Details</h5>

                <div className="input-wrapper">
                  <label>Project Class </label>
                  <input
                    disabled
                    type="text"
                    className="input-field"
                    value={get(
                      currentProject,
                      "chapterID.subjectID.classID.class",
                      "N/A"
                    )}
                  />
                </div>

                <div className="input-wrapper">
                  <label>Project Subject </label>
                  <input
                    disabled
                    type="text"
                    className="input-field"
                    value={get(
                      currentProject,
                      "chapterID.subjectID.subject",
                      "N/A"
                    )}
                  />
                </div>

                <div className="input-wrapper">
                  <label>Project Chapter </label>
                  <input
                    disabled
                    type="text"
                    className="input-field"
                    value={get(currentProject, "chapterID.chapter", "N/A")}
                  />
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Project Title"
                    value={formData.title}
                    onChange={(event) =>
                      this.handleFieldUpdate("title", event.target.value)
                    }
                  />
                </div>

                <div className="project-description">
                  {formData.description.texts.length !== 0 && (
                    <div className="description-data">
                      <div className="description-replicable">
                        {formData.description.texts.map(
                          (description, descriptionIndex) => {
                            return (
                              <div
                                key={descriptionIndex}
                                className="description-replicable-item d-flex align-items-center justify-content-between"
                              >
                                <div className="input-wrapper">
                                  <input
                                    type="text"
                                    className="input-field"
                                    placeholder={`Project Description ${
                                      descriptionIndex + 1
                                    }`}
                                    value={description}
                                    onChange={(event) =>
                                      this.handleFieldUpdate(
                                        `description.texts[${descriptionIndex}]`,
                                        event.target.value
                                      )
                                    }
                                  />
                                </div>

                                <button
                                  type="button"
                                  className="button button-link"
                                  disabled={
                                    formData.description.texts.length < 2
                                  }
                                  onClick={() =>
                                    this.removeDescriptionRow(descriptionIndex)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                              </div>
                            );
                          }
                        )}

                        <div className="description-replicable-trigger">
                          <button
                            type="button"
                            className="button button-link"
                            onClick={() => this.addDescriptionRow()}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Another Text
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Project Difficulty"
                    value={formData.difficulty}
                    onChange={(event) =>
                      this.handleFieldUpdate("difficulty", event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Project Thumbnail URL"
                    value={formData.thumbnail}
                    onChange={(event) =>
                      this.handleFieldUpdate("thumbnail", event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Project Price in INR"
                    value={formData.prices.INR}
                    onChange={(event) =>
                      this.handleFieldUpdate("prices.INR", event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Project Price in USD"
                    value={formData.prices.USD}
                    onChange={(event) =>
                      this.handleFieldUpdate("prices.USD", event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <label>Mark Project as a Freebie</label>
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate("isFreebie", event.target.value)
                    }
                    value={formData.isFreebie}
                  >
                    <option value={"false"}>No</option>
                    <option value={"true"}>Yes</option>
                  </select>
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Project Document URL"
                    value={formData.projectURL}
                    onChange={(event) =>
                      this.handleFieldUpdate("projectURL", event.target.value)
                    }
                  />
                </div>
              </div>

              <hr />

              <div className="content-context">
                <div className="content-context-trigger">
                  <button
                    type="button"
                    className="button button-primary"
                    onClick={() => this.handleFormSubmit()}
                  >
                    Edit Project
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditProjectPage));
