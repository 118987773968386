import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutAction } from '../../actions/authActions';
import { NavigationPaths } from '../../common/Environment';

import styles from './Header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleUserLogout = () => {
    this.props.logoutAction();
  };

  render() {
    return (
      <header className="page-header" style={styles}>
        <div className="page-header-left">
          <Link to={NavigationPaths.STATSPAGE}>
            <div className="page-branding d-flex align-items-center justify-content-start">
              <img
                src={require('../../images/logo.svg')}
                className="page-branding-logo"
                alt="SKIP"
              />
              <h2 className="page-branding-text">SKIP</h2>
            </div>
          </Link>
        </div>
        {!this.props.hideLogout && (
          <div className="page-header-right">
            <button
              type="button"
              className="button button-link"
              onClick={() => this.handleUserLogout()}
            >
              Log Out
            </button>
          </div>
        )}
      </header>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  logoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
