import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import get from 'lodash/get';
import Header from '../../components/Header';
import ClassService from '../../services/classService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './EditClassPage.scss';

class EditClassPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      formData: {
        class: '',
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.classService = new ClassService();
  }

  componentDidMount() {
    this.getClassByID();
  }

  getClassByID = () => {
    this.setState({ loader: true });

    const dataPayload = {
      classID: this.props.match.params.id,
    };

    this.classService
      .getClassByID(dataPayload)
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData.data) {
          this.setState((state) => ({
            ...state,
            formData: {
              ...state.formData,
              class: get(responseData.data, 'class', ''),
            },
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH DATA', errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.class === '') {
      errorMessagesArr.push('Class is a required field.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form

      const { formData } = this.state;

      const dataPayload = {
        class: formData.class,
        classID: this.props.match.params.id
      };

      this.classService
        .editClass(dataPayload)
        .then((responseData) => {
          if (responseData) {
            this.props.history.push(NavigationPaths.CLASSPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });
        });
    }
  };

  render() {
    const {
      formData,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Edit Class</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="editclass-container">
          <div className="editclass-container-wrapper">
            <h1 className="editclass-container-title">Edit Class</h1>

            <div className="editclass-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Class Name"
                  value={formData.class}
                  onChange={(event) =>
                    this.handleFieldUpdate('class', event.target.value)
                  }
                />
              </div>
              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Edit Class
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(EditClassPage));
