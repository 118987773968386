import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Header from '../../components/Header';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import AdminService from '../../services/adminService';

import styles from './StatsPage.scss';
import { NavigationPaths } from '../../common/Environment';

class StatsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: true,
      analytics: null,
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.adminService
      .getDashboardAnalytics()
      .then((responseData) => {
        if (responseData.data) {
          this.setState({ loader: false, analytics: responseData.data });
        }
      })
      .catch((errorData) => {
        console.log(errorData);
        this.setState({ loader: false });
      });
  }

  handleNavigation = (page) => {
    this.props.history.push(page);
  };

  render() {
    const { analytics } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Application Statistics</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="stats-container">
          {analytics && (
            <div className="stats-container-wrapper">
              <h1 className="stats-container-title">Application Statistics</h1>

              <div className="row">
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.WALKTHROUGHPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.walkthroughsCount}
                    </h4>
                    <p className="stats-pod-param">Walkthroughs</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.WORKSHEETPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.worksheetsCount}
                    </h4>
                    <p className="stats-pod-param">Worksheets</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.PROJECTPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.projectsCount}
                    </h4>
                    <p className="stats-pod-param">Projects</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.ACTIVITYPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.activitiesCount}
                    </h4>
                    <p className="stats-pod-param">Activities</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="stats-pod">
                    <h4 className="stats-pod-value">
                      {analytics.questionsCount}
                    </h4>
                    <p className="stats-pod-param">Questions</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.USERPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">{analytics.usersCount}</h4>
                    <p className="stats-pod-param">Customers</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="stats-pod">
                    <h4 className="stats-pod-value">{analytics.adminsCount}</h4>
                    <p className="stats-pod-param">Administrators</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.AGENTPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.salesAgentsCount}
                    </h4>
                    <p className="stats-pod-param">Agents</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.CLASSPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.classesCount}
                    </h4>
                    <p className="stats-pod-param">Classes</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.SUBJECTPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.subjectsCount}
                    </h4>
                    <p className="stats-pod-param">Subjects</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="stats-pod">
                    <h4 className="stats-pod-value">
                      {analytics.chaptersCount}
                    </h4>
                    <p className="stats-pod-param">Chapters</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.USERPROJECTPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.userProjectsCount}
                    </h4>
                    <p className="stats-pod-param">Project Submissions</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.COUPONPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.couponsCount}
                    </h4>
                    <p className="stats-pod-param">Coupons</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div
                    className="stats-pod"
                    onClick={() =>
                      this.handleNavigation(NavigationPaths.PACKAGEPAGE)
                    }
                  >
                    <h4 className="stats-pod-value">
                      {analytics.packagesCount}
                    </h4>
                    <p className="stats-pod-param">Packages</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(StatsPage));
