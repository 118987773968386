import moment from 'moment';

export const tableDataParser = (responseData, columnCount) => {
  if (responseData && responseData.length !== 0) {
    let tableDataArr = [];

    for (let rowIndex = 0; rowIndex < responseData.length; rowIndex++) {
      let currentRowArr = [];
      currentRowArr.push(`${rowIndex + 1}.`);
      currentRowArr.push(responseData[rowIndex]._id);
      currentRowArr.push(responseData[rowIndex].coupon);
      currentRowArr.push(responseData[rowIndex].isAgent ? 'Yes' : 'No');
      currentRowArr.push(responseData[rowIndex].isPublic ? 'Yes' : 'No');
      currentRowArr.push(responseData[rowIndex].isUsed ? 'Yes' : 'No');
      currentRowArr.push(
        responseData[rowIndex].isAgent || responseData[rowIndex].isDiscountTypeFlat
          ? `Rs. ${responseData[rowIndex].maxDiscount}`
          : `${responseData[rowIndex].maxDiscount}%`
      );
      currentRowArr.push(responseData[rowIndex].minOrderAmountINR || 'N/A');
      currentRowArr.push(
        responseData[rowIndex].expiry
          ? moment(responseData[rowIndex].expiry).format('DD/MM/YY')
          : 'N/A'
      );
      currentRowArr.push(responseData[rowIndex].active ? 'Active' : 'Inactive');
      currentRowArr.push(
        moment(responseData[rowIndex].createdAt).format('DD/MM/YY hh:mm A')
      );

      tableDataArr.push(currentRowArr);
    }

    return tableDataArr;
  } else {
    return [];
  }
};
