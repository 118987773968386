import React from 'react';
import DatePicker from 'react-datepicker';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import PageLoader from '../../components/PageLoader';
import CouponService from '../../services/couponService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './AddCouponPage.scss';

class AddCouponPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      formData: {
        coupon: '',
        isAgent: false,
        isPublic: 'true',
        userID: '',
        minOrderAmountINR: null,
        minOrderAmountUSD: null,
        maxDiscount: null,
        category: 'Package',
        expiry: null,
        isDiscountTypeFlat: 'false'
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.couponService = new CouponService();
  }

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.coupon === '') {
      errorMessagesArr.push('Coupon is a required field.');
      isFormValid = false;
    }

    if (formData.isPublic === 'false' && formData.userID === '') {
      errorMessagesArr.push('User ID is a required field.');
      isFormValid = false;
    }

    if (!formData.minOrderAmountINR) {
      errorMessagesArr.push('Min Order INR Amount is a required.');
      isFormValid = false;
    }

    if (!formData.minOrderAmountUSD) {
      errorMessagesArr.push('Min Order USD Amount is a required.');
      isFormValid = false;
    }

    if (!formData.maxDiscount) {
      errorMessagesArr.push('Max Discount is a required field.');
      isFormValid = false;
    }

    if (!formData.expiry) {
      errorMessagesArr.push('Expiry is a required field.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      const dataPayload = {
        ...formData,
        isAllApplicable: false,
        coupon: formData.coupon.toUpperCase(),
        maxDiscount: parseInt(formData.maxDiscount),
        isPublic: formData.isPublic === 'true' ? true : false,
        minOrderAmountINR: parseInt(formData.minOrderAmountINR),
        minOrderAmountUSD: parseInt(formData.minOrderAmountUSD),
        isDiscountTypeFlat: formData.isDiscountTypeFlat === 'true' ? true : false
      };

      this.couponService
        .addCoupon(dataPayload)
        .then((responseData) => {
          console.log('SUCCESSFULLY ADDED NEW COUPON', responseData);

          if (responseData) {
            this.props.history.push(NavigationPaths.COUPONPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          console.log('UNABLE TO ADD NEW COUPON', errorData);
        });
    }
  };

  render() {
    const {
      loader,
      formData,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Add New Coupon</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addcoupon-container">
          <div className="addcoupon-container-wrapper">
            <h1 className="addcoupon-container-title">Add New Coupon</h1>

            <div className="addcoupon-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Coupon"
                  value={formData.coupon}
                  style={{ textTransform: 'uppercase' }}
                  onChange={(event) => this.handleFieldUpdate('coupon', event.target.value)}
                />
              </div>

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  Is this coupon applicable to all the users?
                </label>
                <select
                  className="input-select"
                  onChange={(event) => this.handleFieldUpdate('isPublic', event.target.value)}
                  value={formData.isPublic}
                >
                  <option value={'true'}>Yes</option>
                  <option value={'false'}>No</option>
                </select>
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="User ID"
                  value={formData.userID}
                  disabled={formData.isPublic === 'true'}
                  onChange={(event) => this.handleFieldUpdate('userID', event.target.value)}
                />
              </div>

              <div className="input-wrapper">
                <input
                  type="number"
                  className="input-field"
                  value={formData.maxDiscount}
                  placeholder="Max Discount Percentage/Value"
                  onChange={(event) => this.handleFieldUpdate('maxDiscount', event.target.value)}
                />
              </div>

              <div className="input-wrapper">
                <input
                  type="number"
                  className="input-field"
                  placeholder="Minimum Order Amount in INR"
                  value={formData.minOrderAmountINR}
                  onChange={(event) =>
                    this.handleFieldUpdate('minOrderAmountINR', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <input
                  type="number"
                  className="input-field"
                  placeholder="Minimum Order Amount in USD"
                  value={formData.minOrderAmountUSD}
                  onChange={(event) =>
                    this.handleFieldUpdate('minOrderAmountUSD', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  minDate={new Date()}
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  className="input-field"
                  value={formData.expiry}
                  selected={formData.expiry}
                  placeholderText="Expiry Date"
                  onChange={(date) => this.handleFieldUpdate('expiry', date)}
                />
              </div>

              <div className="input-wrapper">
                <label className="input-wrapper-label">
                  Select a discount type for this coupon
                </label>
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('isDiscountTypeFlat', event.target.value)
                  }
                  value={formData.isDiscountTypeFlat}
                >
                  <option value={'true'}>Flat Discount</option>
                  <option value={'false'}>Percentage Discount</option>
                </select>
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Add New Coupon
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withAuthentication(AddCouponPage));
