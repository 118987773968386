import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { tableDataParser } from './dataParser';
import { NavigationPaths } from '../../common/Environment';

import Table from '../../components/Table';
import Header from '../../components/Header';
import LmsService from '../../services/lmsService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './BannersPage.scss';

class BannersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: ['S. No.', 'Database ID', 'Banner', 'Status', 'Created On'],
      tableContent: [],
    };

    this.lmsService = new LmsService();
  }

  componentDidMount() {
    this.getBanners();
  }

  getBanners = () => {
    this.setState({ loader: true });

    this.lmsService
      .getBanners()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  handleToggle = (bannerID) => {
    if (!bannerID) {
      return;
    }

    const dataPayload = {
      bannerID,
    };

    this.lmsService
      .toggleBanners(dataPayload)
      .then(() => {
        this.getBanners();
      })
      .catch((errorData) => {});
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Banners</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="banners-container">
          <div className="banners-container-wrapper">
            <div className="banners-container-header d-flex align-items-center justify-content-between">
              <h1 className="banners-container-title">Banners</h1>
              <Link
                to={NavigationPaths.ADDBANNERSPAGE}
                className="button button-primary"
              >
                Add Banners
              </Link>
            </div>

            <Table
              hasActions={true}
              hasDisable={true}
              loaderState={loader}
              headerData={tableHeaders}
              tableData={tableContent}
              disablePath={this.handleToggle}
              editPath={NavigationPaths.EDITBANNERSPAGE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(BannersPage));
