import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import UserService from '../../services/userService';
import ClassService from '../../services/classService';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import SideNavigation from '../../components/SideNavigation';

import styles from './AddUserPage.scss';

class AddUserPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      classesArr: [],
      formData: {
        classID: '',
        fullName: '',
        emailAddress: '',
        countryCode: '91',
        mobileNumber: '',
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.userService = new UserService();
    this.classService = new ClassService();
  }

  componentDidMount() {
    this.fetchAllClasses();
  }

  fetchAllClasses = () => {
    this.setState({ loader: true });

    this.classService
      .getAllClasses()
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            classesArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH CLASSES', errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.classID === '') {
      errorMessagesArr.push('Class is a required field.');
      isFormValid = false;
    }

    if (formData.fullName === '') {
      errorMessagesArr.push('Full name is a required field.');
      isFormValid = false;
    }

    if (formData.emailAddress === '') {
      errorMessagesArr.push('Email address is a required field.');
      isFormValid = false;
    }

    if (formData.countryCode === '') {
      errorMessagesArr.push('Country code is a required field.');
      isFormValid = false;
    }

    if (formData.mobileNumber === '') {
      errorMessagesArr.push('Mobile number is a required field.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form

      const { formData } = this.state;

      const dataPayload = {
        classID: formData.classID,
        countryCode: formData.countryCode,
        mobileNumber: formData.mobileNumber,
        name: formData.fullName,
        email: formData.emailAddress,
      };

      this.userService
        .addUser(dataPayload)
        .then((responseData) => {
          console.log('SUCCESSFULLY ADDED NEW USER', responseData);

          if (responseData) {
            this.props.history.push(NavigationPaths.USERPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          console.log('UNABLE TO ADD NEW USER', errorData);
        });
    }
  };

  render() {
    const {
      formData,
      classesArr,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className='page-container' style={styles}>
        <Helmet>
          <title>SKIP - Add New User</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className='adduser-container'>
          <div className='adduser-container-wrapper'>
            <h1 className='adduser-container-title'>Add New User</h1>

            <div className='adduser-container-content'>
              {showValidationErrors && (
                <div className='error-wrapper'>
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className='error-wrapper'>
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className='input-wrapper'>
                <select
                  className='input-select'
                  onChange={(event) =>
                    this.handleFieldUpdate('classID', event.target.value)
                  }
                >
                  <option value=''>Choose a Class</option>

                  {classesArr.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.class}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Full Name'
                  value={formData.fullName}
                  onChange={(event) =>
                    this.handleFieldUpdate('fullName', event.target.value)
                  }
                />
              </div>
              <div className='input-wrapper'>
                <input
                  type='text'
                  className='input-field'
                  placeholder='Email Address'
                  value={formData.emailAddress}
                  onChange={(event) =>
                    this.handleFieldUpdate('emailAddress', event.target.value)
                  }
                />
              </div>
              <div className='input-wrapper input-mobile'>
                <select
                  className='input-select'
                  onChange={(event) =>
                    this.handleFieldUpdate('countryCode', event.target.value)
                  }
                >
                  <option value={91}>+91</option>
                </select>

                <input
                  type='text'
                  className='input-field'
                  placeholder='Mobile Number'
                  value={formData.mobileNumber}
                  onChange={(event) =>
                    this.handleFieldUpdate('mobileNumber', event.target.value)
                  }
                />
              </div>
              <div className='input-wrapper'>
                <button
                  type='button'
                  className='button button-primary'
                  onClick={() => this.handleFormSubmit()}
                >
                  Add New User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AddUserPage));
