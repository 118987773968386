import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { tableDataParser } from './dataParser';
import { NavigationPaths } from '../../common/Environment';

import Table from '../../components/Table';
import Header from '../../components/Header';
import AdminService from '../../services/adminService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './AdminPage.scss';

class AdminPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: [
        'S. No.',
        'Database ID',
        'Full Name',
        'Email Address',
        'Mobile No.',
        'Account Status',
      ],
      tableContent: [],
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.getAdmins();
  }

  getAdmins = () => {
    this.setState({ loader: true });

    this.adminService
      .getAdmins()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        console.log('ERROR: Unable to Fetch Users', errorData);
      });
  };

  handleToggle = (adminID) => {
    if (!adminID) {
      return;
    }

    const dataPayload = {
      adminID,
    };

    this.adminService
      .toggleAdmin(dataPayload)
      .then(() => {
        this.getAdmins();
      })
      .catch((errorData) => {
        console.log('ERROR: Unable to toggle Admin', errorData);
      });
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Administrators</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="admins-container">
          <div className="admins-container-wrapper">
            <div className="admins-container-header d-flex align-items-center justify-content-between">
              <h1 className="admins-container-title">Administrators</h1>
              <Link
                to={NavigationPaths.ADDADMINPAGE}
                className="button button-primary"
              >
                Add Admin
              </Link>
            </div>

            <Table
              hasActions={true}
              hasDisable={true}
              loaderState={loader}
              tableData={tableContent}
              headerData={tableHeaders}
              disablePath={this.handleToggle}
              editPath={NavigationPaths.EDITADMINPAGE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AdminPage));
