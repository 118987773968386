import React from 'react';
import { Link } from 'react-router-dom';
import { Table as ReactTable } from 'react-bootstrap';

import DeleteConfirmation from '../DeleteConfirmation';

import styles from './Table.scss';

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowIdentifier: null,
      showDeleteConfirmation: false,
    };
  }

  getRenderingTableData = () => {
    const { headerData, tableData, hasActions, editPath, hasDisable } = this.props;

    if (
      tableData === undefined ||
      tableData === null ||
      typeof tableData !== 'object' ||
      tableData.length === 0
    ) {
      return (
        <tr className="text-center">
          <td colSpan={headerData.length}>No Records Available!</td>
        </tr>
      );
    }

    let tableDataArr = [];
    for (let rowIndex = 0; rowIndex < tableData.length; rowIndex++) {
      let columnDataArr = [];

      for (let columnIndex = 0; columnIndex < tableData[rowIndex].length; columnIndex++) {
        let currentData = tableData[rowIndex][columnIndex];

        if (currentData && typeof currentData === 'string') {
          if (currentData.length !== 0 && currentData.length > 35) {
            currentData = `${currentData.substr(0, 35)}...`;
          }
        }

        columnDataArr.push(<td key={columnIndex}>{currentData}</td>);
      }

      if (hasActions) {
        if (hasDisable) {
          columnDataArr.push(
            <td key={`TABLE_ACTIONS_${rowIndex}`}>
              <div className="d-flex align-items-center justify-content-start">
                <Link
                  to={editPath.replace(':id', tableData[rowIndex][1])}
                  className="button button-link"
                >
                  Edit
                </Link>
                <span className="vertical-separator" />
                <button
                  type="button"
                  className="button button-link"
                  onClick={() => this.toggleConfirmation(tableData[rowIndex][1])}
                >
                  Toggle
                </button>
              </div>
            </td>
          );
        } else {
          columnDataArr.push(
            <td key={`TABLE_ACTIONS_${rowIndex}`}>
              <Link
                to={editPath.replace(':id', tableData[rowIndex][1])}
                className="button button-link"
              >
                Edit
              </Link>
            </td>
          );
        }
      }

      if (columnDataArr.length !== 0) {
        tableDataArr.push(<tr key={rowIndex}>{columnDataArr}</tr>);
      }
    }

    return tableDataArr;
  };

  toggleConfirmation = (rowIdentifier = null) => {
    this.setState((state) => ({
      ...state,
      rowIdentifier,
      showDeleteConfirmation: !state.showDeleteConfirmation,
    }));
  };

  confirmToggleHandler = () => {
    const { disablePath } = this.props;
    const { rowIdentifier } = this.state;

    if (disablePath) {
      disablePath(rowIdentifier);
      this.toggleConfirmation(null);
    }
  };

  render() {
    const { showDeleteConfirmation } = this.state;
    const { headerData, loaderState, hasActions } = this.props;

    return (
      <div className="dynamic-table position-relative" style={styles}>
        <ReactTable>
          {headerData !== undefined &&
            headerData !== null &&
            typeof headerData === 'object' &&
            headerData.length !== 0 && (
              <thead>
                <tr>
                  {headerData.map((item, index) => {
                    return <td key={index}>{item}</td>;
                  })}
                  {hasActions && <td>Actions</td>}
                </tr>
              </thead>
            )}
          <tbody>{this.getRenderingTableData()}</tbody>
        </ReactTable>

        {loaderState && (
          <div className="table-loader d-flex align-items-center justify-content-center">
            <div className="table-loader-wrapper">
              <img
                src={require('../../images/loader.gif')}
                className="table-loader-image"
                alt="Loading"
              />
              <h5 className="table-loader-text">Loading...</h5>
            </div>
          </div>
        )}

        {showDeleteConfirmation && (
          <DeleteConfirmation
            resetHandler={this.toggleConfirmation}
            actionHandler={this.confirmToggleHandler}
            description={this.props.toggleDescription}
          />
        )}
      </div>
    );
  }
}

export default Table;
