import React from "react";

import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { tableDataParser } from "./dataParser";
import { AGENT_FORM_URL, NavigationPaths } from "../../common/Environment";

import Table from "../../components/Table";
import Header from "../../components/Header";
import AgentService from "../../services/agentService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";

import styles from "./AgentPage.scss";

class AgentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: [
        "S. No.",
        "Database ID",
        "Name",
        "Email",
        "Mobile Number",
        "Code",
        "Status",
        "Created On",
      ],
      tableContent: [],
    };

    this.agentService = new AgentService();
  }

  componentDidMount() {
    this.getAgents();
  }

  getAgents = () => {
    this.setState({ loader: true });

    this.agentService
      .getAgents()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        console.log("ERROR: Unable to Fetch Agents", errorData);
      });
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Sales Agents</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="agent-container">
          <div className="agent-container-wrapper">
            <div className="agent-container-header d-flex align-items-center justify-content-between">
              <h1 className="agent-container-title">Sales Agents</h1>
              <a
                target="_blank"
                href={AGENT_FORM_URL}
                rel="noopener noreferrer"
                className="button button-primary"
              >
                Agent Form
              </a>
            </div>

            <Table
              hasActions={true}
              hasDisable={false}
              loaderState={loader}
              headerData={tableHeaders}
              tableData={tableContent}
              editPath={NavigationPaths.EDITAGENTPAGE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AgentPage));
