import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { tableDataParser } from './dataParser';
import { NavigationPaths } from '../../common/Environment';

import Table from '../../components/Table';
import Header from '../../components/Header';
import CouponService from '../../services/couponService';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import SideNavigation from '../../components/SideNavigation';

import styles from './CouponPage.scss';

class CouponPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: [
        'S. No.',
        'Database ID',
        'Coupon',
        'Agent',
        'Public',
        'Used',
        'Discount',
        'MOA',
        'Expiry',
        'Active',
        'Created On',
      ],
      tableContent: [],
    };

    this.couponService = new CouponService();
  }

  componentDidMount() {
    this.getCoupons();
  }

  getCoupons = () => {
    this.setState({ loader: true });

    this.couponService
      .getCoupons()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        console.log('ERROR: Unable to Fetch Coupons', errorData);
      });
  };

  handleCouponToggle = (couponID) => {
    if (!couponID) {
      return;
    }

    const dataPayload = {
      couponID,
    };

    this.couponService
      .toggleCoupon(dataPayload)
      .then(() => {
        this.getCoupons();
      })
      .catch((errorData) => {
        console.log('ERROR: Unable to toggle COUPON', errorData);
      });
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Coupons</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="coupon-container">
          <div className="coupon-container-wrapper">
            <div className="coupon-container-header d-flex align-items-center justify-content-between">
              <h1 className="coupon-container-title">Coupons</h1>
              <Link to={NavigationPaths.ADDCOUPONPAGE} className="button button-primary">
                Add Coupon
              </Link>
            </div>

            <Table
              hasActions={true}
              hasDisable={true}
              loaderState={loader}
              headerData={tableHeaders}
              tableData={tableContent}
              disablePath={this.handleCouponToggle}
              editPath={NavigationPaths.EDITCOUPONPAGE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withAuthentication(CouponPage));
