import React from "react";

import { get, has } from "lodash";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { tableDataParser } from "./dataParser";
import { Subjects } from "../../common/Constants";
import { NavigationPaths } from "../../common/Environment";

import Table from "../../components/Table";
import Header from "../../components/Header";
import ContentService from "../../services/contentService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";

import styles from "./ActivityPage.scss";

class ActivityPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: [
        "S. No.",
        "ID",
        "Title",
        "Lesson",
        "Section",
        "Subject",
        "Class",
        "Freebie",
        "Status",
        "Created On",
      ],
      tableContent: [],
    };

    this.contentService = new ContentService();
  }

  componentDidMount() {
    this.getAllActivities();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      get(prevProps, "match.params.subject") !==
      get(this.props, "match.params.subject")
    ) {
      this.getAllActivities();
    }
  }

  getAllActivities = () => {
    let criteria = { resourceType: "Activity" };

    const subject = get(this.props.match.params, "subject", null);
    if (subject && has(Subjects, subject.toUpperCase())) {
      criteria.subject = subject;
    }

    this.setState({ loader: true });

    this.contentService
      .getContent(criteria)
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
        console.log("ERROR: Unable to Fetch Activities", errorData);
      });
  };

  handleResourceToggle = (resourceID) => {
    if (!resourceID) {
      return;
    }

    this.setState({ loader: true });

    const dataPayload = {
      resourceID,
      resourceType: "Activity",
    };

    this.contentService
      .toggleContent(dataPayload)
      .then(() => {
        this.getAllActivities();
      })
      .catch((errorData) => {
        this.setState({ loader: false });
        console.log("ERROR: Unable to toggle RESOURCE", errorData);
      });
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Activities</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="activity-container">
          <div className="activity-container-wrapper">
            <div className="activity-container-header d-flex align-items-center justify-content-between">
              <h1 className="activity-container-title">Activities</h1>
              <Link
                to={NavigationPaths.ADDACTIVITYPAGE}
                className="button button-primary"
              >
                Add Activity
              </Link>
            </div>

            <Table
              hasActions={true}
              hasDisable={true}
              loaderState={loader}
              headerData={tableHeaders}
              tableData={tableContent}
              disablePath={this.handleResourceToggle}
              editPath={NavigationPaths.EDITACTIVITYPAGE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(ActivityPage));
