import React from 'react';
import { set, cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  QuestionSubTypes,
  QuestionTypes,
  QuestionSubTypePayload,
  Subjects,
} from '../../common/Constants';
import { switchQuestionPositions } from '../../common/Helpers';

import Header from '../../components/Header';
import SubjectService from '../../services/subjectService';
import ClassService from '../../services/classService';
import ContentService from '../../services/contentService';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';
import SideNavigation from '../../components/SideNavigation';
import ImageUploader from '../../components/ImageUploader';
import PageLoader from '../../components/PageLoader';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import QuestionPods from '../../components/QuestionPods';

import styles from './AddWorksheetPage.scss';

class AddWorksheetPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      classesArr: [],
      subjectsArr: [],
      chaptersArr: [],
      selectedQuestionType: '',
      selectedQuestionSubType: '',
      positionSwitchMap: {},
      markedForDeletion: [],
      formData: {
        subjectID: '',
        classID: '',
        resourceType: 'Worksheet',
        difficulty: '',
        chapterID: '',
        questions: [],
        mediaDirectory: uuid(),
        isFreebie: 'false',
        thumbnail: '',
        title: '',
        description: { texts: [''] },
        isBest: 'false'
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
      showDeleteConfirmation: false,
      questionIdentifier: null,
    };

    this.subjectService = new SubjectService();
    this.classService = new ClassService();
    this.contentServive = new ContentService();
  }

  componentDidMount() {
    this.fetchAllClasses();
  }

  fetchAllClasses = () => {
    this.setState({ loader: true });

    this.classService
      .getAllClasses()
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            classesArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH CLASSES', errorData);
      });
  };

  getSubjectByClass = () => {
    const { classID } = this.state.formData;

    if (!classID) {
      return;
    }

    this.setState({ loader: true });

    this.subjectService
      .getSubjectsByClass({ classID })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            subjectsArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH SUBJECTS', errorData);
      });
  };

  getChaptersBySubject = () => {
    const { subjectID } = this.state.formData;

    if (!subjectID) {
      return;
    }

    this.setState({ loader: true });

    this.subjectService
      .getChapters({ subjectID })
      .then((responseData) => {
        this.setState({ loader: false });

        if (responseData) {
          this.setState((state) => ({
            ...state,
            chaptersArr: responseData.data,
          }));
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });

        console.log('UNABLE TO FETCH CHAPTERS', errorData);
      });
  };

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.resourceType === '') {
      errorMessagesArr.push('Resource Type is a required field.');
      isFormValid = false;
    }

    if (formData.classID === '') {
      errorMessagesArr.push('Class is a required field.');
      isFormValid = false;
    }

    if (formData.subjectID === '') {
      errorMessagesArr.push('Subject is a required field.');
      isFormValid = false;
    }

    if (formData.chapterID === '') {
      errorMessagesArr.push('Selection of Lesson is a required.');
      isFormValid = false;
    }

    if (formData.difficulty === '') {
      errorMessagesArr.push('Worksheet Difficulty is a required field.');
      isFormValid = false;
    }

    if (formData.title === '') {
      errorMessagesArr.push('Worksheet Title is a required field.');
      isFormValid = false;
    }

    if (!formData.description.texts.length) {
      errorMessagesArr.push('Worksheet Description is a required.');
      isFormValid = false;
    } else {
      for (let d of formData.description.texts) {
        if (!d || d === '') {
          errorMessagesArr.push('Worksheet Description cannot be empty.');
          isFormValid = false;
          break;
        }
      }
    }

    if (formData.thumbnail === '') {
      errorMessagesArr.push('Worksheet Thumbnail URL is a required field.');
      isFormValid = false;
    }

    if (formData.questions.length === 0) {
      errorMessagesArr.push('Questions cannot be less than 1.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    let formData = Object.assign({}, this.state.formData);

    set(formData, fieldName, fieldValue);

    this.setState(
      (state) => ({
        ...state,
        formData,
      }),
      () => {
        if (fieldName === 'classID') {
          this.setState((state) => ({
            ...state,
            formData: { ...state.formData, subjectID: '' },
          }));
          this.getSubjectByClass();
        } else if (fieldName === 'subjectID') {
          this.setState((state) => ({
            ...state,
            formData: { ...state.formData, chapterID: '' },
          }));
          this.getChaptersBySubject();
        }
      }
    );
  };

  handleStateUpdate = (fieldName, fieldValue) => {
    this.setState(
      (state) => ({
        ...state,
        [`${fieldName}`]: fieldValue,
      }),
      () => {
        if (fieldName === 'selectedQuestionType') {
          if (
            fieldValue === QuestionTypes.DSC ||
            fieldValue === QuestionTypes.EMPTY
          ) {
            this.setState({
              selectedQuestionSubType: QuestionSubTypes[fieldValue],
            });
          } else {
            if (
              this.state.selectedQuestionSubType ===
              QuestionSubTypes[fieldValue]
            ) {
              this.setState({ selectedQuestionSubType: '' });
            }
          }
        }
      }
    );
  };

  addQuestionHandler = () => {
    const { selectedQuestionSubType, selectedQuestionType } = this.state;

    if (selectedQuestionType && selectedQuestionSubType) {
      let questionsPayload = cloneDeep(
        QuestionSubTypePayload[selectedQuestionSubType]
      );

      questionsPayload = {
        ...questionsPayload,
        markedForDeletion: false,
        code: `${new Date().getTime()}`,
        questionType: selectedQuestionType,
      };

      this.setState((state) => ({
        ...state,
        selectedQuestionType: '',
        selectedQuestionSubType: '',
        formData: {
          ...state.formData,
          questions: [...state.formData.questions, questionsPayload],
        },
      }));
    }
  };

  removeQuestion = (questionIndex) => {
    this.setState({
      showDeleteConfirmation: true,
      questionIdentifier: questionIndex,
    });
  };

  resetDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
      questionIdentifier: null,
    });
  };

  removeQuestionHandler = () => {
    let questions = Object.assign([], this.state.formData.questions);
    questions[this.state.questionIdentifier].markedForDeletion = true;

    this.setState((state) => ({
      ...state,
      markedForDeletion: [
        ...state.markedForDeletion,
        this.state.questionIdentifier,
      ],
      formData: {
        ...state.formData,
        questions,
      },
    }));

    this.resetDeleteConfirmation();
  };

  handleQuestionUpdate = (questionData, questionIndex) => {
    let updatedQuestions = Object.assign([], this.state.formData.questions);
    updatedQuestions[questionIndex] = {
      ...updatedQuestions[questionIndex],
      ...questionData,
    };

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questions: updatedQuestions,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;
      let updatedQuestions = [];

      for (let question of formData.questions) {
        if (!question.markedForDeletion) {
          updatedQuestions.push(question);
        }
      }

      updatedQuestions = switchQuestionPositions(
        updatedQuestions,
        this.state.positionSwitchMap
      );

      let dataPayload = {
        classID: formData.classID,
        subjectID: formData.subjectID,
        resourceType: formData.resourceType,
        questions: updatedQuestions,
        mediaDirectory: formData.mediaDirectory,
        isFreebie: formData.isFreebie === 'true' ? true : false,
        isBest: formData.isBest === 'true' ? true : false,
        title: formData.title,
        description: formData.description,
        thumbnail: formData.thumbnail,
        chapterID: formData.chapterID,
        difficulty: formData.difficulty,
      };

      this.contentServive
        .addContent(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });
          console.log('SUCCESSFULLY ADDED NEW Content', responseData);
          this.handleNavigation();
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });

          console.log('UNABLE TO ADD NEW CONTENT', errorData);
        });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  addDescriptionRow = () => {
    let description = Object.assign({}, this.state.formData.description);
    description.texts.push('');
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        description,
      },
    }));
  };

  removeDescriptionRow = (descriptionIndex) => {
    let description = Object.assign({}, this.state.formData.description);
    description.texts = description.texts.filter(
      (item, itemIndex) => itemIndex !== descriptionIndex
    );

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        description,
      },
    }));
  };

  handleEmptyQuestionUpdate = (questionData, position) => {
    const { selectedQuestionSubType, selectedQuestionType } = questionData;

    let questionsPayload = cloneDeep(
      QuestionSubTypePayload[selectedQuestionSubType]
    );

    questionsPayload = {
      ...questionsPayload,
      markedForDeletion: false,
      code: `${new Date().getTime()}`,
      questionType: selectedQuestionType,
    };

    const questions = Object.assign([], this.state.formData.questions);
    questions[position] = questionsPayload;

    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        questions,
      },
    }));
  };

  updatePositionMap = (questionCode, switchIndex) => {
    let positionSwitchMap = Object.assign({}, this.state.positionSwitchMap);

    delete positionSwitchMap[questionCode];
    if (switchIndex !== '') {
      positionSwitchMap[questionCode] = switchIndex;
    }

    this.setState((state) => ({
      ...state,
      positionSwitchMap,
    }));
  };

  handleNavigation = () => {
    const { subjectsArr, formData } = this.state;
    const { subjectID } = formData;

    let subject = null;
    if (subjectsArr.length && subjectID) {
      for (let s of subjectsArr) {
        if (s._id === subjectID) {
          subject = s.subject;
          break;
        }
      }
    }

    switch (subject) {
      case Subjects.MATHS:
      case Subjects.ENGLISH:
      case Subjects.SCIENCE:
        return this.props.history.push(
          NavigationPaths.SUBJECTWORKSHEETPAGE.replace(':subject', subject)
        );

      case Subjects.EVS:
        return this.props.history.push(
          NavigationPaths.SUBJECTWORKSHEETPAGE.replace(
            ':subject',
            Subjects.SCIENCE
          )
        );

      default:
        return this.props.history.push(NavigationPaths.WORKSHEETPAGE);
    }
  };

  render() {
    const {
      loader,
      formData,
      classesArr,
      showServerErrors,
      validationErrors,
      positionSwitchMap,
      showValidationErrors,
      subjectsArr,
      chaptersArr,
      selectedQuestionType,
      selectedQuestionSubType,
      showDeleteConfirmation,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Add New Worksheet</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addcontent-container">
          <ImageUploader mediaDirectory={formData.mediaDirectory} />

          <div className="addcontent-container-wrapper">
            <h1 className="addcontent-container-title">Add New Worksheet</h1>

            <div className="addcontent-container-content">
              <div className="addcontent-container-metadata">
                {showValidationErrors && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    {validationErrors.map((item, index) => {
                      return <p key={index}>{item}</p>;
                    })}
                  </div>
                )}

                {showServerErrors !== '' && (
                  <div className="error-wrapper">
                    <h6>Error!</h6>

                    <p>{showServerErrors}</p>
                  </div>
                )}

                <div className="input-wrapper">
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate('classID', event.target.value)
                    }
                    value={formData.classID}
                  >
                    <option value="">Choose a Class</option>

                    {classesArr.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.class}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="input-wrapper">
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate('subjectID', event.target.value)
                    }
                    value={formData.subjectID}
                    disabled={!formData.classID ? true : false}
                  >
                    <option value="" disabled>
                      Choose a Subject
                    </option>

                    {formData.classID &&
                      subjectsArr.map((item, index) => {
                        return (
                          <option key={index} value={item._id}>
                            {item.subject}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="input-wrapper">
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate('chapterID', event.target.value)
                    }
                    value={formData.chapterID}
                    disabled={!formData.subjectID ? true : false}
                  >
                    <option value="" disabled>
                      Choose a Lesson
                    </option>

                    {formData.subjectID &&
                      chaptersArr.map((item, index) => {
                        return (
                          <option key={index} value={item._id}>
                            {item.chapter}{' '}
                            {item?.subjectID?.subject === Subjects.ENGLISH &&
                              ` (${item.englishTag})`}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <hr />

                <h5>Worksheet Details</h5>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Worksheet Title"
                    value={formData.title}
                    onChange={(event) =>
                      this.handleFieldUpdate('title', event.target.value)
                    }
                  />
                </div>

                <div className="walkthrough-description">
                  {formData.description.texts.length !== 0 && (
                    <div className="description-data">
                      <div className="description-replicable">
                        {formData.description.texts.map(
                          (description, descriptionIndex) => {
                            return (
                              <div
                                key={descriptionIndex}
                                className="description-replicable-item d-flex align-items-center justify-content-between"
                              >
                                <div className="input-wrapper">
                                  <input
                                    type="text"
                                    className="input-field"
                                    placeholder={`Worksheet Description ${
                                      descriptionIndex + 1
                                    }`}
                                    value={description}
                                    onChange={(event) =>
                                      this.handleFieldUpdate(
                                        `description.texts[${descriptionIndex}]`,
                                        event.target.value
                                      )
                                    }
                                  />
                                </div>

                                <button
                                  type="button"
                                  className="button button-link"
                                  disabled={
                                    formData.description.texts.length < 2
                                  }
                                  onClick={() =>
                                    this.removeDescriptionRow(descriptionIndex)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                              </div>
                            );
                          }
                        )}

                        <div className="description-replicable-trigger">
                          <button
                            type="button"
                            className="button button-link"
                            onClick={() => this.addDescriptionRow()}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Another Text
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Worksheet Difficulty"
                    value={formData.difficulty}
                    onChange={(event) =>
                      this.handleFieldUpdate('difficulty', event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Worksheet Thumbnail URL"
                    value={formData.thumbnail}
                    onChange={(event) =>
                      this.handleFieldUpdate('thumbnail', event.target.value)
                    }
                  />
                </div>

                <div className="input-wrapper">
                  <label>Mark Worksheet as a Freebie</label>
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate('isFreebie', event.target.value)
                    }
                    value={formData.isFreebie}
                  >
                    <option value={'false'}>No</option>
                    <option value={'true'}>Yes</option>
                  </select>
                </div>

                <div className="input-wrapper">
                  <label>Mark Worksheet as Best</label>
                  <select
                    className="input-select"
                    onChange={(event) =>
                      this.handleFieldUpdate('isBest', event.target.value)
                    }
                    value={formData.isBest}
                  >
                    <option value={'false'}>No</option>
                    <option value={'true'}>Yes</option>
                  </select>
                </div>
              </div>

              <hr />

              <div className="content-context">
                <h5>Worksheet Questions</h5>

                {formData.questions.length !== 0 && (
                  <QuestionPods
                    questions={formData.questions}
                    removeQuestion={this.removeQuestion}
                    positionSwitchMap={positionSwitchMap}
                    updateQuestion={this.handleQuestionUpdate}
                    updatePositionMap={this.updatePositionMap}
                    updateEmptyQuestion={this.handleEmptyQuestionUpdate}
                  />
                )}

                <div className="content-context-actions d-flex align-items-center justify-content-start">
                  <div className="input-wrapper">
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleStateUpdate(
                          'selectedQuestionType',
                          event.target.value
                        )
                      }
                      value={selectedQuestionType}
                    >
                      <option value={''} disabled>
                        Select a Question Type
                      </option>
                      <option value="T">T - Text Only</option>
                      <option value="TWI">TWI - Text With Image</option>
                      <option value="TWV">TWV - Text With Video</option>
                      <option value="TWA">TWA - Text With Audio</option>
                      <option value="DSC">DSC - Description Type</option>
                      <option value="EMPTY">
                        EMPTY - Placeholder Question
                      </option>
                    </select>
                  </div>

                  <div className="input-wrapper">
                    <select
                      className="input-select"
                      onChange={(event) =>
                        this.handleStateUpdate(
                          'selectedQuestionSubType',
                          event.target.value
                        )
                      }
                      value={selectedQuestionSubType}
                      disabled={
                        selectedQuestionType === QuestionTypes.DSC ||
                        selectedQuestionType === QuestionTypes.EMPTY
                      }
                    >
                      <option value={''} disabled>
                        Select a Question Sub Type
                      </option>
                      <option value="SAQ">SAQ - Single Answer Question</option>
                      <option value="MCQ">
                        MCQ - Multiple Choice Question
                      </option>
                      <option value="MCQM">
                        MCQM - Multiple Choice Question With Multiple Answers
                      </option>
                      <option value="LQ">LQ - Labelling Question</option>
                      <option value="MTF">MTF - Match The Following</option>
                      <option value="FITBT">
                        FITBT - Fill In The Blanks - Typeable
                      </option>
                      <option value="FITBS">
                        FITBS - Fill In The Blanks - Selectable
                      </option>
                      <option value="TBLT">
                        TBLT - Table Question - Typeable
                      </option>
                      <option value="TBLS">
                        TBLS - Table Question - Selectable
                      </option>
                      <option value="LAQ">LAQ - Long Answer Question</option>
                      <option value="LAQE">
                        LAQE - Long Answer Question Editable
                      </option>
                      <option value="LAQWL">
                        LAQWL - Long Answer Question Word Limited
                      </option>
                      <option value="LAQAI">
                        LAQAI - Long Answer Question With AI Checking
                      </option>
                      <option value="RQT">RQT - Reading Question With Timer</option>
                      <option value="DSC">DSC - Description Type</option>
                      <option value="EMPTY">
                        EMPTY - Placeholder Question
                      </option>
                    </select>
                  </div>

                  <button
                    type="button"
                    className="button button-link"
                    onClick={this.addQuestionHandler}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </button>
                </div>

                <div className="content-context-trigger">
                  <button
                    type="button"
                    className="button button-primary"
                    onClick={() => this.handleFormSubmit()}
                  >
                    Add Worksheet
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showDeleteConfirmation && (
          <DeleteConfirmation
            actionHandler={this.removeQuestionHandler}
            resetHandler={this.resetDeleteConfirmation}
          />
        )}

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AddWorksheetPage));
