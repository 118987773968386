import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { tableDataParser } from './dataParser';
import { NavigationPaths } from '../../common/Environment';

import Table from '../../components/Table';
import Header from '../../components/Header';
import OrderService from '../../services/orderService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './ProductOrdersPage.scss';

class ProductOrdersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: [
        'S. No.',
        'Database ID',
        'Order ID',
        'Amount',
        'Customer',
        'Status',
        'Created On',
      ],
      tableContent: [],
    };

    this.orderService = new OrderService();
  }

  componentDidMount() {
    this.getProductOrders();
  }

  getProductOrders = () => {
    this.setState({ loader: true });

    this.orderService
      .getProductOrders()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        this.setState({ loader: false });
      });
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Product Orders</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="productorders-container">
          <div className="productorders-container-wrapper">
            <div className="productorders-container-header d-flex align-items-center justify-content-between">
              <h1 className="productorders-container-title">Product Orders</h1>
            </div>

            <Table
              hasActions={true}
              hasDisable={false}
              loaderState={loader}
              headerData={tableHeaders}
              tableData={tableContent}
              editPath={NavigationPaths.EDITPRODUCTORDERSPAGE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(ProductOrdersPage));
