import axiosInstance from './CreateService';
import { ApiConstants } from '../common/Environment';

export default class AgentService {
  getDashboardAnalytics() {
    return axiosInstance.get(ApiConstants.GETDASHBOARDANALYTICS);
  }

  getAdmins() {
    return axiosInstance.get(ApiConstants.GETADMINS);
  }

  getAdminByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETADMINSBYID.replace(':adminID', dataPayload.adminID)
    );
  }

  addAdmin(dataPayload) {
    return axiosInstance.post(ApiConstants.ADDADMINS, dataPayload);
  }

  editAdmin(dataPayload) {
    return axiosInstance.put(ApiConstants.EDITADMINS, dataPayload);
  }

  toggleAdmin(dataPayload) {
    return axiosInstance.put(ApiConstants.TOGGLEADMINS, dataPayload);
  }

  getPermissions() {
    return axiosInstance.get(ApiConstants.GETPERMISSIONS);
  }

  generateReports(dataPayload) {
    return axiosInstance.post(ApiConstants.GENERATEREPORTS, dataPayload);
  }

  config(dataPayload) {
    return axiosInstance.post(ApiConstants.CONFIG, dataPayload);
  }
}
