import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SweetAlert = withReactContent(Swal);

class DeleteConfirmation extends React.Component {
  componentDidMount() {
    SweetAlert.fire({
      title: "Are you sure?",
      text: this.props.description || "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgba(31, 174, 15, 1)",
      cancelButtonColor: "rgba(23, 35, 67, 1)",
      confirmButtonText: "Proceed",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.actionHandler();
      } else {
        this.props.resetHandler();
      }
    });
  }

  render() {
    return null;
  }
}

export default DeleteConfirmation