import axiosInstance from './CreateService';
import { ApiConstants } from '../common/Environment';

export default class LmsService {
  getNews() {
    return axiosInstance.get(ApiConstants.GETNEWS);
  }

  getNewsByID(payload) {
    return axiosInstance.get(
      ApiConstants.GETNEWSBYID.replace(':newsID', payload.newsID)
    );
  }

  addNews(payload) {
    return axiosInstance.post(ApiConstants.ADDNEWS, payload);
  }

  editNews(payload) {
    return axiosInstance.put(ApiConstants.EDITNEWS, payload);
  }

  toggleNews(payload) {
    return axiosInstance.put(ApiConstants.TOGGLENEWS, payload);
  }

  getBanners() {
    return axiosInstance.get(ApiConstants.GETBANNERS);
  }

  getBannersByID(payload) {
    return axiosInstance.get(
      ApiConstants.GETBANNERSBYID.replace(':bannerID', payload.bannerID)
    );
  }

  addBanners(payload) {
    return axiosInstance.post(ApiConstants.ADDBANNERS, payload);
  }

  editBanners(payload) {
    return axiosInstance.put(ApiConstants.EDITBANNERS, payload);
  }

  toggleBanners(payload) {
    return axiosInstance.put(ApiConstants.TOGGLEBANNERS, payload);
  }

  addAdvertisements(payload) {
    return axiosInstance.post(ApiConstants.ADDADVERTISEMENTS, payload);
  }

  getAdvertisements() {
    return axiosInstance.get(ApiConstants.GETADVERTISEMENTS);
  }

  editAdvertisements(payload) {
    return axiosInstance.put(ApiConstants.EDITADVERTISEMENTS, payload);
  }

  toggleAdvertisements(payload) {
    return axiosInstance.put(ApiConstants.TOGGLEADVERTISEMENTS, payload);
  }

  getAdvertisementsByID(payload) {
    return axiosInstance.get(
      ApiConstants.GETADVERTISEMENTSBYID.replace(
        ':advertisementID',
        payload.advertisementID
      )
    );
  }

  addSessions(payload) {
    return axiosInstance.post(ApiConstants.ADDSESSIONS, payload);
  }

  getSessions() {
    return axiosInstance.get(ApiConstants.GETSESSIONS);
  }

  editSessions(payload) {
    return axiosInstance.put(ApiConstants.EDITSESSIONS, payload);
  }

  toggleSessions(payload) {
    return axiosInstance.put(ApiConstants.TOGGLESESSIONS, payload);
  }

  getSessionsByID(payload) {
    return axiosInstance.get(
      ApiConstants.GETSESSIONSBYID.replace(':sessionID', payload.sessionID)
    );
  }

  addNewsletters(payload) {
    return axiosInstance.post(ApiConstants.ADDNEWSLETTERS, payload);
  }

  getNewsletters() {
    return axiosInstance.get(ApiConstants.GETNEWSLETTERS);
  }

  editNewsletters(payload) {
    return axiosInstance.put(ApiConstants.EDITNEWSLETTERS, payload);
  }

  toggleNewsletters(payload) {
    return axiosInstance.put(ApiConstants.TOGGLENEWSLETTERS, payload);
  }

  getNewslettersByID(payload) {
    return axiosInstance.get(
      ApiConstants.GETNEWSLETTERSBYID.replace(
        ':newsletterID',
        payload.newsletterID
      )
    );
  }

  addVideos(payload) {
    return axiosInstance.post(ApiConstants.ADDVIDEOS, payload);
  }

  getVideos() {
    return axiosInstance.get(ApiConstants.GETVIDEOS);
  }

  editVideos(payload) {
    return axiosInstance.put(ApiConstants.EDITVIDEOS, payload);
  }

  toggleVideos(payload) {
    return axiosInstance.put(ApiConstants.TOGGLEVIDEOS, payload);
  }

  getVideosByID(payload) {
    return axiosInstance.get(
      ApiConstants.GETVIDEOSBYID.replace(':videoID', payload.videoID)
    );
  }
}
