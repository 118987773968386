import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NavigationPaths } from '../common/Environment';

import LoginPage from '../containers/LoginPage';
import StatsPage from '../containers/StatsPage';
import UserPage from '../containers/UserPage';
import AddUserPage from '../containers/AddUserPage';
import ClassPage from '../containers/ClassPage';
import AddClassPage from '../containers/AddClassPage';
import SubjectPage from '../containers/SubjectPage';
import AddSubjectPage from '../containers/AddSubjectPage';
import PackagePage from '../containers/PackagePage';
import AddPackagePage from '../containers/AddPackagePage';
import EditPackagePage from '../containers/EditPackagePage';
import AddWalkthroughPage from '../containers/AddWalkthroughPage';
import WalkthroughPage from '../containers/WalkthroughPage';
import EditSubjectPage from '../containers/EditSubjectPage';
import EditWalkthroughPage from '../containers/EditWalkthroughPage';
import WorksheetPage from '../containers/WorksheetPage';
import AddWorksheetPage from '../containers/AddWorksheetPage';
import EditWorksheetPage from '../containers/EditWorksheetPage';
import AddProjectPage from '../containers/AddProjectPage';
import ProjectPage from '../containers/ProjectPage';
import EditProjectPage from '../containers/EditProjectPage';
import AgentPage from '../containers/AgentPage';
import EditAgentPage from '../containers/EditAgentPage';
import ActivityPage from '../containers/ActivityPage';
import AddActivityPage from '../containers/AddActivityPage';
import EditActivityPage from '../containers/EditActivityPage';
import UserProjectPage from '../containers/UserProjectPage';
import EditUserProjectPage from '../containers/EditUserProjectPage';
import EcomOrdersPage from '../containers/EcomOrdersPage';
import EditEcomOrdersPage from '../containers/EditEcomOrdersPage';
import SearchWorksheetPage from '../containers/SearchWorksheetPage';
import ChapterPage from '../containers/ChapterPage';
import EditChapterPage from '../containers/EditChapterPage';
import CouponPage from '../containers/CouponPage';
import AddCouponPage from '../containers/AddCouponPage';
import EditCouponPage from '../containers/EditCouponPage';
import BannersPage from '../containers/BannersPage';
import AdvertisementPage from '../containers/AdvertisementPage';
import AddAdvertisementPage from '../containers/AddAdvertisementPage';
import EditAdvertisementPage from '../containers/EditAdvertisementPage';
import AdminPage from '../containers/AdminPage';
import AddAdminPage from '../containers/AddAdminPage';
import EditAdminPage from '../containers/EditAdminPage';
import PackageOrdersPage from '../containers/PackageOrdersPage';
import EditPackageOrdersPage from '../containers/EditPackageOrdersPage';
import ProductOrdersPage from '../containers/ProductOrdersPage';
import EditProductOrdersPage from '../containers/EditProductOrdersPage';
import AddNewsPage from '../containers/AddNewsPage';
import NewsPage from '../containers/NewsPage';
import EditNewsPage from '../containers/EditNewsPage';
import AddBannersPage from '../containers/AddBannersPage';
import EditBannersPage from '../containers/EditBannersPage';
import SessionPage from '../containers/SessionPage';
import AddSessionPage from '../containers/AddSessionPage';
import EditSessionPage from '../containers/EditSessionPage';
import UnauthorizedPage from '../containers/UnauthorizedPage';
import PayoutsPage from '../containers/PayoutsPage';
import EditPayoutPage from '../containers/EditPayoutPage';
import ReportsPage from '../containers/ReportsPage';
import NewsletterPage from '../containers/NewsletterPage';
import AddNewsletterPage from '../containers/AddNewsletterPage';
import EditNewsletterPage from '../containers/EditNewsletterPage';
import VideoPage from '../containers/VideoPage';
import AddVideoPage from '../containers/AddVideoPage';
import EditVideoPage from '../containers/EditVideoPage';
import EditClassPage from '../containers/EditClassPage';
import DurationPage from '../containers/DurationPage';
import AddDurationPage from '../containers/AddDurationPage';
import EditDurationPage from '../containers/EditDurationPage';

const Navigator = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={NavigationPaths.LOGIN} component={LoginPage} />
        <Route exact path={NavigationPaths.STATSPAGE} component={StatsPage} />
        <Route exact path={NavigationPaths.USERPAGE} component={UserPage} />
        <Route
          exact
          path={NavigationPaths.ADDUSERPAGE}
          component={AddUserPage}
        />
        <Route exact path={NavigationPaths.CLASSPAGE} component={ClassPage} />
        <Route
          exact
          path={NavigationPaths.ADDCLASSPAGE}
          component={AddClassPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITCLASSPAGE}
          component={EditClassPage}
        />
        <Route
          exact
          path={NavigationPaths.SUBJECTPAGE}
          component={SubjectPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDSUBJECTPAGE}
          component={AddSubjectPage}
        />
        <Route
          exact
          path={NavigationPaths.PACKAGEPAGE}
          component={PackagePage}
        />
        <Route
          exact
          path={NavigationPaths.ADDPACKAGEPAGE}
          component={AddPackagePage}
        />
        <Route
          exact
          path={NavigationPaths.EDITPACKAGEPAGE}
          component={EditPackagePage}
        />
        <Route
          exact
          path={NavigationPaths.WALKTHROUGHPAGE}
          component={WalkthroughPage}
        />
        <Route
          exact
          path={NavigationPaths.SUBJECTWALKTHROUGHPAGE}
          component={WalkthroughPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDWALKTHROUGHPAGE}
          component={AddWalkthroughPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITSUBJECTPAGE}
          component={EditSubjectPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITWALKTHROUGHPAGE}
          component={EditWalkthroughPage}
        />
        <Route
          exact
          path={NavigationPaths.WORKSHEETPAGE}
          component={WorksheetPage}
        />
        <Route
          exact
          path={NavigationPaths.SUBJECTWORKSHEETPAGE}
          component={WorksheetPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDWORKSHEETPAGE}
          component={AddWorksheetPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITWORKSHEETPAGE}
          component={EditWorksheetPage}
        />
        <Route
          exact
          path={NavigationPaths.SEARCHWORKSHEETPAGE}
          component={SearchWorksheetPage}
        />
        <Route
          exact
          path={NavigationPaths.PROJECTPAGE}
          component={ProjectPage}
        />
        <Route
          exact
          path={NavigationPaths.SUBJECTPROJECTPAGE}
          component={ProjectPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDPROJECTPAGE}
          component={AddProjectPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITPROJECTPAGE}
          component={EditProjectPage}
        />
        <Route exact path={NavigationPaths.AGENTPAGE} component={AgentPage} />
        <Route
          exact
          path={NavigationPaths.EDITAGENTPAGE}
          component={EditAgentPage}
        />
        <Route
          exact
          path={NavigationPaths.ACTIVITYPAGE}
          component={ActivityPage}
        />
        <Route
          exact
          path={NavigationPaths.SUBJECTACTIVITYPAGE}
          component={ActivityPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDACTIVITYPAGE}
          component={AddActivityPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITACTIVITYPAGE}
          component={EditActivityPage}
        />
        <Route
          exact
          path={NavigationPaths.USERPROJECTPAGE}
          component={UserProjectPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITUSERPROJECTPAGE}
          component={EditUserProjectPage}
        />
        <Route
          exact
          path={NavigationPaths.ECOMORDERSPAGE}
          component={EcomOrdersPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITECOMORDERSPAGE}
          component={EditEcomOrdersPage}
        />
        <Route
          exact
          path={NavigationPaths.LESSONSPAGE}
          component={ChapterPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITCHAPTERPAGE}
          component={EditChapterPage}
        />
        <Route exact path={NavigationPaths.COUPONPAGE} component={CouponPage} />
        <Route exact path={NavigationPaths.NEWSPAGE} component={NewsPage} />
        <Route
          exact
          path={NavigationPaths.ADDNEWSPAGE}
          component={AddNewsPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITNEWSPAGE}
          component={EditNewsPage}
        />
        <Route
          exact
          path={NavigationPaths.BANNERSPAGE}
          component={BannersPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDBANNERSPAGE}
          component={AddBannersPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITBANNERSPAGE}
          component={EditBannersPage}
        />
        <Route
          exact
          path={NavigationPaths.ADVERTISEMENTSPAGE}
          component={AdvertisementPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDADVERTISEMENTSPAGE}
          component={AddAdvertisementPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITADVERTISEMENTSPAGE}
          component={EditAdvertisementPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDCOUPONPAGE}
          component={AddCouponPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITCOUPONPAGE}
          component={EditCouponPage}
        />
        <Route exact path={NavigationPaths.ADMINPAGE} component={AdminPage} />
        <Route
          exact
          path={NavigationPaths.ADDADMINPAGE}
          component={AddAdminPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITADMINPAGE}
          component={EditAdminPage}
        />
        <Route
          exact
          path={NavigationPaths.PACKAGEORDERSPAGE}
          component={PackageOrdersPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITPACKAGEORDERSPAGE}
          component={EditPackageOrdersPage}
        />
        <Route
          exact
          path={NavigationPaths.PRODUCTORDERSPAGE}
          component={ProductOrdersPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITPRODUCTORDERSPAGE}
          component={EditProductOrdersPage}
        />
        <Route
          exact
          path={NavigationPaths.SESSIONSPAGE}
          component={SessionPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDSESSIONSPAGE}
          component={AddSessionPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITSESSIONSPAGE}
          component={EditSessionPage}
        />
        <Route
          exact
          path={NavigationPaths.UNAUTHORIZEDPAGE}
          component={UnauthorizedPage}
        />
        <Route
          exact
          path={NavigationPaths.PAYOUTPAGE}
          component={PayoutsPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITPAYOUTPAGE}
          component={EditPayoutPage}
        />
        <Route
          exact
          path={NavigationPaths.REPORTSPAGE}
          component={ReportsPage}
        />
        <Route
          exact
          path={NavigationPaths.NEWSLETTERSPAGE}
          component={NewsletterPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDNEWSLETTERSPAGE}
          component={AddNewsletterPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITNEWSLETTERSPAGE}
          component={EditNewsletterPage}
        />
        <Route exact path={NavigationPaths.VIDEOSPAGE} component={VideoPage} />
        <Route
          exact
          path={NavigationPaths.ADDVIDEOSPAGE}
          component={AddVideoPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITVIDEOSPAGE}
          component={EditVideoPage}
        />
        <Route
          exact
          path={NavigationPaths.DURATIONPAGE}
          component={DurationPage}
        />
        <Route
          exact
          path={NavigationPaths.ADDDURATIONPAGE}
          component={AddDurationPage}
        />
        <Route
          exact
          path={NavigationPaths.EDITDURATIONPAGE}
          component={EditDurationPage}
        />
      </Switch>
    </Router>
  );
};

export default Navigator;
