export const loginValidation = (inputs) => {
  const { mobileNumber } = inputs;
  if (!mobileNumber || mobileNumber.length !== 10) {
    return false;
  }

  return true;
};

export const loginPayload = (inputs) => {
    return {
        mobileNumber : inputs.mobileNumber,
        countryCode : inputs.countryCode
    }
}

export const verifyOTPValidation = (inputs) => {
    const { mobileNumber, otp } = inputs;
    if (!mobileNumber || mobileNumber.length !== 10 || !otp || otp.length !== 4) {
      return false;
    }
  
    return true;
  };
  
  export const verifyOTPPayload = (inputs) => {
      return {
          mobileNumber : inputs.mobileNumber,
          countryCode : inputs.countryCode,
          otp : inputs.otp
      }
  }