import {get} from 'lodash';
import moment from 'moment';

export const tableDataParser = (responseData, columnCount) => {
  if (responseData && responseData.length !== 0) {
    let tableDataArr = [];

    for (let rowIndex = 0; rowIndex < responseData.length; rowIndex++) {
      let currentRowArr = [];
      let priceString = "";
      let subjectString = "";
      const packagePrice = responseData[rowIndex].prices;
      const packageSubject = get(responseData[rowIndex], 'subjectID.subject', null);
      const packageIsAll = responseData[rowIndex].isAll;

      if (typeof packagePrice === "object") {
       if (packagePrice.INR) {
          priceString = `Rs. ${packagePrice.INR}`;
        }
      }

      if(packageIsAll) {
        subjectString = "ALL";
      } else {
        subjectString = packageSubject ? packageSubject : "N/A"
      }

      currentRowArr.push(`${rowIndex + 1}.`);
      currentRowArr.push(responseData[rowIndex]._id);
      currentRowArr.push(responseData[rowIndex].title);
      currentRowArr.push(responseData[rowIndex].permittedResources);
      currentRowArr.push(priceString);
      currentRowArr.push(responseData[rowIndex].classID.class);
      currentRowArr.push(subjectString);
      currentRowArr.push(responseData[rowIndex].status ? "Active" : "Inactive");
      currentRowArr.push(moment(responseData[rowIndex].createdAt).format('DD/MM/YY hh:mm A'));

      tableDataArr.push(currentRowArr);
    }

    return tableDataArr;
  } else {
    return [];
  }
};
