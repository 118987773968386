import React from 'react';

import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NavigationPaths } from '../../common/Environment';

import Header from '../../components/Header';
import LmsService from '../../services/lmsService';
import PageLoader from '../../components/PageLoader';
import ImageUploader from '../../components/ImageUploader';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './AddNewsletterPage.scss';

class AddNewsletterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      formData: {
        title: '',
        url: '',
        thumbnail: '',
        mediaDirectory: uuid(),
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    this.lmsService = new LmsService();
  }

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.title === '') {
      errorMessagesArr.push('Title is a required field.');
      isFormValid = false;
    }

    if (!this.urlRegex.test(formData.url)) {
      errorMessagesArr.push('Newsletter PDF URL is invalid.');
      isFormValid = false;
    }

    if (!this.urlRegex.test(formData.thumbnail)) {
      errorMessagesArr.push('Thumbnail URL is invalid.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      const dataPayload = {
        ...formData,
      };

      this.lmsService
        .addNewsletters(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });

          if (responseData) {
            this.props.history.push(NavigationPaths.NEWSLETTERSPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const {
      loader,
      formData,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Add New Newsletter</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addnewsletter-container">
          <ImageUploader mediaDirectory={formData.mediaDirectory} />

          <div className="addnewsletter-container-wrapper">
            <h1 className="addnewsletter-container-title">
              Add New Newsletter
            </h1>

            <div className="addnewsletter-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <label>Title</label>

                <input
                  type="text"
                  className="input-field"
                  placeholder="Title"
                  value={formData.title}
                  onChange={(event) =>
                    this.handleFieldUpdate('title', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <label>Thumbnail URL</label>

                <input
                  type="text"
                  className="input-field"
                  placeholder="Thumbnail URL"
                  value={formData.thumbnail}
                  onChange={(event) =>
                    this.handleFieldUpdate('thumbnail', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <label>Newsletter PDF URL</label>
                <input
                  type="text"
                  className="input-field"
                  placeholder="Newsletter PDF URL"
                  value={formData.url}
                  onChange={(event) =>
                    this.handleFieldUpdate('url', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Add New Newsletter
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AddNewsletterPage));
