import React from 'react';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form } from 'react-bootstrap';
import { NavigationPaths } from '../../common/Environment';
import { SYSTEM_PERMISSIONS } from '../../common/Constants';

import Header from '../../components/Header';
import PageLoader from '../../components/PageLoader';
import AdminService from '../../services/adminService';
import SideNavigation from '../../components/SideNavigation';
import withAuthentication from '../../hocs/AuthGuard/withAuthentication';

import styles from './AddAdminPage.scss';

class AddAdminPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      formData: {
        name: '',
        email: '',
        countryCode: '91',
        mobileNumber: '',
        permissions: [SYSTEM_PERMISSIONS.STATS],
      },
      validationErrors: [],
      showServerErrors: '',
      showValidationErrors: false,
    };

    this.adminService = new AdminService();
  }

  validateFormData = () => {
    let isFormValid = true;
    let errorMessagesArr = [];

    const { formData } = this.state;

    if (formData.name === '') {
      errorMessagesArr.push('Full name is a required field.');
      isFormValid = false;
    }

    if (formData.email === '') {
      errorMessagesArr.push('Email address is a required field.');
      isFormValid = false;
    }

    if (formData.countryCode === '') {
      errorMessagesArr.push('Country code is a required field.');
      isFormValid = false;
    }

    if (formData.mobileNumber === '') {
      errorMessagesArr.push('Mobile number is a required field.');
      isFormValid = false;
    }

    if (formData.permissions.length === 0) {
      errorMessagesArr.push('Permissions are required.');
      isFormValid = false;
    }

    if (errorMessagesArr.length !== 0 && !isFormValid) {
      // Form is Invalid
      console.log('Invalid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: errorMessagesArr,
        showValidationErrors: true,
      }));
    } else {
      console.log('Valid Form', errorMessagesArr, isFormValid);

      this.setState((state) => ({
        ...state,
        validationErrors: [],
        showServerErrors: '',
        showValidationErrors: false,
      }));
    }

    return isFormValid;
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  handlePermissions = (permission) => {
    let permissions = Object.assign([], this.state.formData.permissions);

    if (permissions.includes(permission)) {
      permissions = permissions.filter((value) => value !== permission);
    } else {
      permissions.push(permission);
    }

    this.handleFieldUpdate('permissions', permissions);
  };

  handleFormSubmit = () => {
    let isFormValid = false;

    isFormValid = this.validateFormData();

    if (isFormValid) {
      // Form is Valid, Submit the Form
      this.setState({ loader: true });

      const { formData } = this.state;

      const dataPayload = {
        ...formData,
      };

      this.adminService
        .addAdmin(dataPayload)
        .then((responseData) => {
          this.setState({ loader: false });

          if (responseData) {
            this.props.history.push(NavigationPaths.ADMINPAGE);
          }
        })
        .catch((errorData) => {
          this.setState({
            loader: false,
            showServerErrors: errorData.message,
          });
        });
    }
  };

  render() {
    const {
      loader,
      formData,
      showServerErrors,
      validationErrors,
      showValidationErrors,
    } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Add New Admin</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="addadmin-container">
          <div className="addadmin-container-wrapper">
            <h1 className="addadmin-container-title">Add New Admin</h1>

            <div className="addadmin-container-content">
              {showValidationErrors && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  {validationErrors.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              )}

              {showServerErrors !== '' && (
                <div className="error-wrapper">
                  <h6>Error!</h6>

                  <p>{showServerErrors}</p>
                </div>
              )}

              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={(event) =>
                    this.handleFieldUpdate('name', event.target.value)
                  }
                />
              </div>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={(event) =>
                    this.handleFieldUpdate('email', event.target.value)
                  }
                />
              </div>
              <div className="input-wrapper input-mobile">
                <select
                  className="input-select"
                  onChange={(event) =>
                    this.handleFieldUpdate('countryCode', event.target.value)
                  }
                >
                  <option value={'91'}>+91</option>
                </select>

                <input
                  type="text"
                  className="input-field"
                  placeholder="Mobile Number"
                  value={formData.mobileNumber}
                  onChange={(event) =>
                    this.handleFieldUpdate('mobileNumber', event.target.value)
                  }
                />
              </div>

              <div className="input-wrapper">
                <label>System Permissions</label>

                <div>
                  {Object.keys(SYSTEM_PERMISSIONS).map((permission, index) => {
                    return (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        label={permission}
                        value={permission}
                        disabled={permission === SYSTEM_PERMISSIONS.STATS}
                        checked={formData.permissions.includes(permission)}
                        onChange={(event) =>
                          this.handlePermissions(event.currentTarget.value)
                        }
                      />
                    );
                  })}
                </div>
              </div>

              <div className="input-wrapper">
                <button
                  type="button"
                  className="button button-primary"
                  onClick={() => this.handleFormSubmit()}
                >
                  Add New Admin
                </button>
              </div>
            </div>
          </div>
        </div>

        {loader && <PageLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(AddAdminPage));
