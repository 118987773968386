import React from "react";

import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { tableDataParser } from "./dataParser";
import { NavigationPaths } from "../../common/Environment";

import Table from "../../components/Table";
import Header from "../../components/Header";
import PackageService from "../../services/packageService";
import withAuthentication from "../../hocs/AuthGuard/withAuthentication";
import SideNavigation from "../../components/SideNavigation";

import styles from "./PackagePage.scss";
import { Link } from "react-router-dom";

class PackagePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      tableHeaders: [
        "S. No.",
        "Database ID",
        "Title",
        "Resources",
        "Prices",
        "Class",
        "Subject",
        "Status",
        "Created On",
      ],
      tableContent: [],
    };

    this.packageService = new PackageService();
  }

  componentDidMount() {
    this.getAllPackages();
  }

  getAllPackages = () => {
    this.setState({ loader: true });

    this.packageService
      .getAllPackages()
      .then((responseData) => {
        if (responseData) {
          const parsedContentArr = tableDataParser(responseData.data);

          this.setState({ loader: false, tableContent: parsedContentArr });
        }
      })
      .catch((errorData) => {
        console.log("ERROR: Unable to Fetch Users", errorData);
      });
  };

  handlePackageToggle = (packageID) => {
    if (!packageID) {
      return;
    }

    const dataPayload = {
      packageID,
    };

    this.packageService
      .togglePackage(dataPayload)
      .then(() => {
        this.getAllPackages()
      })
      .catch((errorData) => {
        console.log("ERROR: Unable to toggle PACKAGE", errorData);
      });
  };

  render() {
    const { loader, tableHeaders, tableContent } = this.state;

    return (
      <div className="page-container" style={styles}>
        <Helmet>
          <title>SKIP - Packages</title>
        </Helmet>

        <Header />

        <SideNavigation />

        <div className="package-container">
          <div className="package-container-wrapper">
            <div className="package-container-header d-flex align-items-center justify-content-between">
              <h1 className="package-container-title">Packages</h1>
              <Link
                to={NavigationPaths.ADDPACKAGEPAGE}
                className="button button-primary"
              >
                Add Package
              </Link>
            </div>

            <Table
              hasActions={true}
              hasDisable={true}
              loaderState={loader}
              headerData={tableHeaders}
              tableData={tableContent}
              editPath={NavigationPaths.EDITPACKAGEPAGE}
              disablePath={this.handlePackageToggle}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(PackagePage));
