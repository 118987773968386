import axiosInstance from "./CreateService";
import { ApiConstants } from "../common/Environment";

export default class SubjectService {
  getAllSubjects() {
    return axiosInstance.get(ApiConstants.GETSUBJECTS);
  }

  addSubject(dataPayload) {
    return axiosInstance.post(ApiConstants.ADDSUBJECTS, dataPayload);
  }

  editSubject(dataPayload) {
    return axiosInstance.put(
      ApiConstants.EDITSUBJECT.replace(":subjectID", dataPayload.subjectID),
      dataPayload
    );
  }

  getSubjectsByClass(dataPayload) {
    return axiosInstance.get(
      `${ApiConstants.GETSUBJECTS}?classID=${dataPayload.classID}`
    );
  }

  getSubjectByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETSUBJECTBYID.replace(":subjectID", dataPayload.subjectID)
    );
  }

  getChapters(payload) {
    let url = ApiConstants.GETCHAPTERS;
    if (payload.subjectID) {
      url = `${url}?subjectID=${payload.subjectID}`;
    }

    return axiosInstance.get(url);
  }

  getChapterByID(dataPayload) {
    return axiosInstance.get(
      ApiConstants.GETCHAPTERBYID.replace(":chapterID", dataPayload.chapterID)
    );
  }

  editChapter(dataPayload) {
    return axiosInstance.put(
      ApiConstants.EDITCHAPTER.replace(":chapterID", dataPayload.chapterID),
      dataPayload
    );
  }
}
