import React from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AdminService from '../../services/adminService';
import { NavigationPaths } from '../../common/Environment';
import { SYSTEM_PERMISSIONS } from '../../common/Constants';
import { logoutAction, updatePermissions } from '../../actions/authActions';

export default (WrappedComponent) => {
  class withAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.adminService = new AdminService();
    }
    componentDidMount() {
      // Reset Page Scroll to Zero
      window.scrollTo(0, 0);

      const { adminDetails, permissionUpdated } = this.props.admin;
      if (
        adminDetails &&
        (!permissionUpdated ||
          moment().diff(moment(permissionUpdated), 'minutes') > 30)
      ) {
        this.getPermissions();
      }
    }

    getPermissions = () => {
      this.adminService.getPermissions().then((responseData) => {
        if (responseData.data) {
          this.props.updatePermissions(responseData.data.permissions);
        }
      });
    };

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.lockBodyScroll !== this.props.lockBodyScroll) {
        if (this.props.lockBodyScroll) {
          document.body.style.overflowY = 'hidden';
        } else {
          document.body.style.overflowY = 'auto';
        }
      }
    }

    render() {
      const { adminDetails } = this.props.admin;
      if (
        adminDetails &&
        adminDetails.accessToken &&
        adminDetails.permissions &&
        adminDetails.permissions.length
      ) {
        let allow = false;
        switch (this.props.match.path) {
          case NavigationPaths.STATSPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.STATS)) {
              allow = true;
            }
            break;

          case NavigationPaths.PROJECTPAGE:
          case NavigationPaths.ACTIVITYPAGE:
          case NavigationPaths.WORKSHEETPAGE:
          case NavigationPaths.ADDPROJECTPAGE:
          case NavigationPaths.EDITPROJECTPAGE:
          case NavigationPaths.ADDACTIVITYPAGE:
          case NavigationPaths.WALKTHROUGHPAGE:
          case NavigationPaths.ADDWORKSHEETPAGE:
          case NavigationPaths.EDITACTIVITYPAGE:
          case NavigationPaths.EDITWORKSHEETPAGE:
          case NavigationPaths.ADDWALKTHROUGHPAGE:
          case NavigationPaths.SUBJECTPROJECTPAGE:
          case NavigationPaths.SEARCHWORKSHEETPAGE:
          case NavigationPaths.EDITWALKTHROUGHPAGE:
          case NavigationPaths.SUBJECTACTIVITYPAGE:
          case NavigationPaths.SUBJECTWORKSHEETPAGE:
          case NavigationPaths.SUBJECTWALKTHROUGHPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.CONTENT)) {
              allow = true;
            }
            break;

          case NavigationPaths.PACKAGEPAGE:
          case NavigationPaths.ADDPACKAGEPAGE:
          case NavigationPaths.EDITPACKAGEPAGE:
            if (
              adminDetails.permissions.includes(SYSTEM_PERMISSIONS.PACKAGES)
            ) {
              allow = true;
            }
            break;

          case NavigationPaths.CLASSPAGE:
          case NavigationPaths.ADDCLASSPAGE:
          case NavigationPaths.EDITCLASSPAGE:
          case NavigationPaths.SUBJECTPAGE:
          case NavigationPaths.ADDSUBJECTPAGE:
          case NavigationPaths.EDITSUBJECTPAGE:
          case NavigationPaths.LESSONSPAGE:
          case NavigationPaths.EDITCHAPTERPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.CLASSES)) {
              allow = true;
            }
            break;

          case NavigationPaths.USERPAGE:
          case NavigationPaths.ADDUSERPAGE:
          case NavigationPaths.EDITUSERPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.USERS)) {
              allow = true;
            }
            break;

          case NavigationPaths.AGENTPAGE:
          case NavigationPaths.EDITAGENTPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.AGENTS)) {
              allow = true;
            }
            break;

          case NavigationPaths.COUPONPAGE:
          case NavigationPaths.ADDCOUPONPAGE:
          case NavigationPaths.EDITCOUPONPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.COUPONS)) {
              allow = true;
            }
            break;

          case NavigationPaths.USERPROJECTPAGE:
          case NavigationPaths.EDITUSERPROJECTPAGE:
            if (
              adminDetails.permissions.includes(
                SYSTEM_PERMISSIONS.USER_PROJECTS
              )
            ) {
              allow = true;
            }
            break;

          case NavigationPaths.ECOMORDERSPAGE:
          case NavigationPaths.EDITECOMORDERSPAGE:
            if (
              adminDetails.permissions.includes(SYSTEM_PERMISSIONS.SHOP_ORDERS)
            ) {
              allow = true;
            }
            break;

          case NavigationPaths.ADMINPAGE:
          case NavigationPaths.EDITADMINPAGE:
          case NavigationPaths.ADDADMINPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.ADMINS)) {
              allow = true;
            }
            break;

          case NavigationPaths.PACKAGEORDERSPAGE:
          case NavigationPaths.EDITPACKAGEORDERSPAGE:
            if (
              adminDetails.permissions.includes(
                SYSTEM_PERMISSIONS.PACKAGE_ORDERS
              )
            ) {
              allow = true;
            }
            break;

          case NavigationPaths.PRODUCTORDERSPAGE:
          case NavigationPaths.EDITPRODUCTORDERSPAGE:
            if (
              adminDetails.permissions.includes(
                SYSTEM_PERMISSIONS.PRODUCT_ORDERS
              )
            ) {
              allow = true;
            }
            break;

          case NavigationPaths.NEWSPAGE:
          case NavigationPaths.ADDNEWSPAGE:
          case NavigationPaths.EDITNEWSPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.NEWS)) {
              allow = true;
            }
            break;

          case NavigationPaths.BANNERSPAGE:
          case NavigationPaths.ADDBANNERSPAGE:
          case NavigationPaths.EDITBANNERSPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.BANNERS)) {
              allow = true;
            }
            break;

          case NavigationPaths.ADVERTISEMENTSPAGE:
          case NavigationPaths.ADDADVERTISEMENTSPAGE:
          case NavigationPaths.EDITADVERTISEMENTSPAGE:
            if (
              adminDetails.permissions.includes(
                SYSTEM_PERMISSIONS.ADVERTISEMENTS
              )
            ) {
              allow = true;
            }
            break;

          case NavigationPaths.SESSIONSPAGE:
          case NavigationPaths.ADDSESSIONSPAGE:
          case NavigationPaths.EDITSESSIONSPAGE:
            if (
              adminDetails.permissions.includes(SYSTEM_PERMISSIONS.SESSIONS)
            ) {
              allow = true;
            }
            break;

          case NavigationPaths.PAYOUTPAGE:
          case NavigationPaths.EDITPAYOUTPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.PAYOUTS)) {
              allow = true;
            }
            break;

          case NavigationPaths.REPORTSPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.REPORTS)) {
              allow = true;
            }
            break;

          case NavigationPaths.NEWSLETTERSPAGE:
          case NavigationPaths.ADDNEWSLETTERSPAGE:
          case NavigationPaths.EDITNEWSLETTERSPAGE:
            if (
              adminDetails.permissions.includes(SYSTEM_PERMISSIONS.NEWSLETTERS)
            ) {
              allow = true;
            }
            break;

          case NavigationPaths.VIDEOSPAGE:
          case NavigationPaths.ADDVIDEOSPAGE:
          case NavigationPaths.EDITVIDEOSPAGE:
            if (adminDetails.permissions.includes(SYSTEM_PERMISSIONS.VIDEOS)) {
              allow = true;
            }
            break;

          case NavigationPaths.DURATIONPAGE:
          case NavigationPaths.ADDDURATIONPAGE:
          case NavigationPaths.EDITDURATIONPAGE:
            if (
              adminDetails.permissions.includes(SYSTEM_PERMISSIONS.DURATIONS)
            ) {
              allow = true;
            }
            break;

          default:
            break;
        }

        if (allow) {
          return <WrappedComponent {...this.props} />;
        } else {
          return <Redirect to={NavigationPaths.UNAUTHORIZEDPAGE} />;
        }
      } else {
        this.props.logoutAction();
      }

      return <Redirect to={NavigationPaths.LOGIN} />;
    }
  }

  const mapStateToProps = (state) => ({
    admin: state.admin,
  });

  const mapDispatchToProps = {
    logoutAction,
    updatePermissions,
  };

  return connect(mapStateToProps, mapDispatchToProps)(withAuthentication);
};
