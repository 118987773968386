export const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;

export const CDN_ROOT_URL = process.env.REACT_APP_CDN_ROOT_URL;

export const AGENT_FORM_URL = process.env.REACT_APP_AGENT_FORM_URL;

// Start Navigation Constants
export const NavigationPaths = {
  LOGIN: '/',
  STATSPAGE: '/stats',
  USERPAGE: '/users',
  ADDUSERPAGE: '/users/add',
  EDITUSERPAGE: '/users/edit',
  CLASSPAGE: '/classes',
  ADDCLASSPAGE: '/classes/add',
  EDITCLASSPAGE: '/classes/edit/:id',
  SUBJECTPAGE: '/subjects',
  ADDSUBJECTPAGE: '/subjects/add',
  EDITSUBJECTPAGE: '/subjects/edit/:id',
  PACKAGEPAGE: '/packages',
  ADDPACKAGEPAGE: '/packages/add',
  EDITPACKAGEPAGE: '/packages/edit/:id',
  WALKTHROUGHPAGE: '/content/walkthroughs',
  SUBJECTWALKTHROUGHPAGE: '/content/walkthroughs/:subject',
  ADDWALKTHROUGHPAGE: '/content/walkthrough/add',
  EDITWALKTHROUGHPAGE: '/content/walkthrough/edit/:id',
  WORKSHEETPAGE: '/content/worksheets',
  SUBJECTWORKSHEETPAGE: '/content/worksheets/:subject',
  ADDWORKSHEETPAGE: '/content/worksheet/add',
  EDITWORKSHEETPAGE: '/content/worksheet/edit/:id',
  PROJECTPAGE: '/content/projects',
  SUBJECTPROJECTPAGE: '/content/projects/:subject',
  ADDPROJECTPAGE: '/content/project/add',
  EDITPROJECTPAGE: '/content/project/edit/:id',
  AGENTPAGE: '/agents',
  EDITAGENTPAGE: '/agents/:id',
  ACTIVITYPAGE: '/content/activities',
  SUBJECTACTIVITYPAGE: '/content/activities/:subject',
  ADDACTIVITYPAGE: '/content/activity/add',
  EDITACTIVITYPAGE: '/content/activity/edit/:id',
  USERPROJECTPAGE: '/content/user/project/',
  EDITUSERPROJECTPAGE: '/content/user/project/:id',
  ECOMORDERSPAGE: '/orders/ecom/',
  EDITECOMORDERSPAGE: '/orders/ecom/:id',
  SEARCHWORKSHEETPAGE: '/search/worksheets/',
  LESSONSPAGE: '/lessons',
  EDITCHAPTERPAGE: '/lessons/edit/:id',
  COUPONPAGE: '/coupons',
  ADDCOUPONPAGE: '/coupons/add',
  EDITCOUPONPAGE: '/coupons/edit/:id',
  NEWSPAGE: '/news/',
  ADDNEWSPAGE: '/news/add',
  EDITNEWSPAGE: '/news/edit/:id',
  BANNERSPAGE: '/banners/',
  ADDBANNERSPAGE: '/banners/add',
  EDITBANNERSPAGE: '/banners/edit/:id',
  ADVERTISEMENTSPAGE: '/advertisements/',
  EDITADVERTISEMENTSPAGE: '/advertisements/edit/:id',
  ADDADVERTISEMENTSPAGE: '/advertisements/add',
  ADMINPAGE: '/administrators',
  ADDADMINPAGE: '/administrators/add',
  EDITADMINPAGE: '/administrators/edit/:id',
  PACKAGEORDERSPAGE: '/orders/packages/',
  EDITPACKAGEORDERSPAGE: '/orders/packages/:id',
  PRODUCTORDERSPAGE: '/orders/products/',
  EDITPRODUCTORDERSPAGE: '/orders/products/:id',
  SESSIONSPAGE: '/sessions/',
  EDITSESSIONSPAGE: '/sessions/edit/:id',
  ADDSESSIONSPAGE: '/sessions/add',
  UNAUTHORIZEDPAGE: '/unauthorized',
  PAYOUTPAGE: '/payouts/',
  EDITPAYOUTPAGE: '/payouts/edit/:id',
  REPORTSPAGE: '/reports',
  NEWSLETTERSPAGE: '/newsletters/',
  EDITNEWSLETTERSPAGE: '/newsletters/edit/:id',
  ADDNEWSLETTERSPAGE: '/newsletters/add',
  VIDEOSPAGE: '/videos/',
  EDITVIDEOSPAGE: '/videos/edit/:id',
  ADDVIDEOSPAGE: '/videos/add',
  DURATIONPAGE: '/durations',
  ADDDURATIONPAGE: '/durations/add',
  EDITDURATIONPAGE: '/durations/edit/:id',
};

// Start API Constants
const adminPrefix = 'admin';

export const ApiConstants = {
  LOGIN: `/${adminPrefix}/login`,
  VERIFYOTP: `/${adminPrefix}/login/verify`,
  GETUSERS: `/${adminPrefix}/users`,
  ADDUSERS: `/${adminPrefix}/users`,
  GETCLASSES: `/${adminPrefix}/classes`,
  ADDCLASSES: `/${adminPrefix}/classes`,
  EDITCLASSES: `/${adminPrefix}/classes`,
  GETCLASSESBYID: `/${adminPrefix}/classes/:classID`,
  TOGGLECLASSES: `/${adminPrefix}/classes/status`,
  GETSUBJECTS: `/${adminPrefix}/subjects`,
  GETSUBJECTBYID: `/${adminPrefix}/subjects/:subjectID`,
  EDITSUBJECT: `/${adminPrefix}/subjects/:subjectID`,
  ADDSUBJECTS: `/${adminPrefix}/subjects`,
  GETPACKAGES: `/${adminPrefix}/packages`,
  GETPACKAGESBYID: `/${adminPrefix}/packages/:packageID`,
  ADDPACKAGES: `/${adminPrefix}/packages`,
  EDITPACKAGES: `/${adminPrefix}/packages`,
  TOGGLEPACKAGES: `/${adminPrefix}/packages/status`,
  GETCHAPTERS: `/${adminPrefix}/chapters`,
  GETCHAPTERBYID: `/${adminPrefix}/chapters/:chapterID`,
  EDITCHAPTER: `/${adminPrefix}/chapters/:chapterID`,
  GETCONTENT: `/${adminPrefix}/content`,
  GETCONTENTBYID: `/${adminPrefix}/content/id`,
  ADDCONTENT: `/${adminPrefix}/content`,
  EDITCONTENT: `/${adminPrefix}/content`,
  DELETECONTENT: `/${adminPrefix}/content/delete`,
  TOGGLECONTENT: `/${adminPrefix}/content/status`,
  ADDMEDIA: `/${adminPrefix}/media`,
  GETAGENTS: `/${adminPrefix}/agents`,
  GETAGENTBYID: `/${adminPrefix}/agents/:salesAgentID`,
  VERIFYAGENT: `/${adminPrefix}/agents/verify`,
  RETRYAGENTVERIFICATION: `/${adminPrefix}/agents/verify`,
  GETUSERPROJECTS: `/${adminPrefix}/content/user/projects`,
  GETUSERPROJECTBYID: `/${adminPrefix}/content/user/projects/:userProjectID`,
  TOGGLEUSERPROJECTSFEATURED: `/${adminPrefix}/content/user/projects/featured`,
  GETDASHBOARDANALYTICS: `/${adminPrefix}/dashboard`,
  GETECOMORDERS: `/${adminPrefix}/orders/ecom`,
  GETECOMORDERBYID: `/${adminPrefix}/orders/ecom/:ecomOrderID`,
  SEARCHWORKSHEET: `/${adminPrefix}/search/worksheets`,
  GETCOUPONS: `/${adminPrefix}/coupons`,
  GETCOUPONSBYID: `/${adminPrefix}/coupons/:couponID`,
  ADDCOUPONS: `/${adminPrefix}/coupons`,
  EDITCOUPONS: `/${adminPrefix}/coupons`,
  TOGGLECOUPONS: `/${adminPrefix}/coupons/status`,
  ADDNEWS: `/${adminPrefix}/lms/news`,
  EDITNEWS: `/${adminPrefix}/lms/news`,
  TOGGLENEWS: `/${adminPrefix}/lms/news/status`,
  GETNEWS: `/${adminPrefix}/lms/news`,
  GETNEWSBYID: `/${adminPrefix}/lms/news/:newsID`,
  ADDBANNERS: `/${adminPrefix}/lms/banners`,
  EDITBANNERS: `/${adminPrefix}/lms/banners`,
  TOGGLEBANNERS: `/${adminPrefix}/lms/banners/status`,
  GETBANNERS: `/${adminPrefix}/lms/banners`,
  GETBANNERSBYID: `/${adminPrefix}/lms/banners/:bannerID`,
  GETADVERTISEMENTS: `/${adminPrefix}/lms/advertisements`,
  ADDADVERTISEMENTS: `/${adminPrefix}/lms/advertisements`,
  GETADVERTISEMENTSBYID: `/${adminPrefix}/lms/advertisements/:advertisementID`,
  EDITADVERTISEMENTS: `/${adminPrefix}/lms/advertisements`,
  TOGGLEADVERTISEMENTS: `/${adminPrefix}/lms/advertisements/status`,
  GETADMINS: `/${adminPrefix}/get`,
  GETADMINSBYID: `/${adminPrefix}/get/:adminID`,
  ADDADMINS: `/${adminPrefix}/add`,
  EDITADMINS: `/${adminPrefix}/update`,
  TOGGLEADMINS: `/${adminPrefix}/status`,
  GETPACKAGEORDERS: `/${adminPrefix}/orders/packages`,
  GETPACKAGEORDERBYID: `/${adminPrefix}/orders/packages/:packageOrderID`,
  GETPRODUCTORDERS: `/${adminPrefix}/orders/products`,
  GETPRODUCTORDERBYID: `/${adminPrefix}/orders/products/:productOrderID`,
  GETPERMISSIONS: `/${adminPrefix}/get/profile/permissions`,
  GETSESSIONS: `/${adminPrefix}/lms/sessions`,
  ADDSESSIONS: `/${adminPrefix}/lms/sessions`,
  GETSESSIONSBYID: `/${adminPrefix}/lms/sessions/:sessionID`,
  EDITSESSIONS: `/${adminPrefix}/lms/sessions`,
  TOGGLESESSIONS: `/${adminPrefix}/lms/sessions/status`,
  GETPAYOUTS: `/${adminPrefix}/orders/payouts`,
  GETPAYOUTSBYID: `/${adminPrefix}/orders/payouts/:payoutID`,
  REQUESTPAYOUTS: `/${adminPrefix}/orders/payouts`,
  GENERATEREPORTS: `/${adminPrefix}/reports`,
  CONFIG: `/${adminPrefix}/config`,
  GETNEWSLETTERS: `/${adminPrefix}/lms/newsletters`,
  ADDNEWSLETTERS: `/${adminPrefix}/lms/newsletters`,
  GETNEWSLETTERSBYID: `/${adminPrefix}/lms/newsletters/:newsletterID`,
  EDITNEWSLETTERS: `/${adminPrefix}/lms/newsletters`,
  TOGGLENEWSLETTERS: `/${adminPrefix}/lms/newsletters/status`,
  COPYRESOURCES: `/${adminPrefix}/content/resources/copy`,
  GETVIDEOS: `/${adminPrefix}/lms/videos`,
  ADDVIDEOS: `/${adminPrefix}/lms/videos`,
  GETVIDEOSBYID: `/${adminPrefix}/lms/videos/:videoID`,
  EDITVIDEOS: `/${adminPrefix}/lms/videos`,
  TOGGLEVIDEOS: `/${adminPrefix}/lms/videos/status`,
  GETDURATIONS: `/${adminPrefix}/durations`,
  GETDURATIONSBYID: `/${adminPrefix}/durations/:durationID`,
  ADDDURATIONS: `/${adminPrefix}/durations`,
  EDITDURATIONS: `/${adminPrefix}/durations`,
  TOGGLEDURATIONS: `/${adminPrefix}/durations/status`,
};

// Start Redux Constants
export const ReduxConstants = {
  LOGIN: `AUTH/login`,
  LOGOUT: `AUTH/logout`,
  UPDATEPERMISSIONS: 'PROFILE/permissions',
};
